import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import {
  getConsumerInsightsRows,
  getOpportunitiesRows
} from "@coxauto-ui/ccxm/api";
import {
  ConsumerInsightsRecordEntity,
  ConsumerInsightsRecordsState,
  ConsumerInsightsRowsResponse,
  MultiTypeConsumerInsightsRowsRequest
} from "@coxauto-ui/ccxm/interfaces";

export const CONSUMER_INSIGHTS_RECORDS_FEATURE_KEY = "consumerInsightsRecords";

export const consumerInsightsRecordsAdapter =
  createEntityAdapter<ConsumerInsightsRecordEntity>({
    selectId: row => row.consumer.consumerId
  });

export const fetchConsumerInsightsRecords = createAsyncThunk(
  "consumerInsightsRecords/fetchStatus",
  async ({
    dealerGroupId,
    type,
    filters,
    page,
    pageSize = 20
  }: MultiTypeConsumerInsightsRowsRequest) => {
    if (type === "opportunities") {
      return await getOpportunitiesRows({
        filters,
        page,
        pageSize
      });
    } else {
      return await getConsumerInsightsRows({
        dealerGroupId,
        filters,
        page,
        pageSize
      });
    }
  }
);

export const initialConsumerInsightsRecordsState: ConsumerInsightsRecordsState =
  consumerInsightsRecordsAdapter.getInitialState({
    filters: [],
    paging: {
      pageCount: 0,
      pageNumber: 0,
      pageSize: 20,
      totalItems: 0
    },
    visibleColumns: [],
    loadingStatus: "not loaded",
    error: null,
    completedErrorCount: 0
  });

export const consumerInsightsRecordsSlice = createSlice({
  name: CONSUMER_INSIGHTS_RECORDS_FEATURE_KEY,
  initialState: initialConsumerInsightsRecordsState,
  reducers: {
    add: consumerInsightsRecordsAdapter.addOne,
    remove: consumerInsightsRecordsAdapter.removeOne,
    setOne: consumerInsightsRecordsAdapter.setOne,
    clean: () => initialConsumerInsightsRecordsState
  },
  extraReducers: builder => {
    builder
      .addCase(
        fetchConsumerInsightsRecords.pending,
        (state: ConsumerInsightsRecordsState) => {
          state.loadingStatus = "loading";
        }
      )
      .addCase(
        fetchConsumerInsightsRecords.fulfilled,
        (
          state: ConsumerInsightsRecordsState,
          action: PayloadAction<ConsumerInsightsRowsResponse>
        ) => {
          if (action.payload.paging.pageNumber === 1) {
            consumerInsightsRecordsAdapter.setAll(state, action.payload.rows);
          } else {
            consumerInsightsRecordsAdapter.addMany(state, action.payload.rows);
          }
          state.loadingStatus = "loaded";
          state.paging = action.payload.paging;
          state.filters = action.payload.filters;
          state.visibleColumns = action.payload.visibleColumns || [];
        }
      )
      .addCase(
        fetchConsumerInsightsRecords.rejected,
        (state: ConsumerInsightsRecordsState, action) => {
          state.loadingStatus = "error";
          state.error = action.error.message || null;
        }
      );
  }
});

export const consumerInsightsRecordsReducer =
  consumerInsightsRecordsSlice.reducer;

export const consumerInsightsRecordsActions =
  consumerInsightsRecordsSlice.actions;

const { selectAll, selectEntities } =
  consumerInsightsRecordsAdapter.getSelectors();

export const getConsumerInsightsRecordsState = (
  rootState: any
): ConsumerInsightsRecordsState =>
  rootState[CONSUMER_INSIGHTS_RECORDS_FEATURE_KEY];

export const selectAllConsumerInsightsRecords = createSelector(
  getConsumerInsightsRecordsState,
  selectAll
);

export const selectConsumerInsightsRecordsState = createSelector(
  getConsumerInsightsRecordsState,
  selectEntities
);
