import {
  NewRelicConfig,
  NewRelicInfo
} from "@coxauto-ui/core-interfaces-third-party-newrelic";
import { standard } from "./standard";
import scriptHtml from "./script";

const errorsToIgnore = ["ResizeObserver loop limit exceeded"];

const buildScriptHtml = (config: NewRelicConfig) => {
  const script = document.createElement("script");

  const infoConfig: NewRelicInfo = {
    beacon: "bam-cell.nr-data.net",
    errorBeacon: "bam-cell.nr-data.net",
    sa: 1,
    ...config
  };
  script.innerHTML = scriptHtml(config, infoConfig);
  script.setAttribute("data-testid", "newrelic-script");
  return script;
};
export function initialize(config: NewRelicConfig) {
  if (!config || !config.agentID || !config.applicationID) return false;
  const scriptTag = buildScriptHtml(config);
  document.head.insertBefore(scriptTag, document.head.childNodes[0]);
  standard.setErrorHandler(err => errorsToIgnore.includes(err.message));
  return true;
}
