import { ListMode } from "@coxauto-ui/ccxm/interfaces";

export enum ActionContext {
  fromCondensedList = "fromCondensedList",
  fromList = "fromList",
  fromShowAllCondensedList = "fromShowAllCondensedList",
  fromShowAllList = "fromShowAllList"
}
/**
 * Returns the context for use in Google Analytics
 *
 * @remarks
 * This method is intended to take the current url and where the user is in the application
 * and create a string to be used inside Google Analytics
 *
 * @param listMode - centralized enum for current viewport + route
 * @param url - current url (window.location.href)
 */
const deriveDealListActionContext = (listMode: ListMode, url: string) => {
  const isCustomerView = listMode.includes("Customer");
  const isShowAllView = url.toLowerCase().includes("multidealconsumerid");
  return isCustomerView && isShowAllView
    ? "fromShowAllCondensedList"
    : isCustomerView
    ? "fromCondensedList"
    : isShowAllView
    ? "fromShowAllList"
    : "fromList";
};

export default deriveDealListActionContext;
