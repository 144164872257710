// eslint-disable-next-line no-restricted-imports
import {
  StyledCollapsibleCheckboxListTitle,
  StyledDivider,
  StyledLabel
} from "./ccxm-reusable-collapsible-checkbox-list";
import styled from "styled-components";
import { SelectInput, Size } from "@interstate/components/SelectInput";

export const StyledCollapsibleDropDown = styled.div`
  .MuiBox-root {
    color: #000000;
  }
  .select-wrapper .select-container > .dropdown-menu .list-group-item {
    font-weight: 500;
    color: #000000;
  }

  .select-wrapper .select-container > .cx-select.form-control.btn.btn-primary {
    font-weight: 500;
  }
`;

/* eslint-disable-next-line */
export interface CcxmReusableDropdownSelectProps {
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  hasError?: boolean;
  htmlId: string;
  label: string;
  size?: Size;
  layout?: "vertical" | "horizontal";
  name: string;
  /**
   * onChange callback. Called with `cb(cxEvent, isValid, domEvent)`.
   * Open the console to view returned data from fired onChange event.
   * - cxEvent: Returns component specific data whose shape is `{target: {name, value}}`.
   * - isValid: Boolean that specifies if the control is in error.
   * - domEvent: The unaltered event returned from the input's interaction, if exists.
   */
  onChange: (...args: any[]) => any;
  options: { label: string; value: string }[];
  required?: boolean;
  value: string;
  placeholder: string;
}

export function CcxmReusableDropdownSelect({
  className,
  disabled = false,
  errorMessage,
  hasError,
  htmlId,
  label,
  name,
  options,
  required,
  size,
  placeholder,
  value,
  onChange
}: CcxmReusableDropdownSelectProps) {
  return (
    <StyledCollapsibleDropDown data-testid={htmlId} id={htmlId}>
      <StyledDivider data-testid={`${htmlId}-divider`} />
      <StyledCollapsibleCheckboxListTitle>
        <StyledLabel data-testid={`${htmlId}-label`} id={`${htmlId}-label`}>
          {label}
        </StyledLabel>
      </StyledCollapsibleCheckboxListTitle>
      <div className={className}>
        <SelectInput
          disabled={disabled}
          displayLabel={false}
          errorMessage={errorMessage}
          hasError={hasError}
          htmlId={htmlId}
          name={name}
          options={options}
          placeholder={placeholder}
          required={required}
          size={size}
          value={value}
          onChange={onChange}
        />
      </div>
    </StyledCollapsibleDropDown>
  );
}

export default CcxmReusableDropdownSelect;
