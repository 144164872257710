import Snippets from "./snippets";
import {
  dataLayerFunctionInterface,
  googleAnalyticsEvent,
  tagManagerArgs,
  tagsInterface
} from "@coxauto-ui/core-interfaces-third-party-gtm";

const defaultDatalayerName = "dataLayer";

export const TagManager = {
  dataScript(dataLayer: string) {
    const script = document.createElement("script");
    script.setAttribute("data-testid", "gtm-script");
    script.innerHTML = dataLayer;
    return script;
  },
  gtm(args: tagsInterface) {
    const snippets = Snippets.tags(args);

    const noScript = () => {
      const noscript = document.createElement("noscript");
      noscript.setAttribute("data-testid", "gtm-noscript-frame");
      noscript.innerHTML = snippets.iframe;
      return noscript;
    };

    const script = () => {
      const script = document.createElement("script");
      script.setAttribute("data-testid", "gtm-other-script");
      script.innerHTML = snippets.script;
      return script;
    };

    const dataScript = this.dataScript(snippets.dataLayerVar);

    return {
      noScript,
      script,
      dataScript
    };
  },
  /** Add GTM to the page */
  initialize({
    gtmId,
    events = {},
    dataLayer = undefined,
    dataLayerName = defaultDatalayerName,
    auth = "",
    preview = ""
  }: tagManagerArgs) {
    const gtm = this.gtm({
      id: gtmId,
      events,
      dataLayer,
      dataLayerName,
      auth,
      preview
    });
    if (dataLayer) document.head.appendChild(gtm.dataScript);
    document.head.insertBefore(gtm.script(), document.head.childNodes[0]);
    document.body.insertBefore(gtm.noScript(), document.body.childNodes[0]);
  },
  /** Fire an Event */
  event(
    event: googleAnalyticsEvent,
    dataLayerName?: string,
    eventCallback?: () => void,
    eventTimeout = 1000
  ) {
    const _dataLayerName = dataLayerName ?? defaultDatalayerName;
    if ((window as { [key: string]: any })[_dataLayerName]) {
      return (window as { [key: string]: any })[_dataLayerName].push({
        event: "userEvent",
        eventCallback,
        eventTimeout,
        ...event
      });
    }
  },
  /** Create/update the data layer */
  dataLayer({
    dataLayer,
    dataLayerName = defaultDatalayerName
  }: dataLayerFunctionInterface) {
    if ((window as { [key: string]: any })[dataLayerName]) {
      return (window as { [key: string]: any })[dataLayerName].push(dataLayer);
    }
    const snippets = Snippets.dataLayer(dataLayerName, dataLayer);
    const dataScript = this.dataScript(snippets);
    document.head.insertBefore(dataScript, document.head.childNodes[0]);
  }
};
