import { fetchWrapper } from "@coxauto-ui/core-http";
import { getBaseUrl, getHeader } from "../config";
import { ResponseError } from "./interfaces/api-error";
import { SendCommunicationTextRequest } from "./interfaces/send-communication-text";
import { Environment } from "@coxauto-ui/communications-interfaces";

export async function sendCommunicationText(
  data: SendCommunicationTextRequest,
  env: Environment
): Promise<boolean | ResponseError> {
  const url = `${getBaseUrl(env)}cxm/sendtext`;

  const header = await getHeader();

  const response = await fetchWrapper.postAsyncText(url, data, header);
  if (response && !response.hasError) {
    return true;
  } else {
    const error: ResponseError = {
      isError: true,
      statusCode: response.statusCode,
      errorResult: response.error
    };
    return error;
  }
}
