import styled from "styled-components";
import "antd/dist/antd.css";
import { shallowEqual, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useRef } from "react";
import "./app.scss";
import {
  configurationActions,
  fetchUserProfile,
  getConfigurationState,
  getUserProfileState
} from "@coxauto-ui/ccxm/store";
import { TagManager } from "@coxauto-ui/core-third-party-gtm";
import getViewportMode from "@coxauto-ui/ccxm/util/get-viewport-mode";
// Todo we need to delete this when we finished the migration to interstate
import { useAppDispatch } from "@coxauto-ui/ccxm/store";

import { toast } from "@coxauto-ui/core-cx";
import { trackInNewRelic } from "@coxauto-ui/core-third-party-newrelic";
import { getViewAsStatus } from "@coxauto-ui/ccxm/util/get-view-as";
import { useRoutingContext } from "@coxauto-ui/ccxm-routing";
import { CcxmUtilLdProvider } from "@coxauto-ui/ccxm/util/ld-provider";

const StyledApp = styled.div`
  background-color: #f5f6f7;
  height: 100%;
`;

interface AppWrapperProps {
  children: React.ReactNode;
}

export function AppWrapper({ children }: AppWrapperProps) {
  const dispatch = useAppDispatch();

  const dataLayerInitialized = useRef(false);
  const {
    bridgeUserId,
    bridgeUserName,
    dealerGroupId,
    dealerGroupName,
    vinUserId,
    isEmployee,
    viewAsUsername,
    loadingStatus,
    messages
  } = useSelector(getUserProfileState, shallowEqual);
  const { viewportMode } = useSelector(getConfigurationState, shallowEqual);

  const { isModule } = useRoutingContext();

  const user = useMemo(() => {
    return {
      key: bridgeUserId,
      name: bridgeUserName,
      custom: {
        dealerGroupId,
        dealerGroupName
      }
    };
  }, [bridgeUserId, bridgeUserName, dealerGroupId, dealerGroupName]);

  const resizeCallback = () => {
    const newViewPortMode = getViewportMode();
    if (newViewPortMode !== viewportMode) {
      dispatch(configurationActions.setViewportMode(newViewPortMode));
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resizeCallback, true);

    return () => {
      window.removeEventListener("resize", resizeCallback, true);
    };
  });

  useEffect(() => {
    if (messages.length > 0) {
      toast.error(
        "Sorry, something went wrong. Some features may not be available. Please wait a few minutes and try again or contact your support team",
        {
          autoClose: 30000,
          closeOnClick: false,
          closeButton: true,
          toastId: "user-profile-error-toast"
        }
      );
    }
  }, [messages]);

  const updateProfileOnActiveTab = useCallback(() => {
    if (document.visibilityState === "visible") {
      const viewAsUsername = getViewAsStatus();

      dispatch(fetchUserProfile(viewAsUsername));
    }
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("visibilitychange", updateProfileOnActiveTab, true);

    return () => {
      window.removeEventListener(
        "visibilitychange",
        updateProfileOnActiveTab,
        true
      );
    };
  });

  useEffect(() => {
    if (dealerGroupId !== "") {
      trackInNewRelic({
        componentAction: "PageLoad",
        locationLoadedFrom: document.title,
        dealerId: dealerGroupId,
        destinationUrl: window.location.href
      });
    }
  }, [dealerGroupId]);

  useEffect(() => {
    if (bridgeUserId === "") {
      dispatch(fetchUserProfile(viewAsUsername));
    }
  }, [bridgeUserId, viewAsUsername, dispatch]);

  useEffect(() => {
    if (
      !isModule &&
      dataLayerInitialized.current === false &&
      loadingStatus === "loaded"
    ) {
      const isInternalUser = isEmployee || viewAsUsername !== "";

      TagManager.dataLayer({
        dataLayer: {
          dataLayerReady: true,
          event: "dataLayerReady",
          eventName: "dataLayerReady",
          userRole: "",
          common: {
            user: {
              applicationUser: bridgeUserId,
              bridgePlatformId: bridgeUserId,
              bridgeUsername: bridgeUserName,
              solutionPrincipalId: vinUserId,
              userType: "dealer",
              isInternalUser
            },
            context: {
              dealershipId: dealerGroupId,
              dealershipName: dealerGroupName,
              commonOrgId: dealerGroupId
            }
          }
        }
      });
      dataLayerInitialized.current = true;
    }
  }, [
    isModule,
    bridgeUserId,
    dealerGroupId,
    dealerGroupName,
    loadingStatus,
    vinUserId,
    isEmployee,
    viewAsUsername,
    bridgeUserName
  ]);
  return bridgeUserId !== "" ? (
    <CcxmUtilLdProvider user={user}>
      <StyledApp>{children}</StyledApp>
    </CcxmUtilLdProvider>
  ) : (
    <div />
  );
}

export default AppWrapper;
