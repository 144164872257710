import { RealTimeNotification } from "@coxauto-ui/ccxm/interfaces";

export const getRealTimeNotificationType = (message: object) => {
  try {
    const notification = message as RealTimeNotification;
    if (isRealTimeNotification(notification)) {
      return notification;
    }
  } catch (error) {
    return null;
  }

  return null;
};

const isRealTimeNotification = (
  notification: any
): notification is RealTimeNotification => {
  return (
    notification.notificationCategory !== undefined &&
    notification.id !== undefined
  );
};
