import { Button } from "@coxauto-ui/core/cx";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

const StyledErrorTest = styled.div`
  margin: 16px;
`;

export function ErrorTest() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count === 5) {
      throw new Error("This is the test message from the error test page");
    }
  }, [count]);

  const onClick = useCallback(() => {
    setCount(n => n + 1);
  }, []);

  return (
    <StyledErrorTest>
      <div data-testid="error-test-instructions">
        If the count equals 5, the page will throw an error, invoking the Error
        Boundary
      </div>
      <div data-testid="error-test-count">Count: {count}</div>
      <Button
        data-testid="error-test-count-button"
        htmlId="test-button"
        onClick={onClick}
      >
        + 1
      </Button>
    </StyledErrorTest>
  );
}
export default ErrorTest;
