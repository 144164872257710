import {
  completeOpportunities,
  getOpportunityInsightsRows
} from "@coxauto-ui/ccxm/api";
import {
  CompleteOpportunitiesRequest,
  CompleteOpportunitiesResponse,
  LoadingStatus,
  OpportunitiesInsightsRowsRequest,
  OpportunityInsightsRecordsEntity,
  OpportunityInsightsRecordsState,
  OpportunityInsightsRowsResponse
} from "@coxauto-ui/ccxm/interfaces";
import { HttpResponse } from "@coxauto-ui/core-http";
import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";

export const OPPORTUNITY_INSIGHTS_RECORDS_FEATURE_KEY =
  "opportunityInsightsRecords";

export const opportunityInsightsRecordsAdapter =
  createEntityAdapter<OpportunityInsightsRecordsEntity>({
    selectId: OpportunityInsightsRecordsEntity =>
      OpportunityInsightsRecordsEntity.opportunityId
  });

export const fetchOpportunityInsightsRecords = createAsyncThunk(
  "opportunityInsightsRecords/fetchStatus",
  async (request: OpportunitiesInsightsRowsRequest) => {
    return await getOpportunityInsightsRows(request);
  }
);

export const completeOpportunityAction = createAsyncThunk(
  "opportunityInsightsRecords/complete",
  async (request: CompleteOpportunitiesRequest) => {
    return await completeOpportunities(request);
  }
);

export const initialOpportunityInsightsRecordsState: OpportunityInsightsRecordsState =
  opportunityInsightsRecordsAdapter.getInitialState({
    error: "",
    filterSequence: [],
    loadingStatus: LoadingStatus.notLoaded,
    paging: {
      pageCount: 0,
      pageNumber: 0,
      pageSize: 20,
      totalItems: 0
    },
    optionFilters: {},
    textFilters: {},
    visibleColumns: []
  });

export const opportunityInsightsRecordsSlice = createSlice({
  name: OPPORTUNITY_INSIGHTS_RECORDS_FEATURE_KEY,
  initialState: initialOpportunityInsightsRecordsState,
  reducers: {
    add: opportunityInsightsRecordsAdapter.addOne,
    remove: opportunityInsightsRecordsAdapter.removeOne
    // ...
  },
  extraReducers: builder => {
    builder
      .addCase(
        fetchOpportunityInsightsRecords.pending,
        (state: OpportunityInsightsRecordsState) => {
          state.loadingStatus = LoadingStatus.loading;
        }
      )
      .addCase(
        fetchOpportunityInsightsRecords.fulfilled,
        (
          state: OpportunityInsightsRecordsState,
          action: PayloadAction<OpportunityInsightsRowsResponse>
        ) => {
          if (action.payload.paging.pageNumber === 1) {
            opportunityInsightsRecordsAdapter.setAll(
              state,
              action.payload.opportunities
            );
          } else {
            opportunityInsightsRecordsAdapter.addMany(
              state,
              action.payload.opportunities
            );
          }
          state.paging = action.payload.paging;
          state.filterSequence = action.payload.filterSequence;
          state.optionFilters = action.payload.optionFilters;
          state.textFilters = action.payload.textFilters;
          state.loadingStatus = LoadingStatus.loaded;
        }
      )
      .addCase(
        fetchOpportunityInsightsRecords.rejected,
        (state: OpportunityInsightsRecordsState, action) => {
          state.loadingStatus = LoadingStatus.error;
          state.error = action.error.message || "";
        }
      )
      .addCase(
        completeOpportunityAction.fulfilled,
        (
          state: OpportunityInsightsRecordsState,
          action: PayloadAction<HttpResponse<CompleteOpportunitiesResponse>>
        ) => {
          if (action.payload.data?.opportunityId) {
            state.paging.totalItems -= 1;
            opportunityInsightsRecordsAdapter.removeOne(
              state,
              action.payload.data.opportunityId
            );
          }
        }
      );
  }
});

export const opportunityInsightsRecordsReducer =
  opportunityInsightsRecordsSlice.reducer;

export const opportunityInsightsRecordsActions =
  opportunityInsightsRecordsSlice.actions;

const { selectAll, selectEntities } =
  opportunityInsightsRecordsAdapter.getSelectors();

export const getOpportunityInsightsRecordsState = (rootState: {
  [OPPORTUNITY_INSIGHTS_RECORDS_FEATURE_KEY]: OpportunityInsightsRecordsState;
}): OpportunityInsightsRecordsState =>
  rootState[OPPORTUNITY_INSIGHTS_RECORDS_FEATURE_KEY];

export const selectAllOpportunityInsightsRecords = createSelector(
  getOpportunityInsightsRecordsState,
  selectAll
);

export const selectOpportunityInsightsRecordsEntities = createSelector(
  getOpportunityInsightsRecordsState,
  selectEntities
);
