export const sessionIdLocalStorageKey = "crw-session-identifier";

function generateGUID(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function getSessionId(): string {
  let sessionId: string | null = localStorage.getItem(sessionIdLocalStorageKey);
  if (sessionId === null) {
    sessionId = generateGUID();
    localStorage.setItem(sessionIdLocalStorageKey, sessionId);
  }
  return sessionId;
}

export default getSessionId;
