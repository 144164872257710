import {
  LDContext,
  LDProvider,
  LDReactOptions,
  useLDClient
} from "launchdarkly-react-client-sdk";
import { getActiveFlags } from "@coxauto-ui/core/third-party/launch-darkly";
import {
  deriveEnvironmentName,
  environments
} from "@coxauto-ui/ccxm-util-derive-environment";

export interface CcxmUtilLdProviderProps {
  children: React.ReactNode;
  user: LDContext;
}

const reactOptions: LDReactOptions = {
  useCamelCaseFlagKeys: false
};

const ldClientSideID = {
  [environments.prod]: "63583be10d275b11a1f32f7c",
  [environments.nonprod]: "63583be10d275b11a1f32f7b"
};

const flags = getActiveFlags("cxm");

const environment = deriveEnvironmentName();
const clientSideID = ldClientSideID[environment];

export function CcxmUtilLdProvider({
  children,
  user
}: CcxmUtilLdProviderProps) {
  const ldClient = useLDClient();

  if (ldClient) {
    return <div data-testid="ld-client-ready">{children}</div>;
  }

  return (
    <LDProvider
      clientSideID={clientSideID}
      flags={flags}
      reactOptions={reactOptions}
      user={user}
    >
      <div data-testid="add-ld-provider">{children}</div>
    </LDProvider>
  );
}

export default CcxmUtilLdProvider;
