import { ListMode, ViewportMode } from "@coxauto-ui/ccxm/interfaces";

const getListMode = (customerId: string | null, viewportMode: ViewportMode) => {
  const viewsWithCustomer: { [key: string]: ListMode } = {
    mobile: ListMode.mobileCustomer,
    tablet: ListMode.tabletCustomer,
    desktop: ListMode.desktopCustomer
  };

  const views: { [key: string]: ListMode } = {
    mobile: ListMode.mobileList,
    tablet: ListMode.tabletList,
    desktop: ListMode.desktopList
  };

  return customerId ? viewsWithCustomer[viewportMode] : views[viewportMode];
};

export default getListMode;
