import { ConversationItem } from "@coxauto-ui/communications-interfaces";
import MessageEventResult from "../core/message-event-result";

export interface MessageItemBottomProps {
  conversationItem: ConversationItem;
}

export function MessageItemBottom(props: MessageItemBottomProps) {
  const _conversationItem = props.conversationItem;

  return <MessageEventResult eventResult={_conversationItem.eventResult} />;
}

export default MessageItemBottom;
