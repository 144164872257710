import { Button } from "@interstate/components/Button";
import { ArrowTopRightOnSquareIcon } from "@interstate/components/Icons";
import { toast } from "@interstate/components/Toast";
import { useContext, useMemo, useState } from "react";
import { LoadingIndicator } from "@coxauto-ui/core-components";
import { ConversationContext, ConversationDetailsContext } from "../context";
import {
  ConversationDirection,
  ConversationTypeEnum,
  ConversationViewTypeEnum
} from "@coxauto-ui/communications-interfaces";
import { replyButtonTestId } from "../testIds";
import {
  SendCommunicationRequest,
  SendLogCallDetailsRequest,
  contactEnumToEventResultEnum,
  getConversationItemFromSendAction,
  isResponseError,
  sendCommunication,
  sendLogCall
} from "@coxauto-ui/communications-api";
// eslint-disable-next-line
import { TagManager } from "@coxauto-ui/core-third-party-ga4";
import styled from "styled-components";
import { Alert } from "@interstate/components/Alert";
export interface MessageSendButtonProps {
  onMessageSendFailed: () => void;
  onMessageSendFailedSetMsg: (msg: string) => void;
}
const ButtonContainer = styled.div`
  margin-top: 10px;
  float: right;
`;

function removeVideoUrls(content: string) {
  const pattern = /\s*https:\/\/\S*player.vin.media\/\?src\S+\s*/;
  const replacement = "\n\n";

  return content.replace(pattern, replacement).trim();
}

export function MessageSendButton(props: MessageSendButtonProps) {
  const [sendTextResponseMsg, setTextResponseMsg] = useState("");
  const { customerId, customerSystem, getUserName, env } =
    useContext(ConversationContext);
  const {
    selectedReplyOption,
    replyTextSubject,
    replyText,
    selectedConversationItem,
    currentConversationType,
    messageCallContacted,
    messageCallType,
    setConversationItems,
    setReplyText,
    selectedChannelValues,
    messageAttachments,
    setMessageAttachments
  } = useContext(ConversationDetailsContext);
  const [loading, setLoading] = useState(false);
  const getMessageButtonText = () => {
    if (selectedReplyOption?.type === ConversationTypeEnum.EMAIL)
      return "Send Email";
    if (selectedReplyOption?.type === ConversationTypeEnum.TEXT) {
      return "Send Text";
    }

    return "Save";
  };
  const applyDisabled = useMemo(() => {
    if (selectedReplyOption?.type === ConversationTypeEnum.EMAIL) {
      return (
        loading ||
        !(replyText?.trim().length > 0 && replyTextSubject?.trim().length > 0)
      );
    } else {
      return (
        (!selectedReplyOption &&
          currentConversationType !== ConversationTypeEnum.PHONE) ||
        loading ||
        !(replyText?.trim().length > 0)
      );
    }
  }, [
    selectedReplyOption,
    loading,
    replyText,
    replyTextSubject,
    currentConversationType
  ]);

  const sendReply = async () => {
    setTextResponseMsg("");
    if (
      replyText.trim().length === 0 ||
      (!selectedReplyOption &&
        currentConversationType !== ConversationTypeEnum.PHONE)
    )
      return;
    setLoading(true);
    if (currentConversationType === ConversationTypeEnum.PHONE) {
      const channelValue =
        selectedChannelValues?.get(currentConversationType) ?? "";
      const sendLogCallRequest: SendLogCallDetailsRequest = {
        consumerId: customerId,
        consumerRecordSystem: customerSystem,
        callType: messageCallType,
        contacted: messageCallContacted,
        notes: replyText,
        customerNumber: channelValue.replace(/\D/g, "")
      };
      const response = await sendLogCall(sendLogCallRequest, env);
      if (response === true) {
        TagManager.event({
          event: "productEvent",
          eventName: "Call Logged",
          eventProperties: {
            packagedBusinessCapability: "OCC Inbox",
            commonCustomerId: customerId,
            communicationChannel: ConversationTypeEnum.PHONE,
            replyContext:
              selectedConversationItem != null
                ? ConversationViewTypeEnum.DETAIL
                : ConversationViewTypeEnum.LIST,
            errorMessage: undefined
          }
        });
        const conversationItem = getConversationItemFromSendAction(
          getUserName(),
          messageCallType.toString() as ConversationDirection,
          ConversationTypeEnum.PHONE,
          channelValue,
          new Date(),
          removeVideoUrls(replyText.substring(0, 300)),
          "",
          contactEnumToEventResultEnum(messageCallContacted),
          messageAttachments
        );

        setConversationItems(current => [...current, conversationItem]);
        setReplyText("");
      } else if (isResponseError(response)) {
        const error = "Failed to send message.";
        TagManager.event({
          event: "productEvent",
          eventName: "Error Displayed",
          eventProperties: {
            packagedBusinessCapability: "OCC Inbox",
            commonCustomerId: customerId,
            communicationChannel: ConversationTypeEnum.PHONE,
            replyContext: undefined,
            errorMessage: error
          }
        });
        if (response?.statusCode === 403) {
          props.onMessageSendFailedSetMsg("Compliance Issue");
          props.onMessageSendFailed();
        }

        setReplyText("");
        toast.error(error);
        setLoading(false);
      }
    } else if (selectedReplyOption?.replyInline) {
      const channelValue =
        selectedChannelValues?.get(selectedReplyOption?.type) ?? "";
      const sendCommsRequest: SendCommunicationRequest = {
        consumerId: customerId,
        consumerContactInfo: channelValue,
        consumerRecordSystem: customerSystem,
        content: replyText,
        type: selectedReplyOption?.type ?? ConversationTypeEnum.NONE,
        subject: replyTextSubject,
        attachments: messageAttachments
          .filter(p => {
            // Remove video attachments.
            return !p.contentType.includes("video/");
          })
          .map(p => {
            return p.key;
          })
      };
      const response = await sendCommunication(sendCommsRequest, env);
      if (response === true) {
        TagManager.event({
          event: "productEvent",
          eventName: "Message Sent",
          eventProperties: {
            packagedBusinessCapability: "OCC Inbox",
            commonCustomerId: customerId,
            communicationChannel: sendCommsRequest.type,
            replyContext:
              selectedConversationItem != null
                ? ConversationViewTypeEnum.DETAIL
                : ConversationViewTypeEnum.LIST,
            errorMessage: undefined
          }
        });
        const conversationItem = getConversationItemFromSendAction(
          getUserName(),
          "outbound",
          sendCommsRequest.type,
          channelValue,
          new Date(),
          removeVideoUrls(replyText.substring(0, 300)),
          sendCommsRequest.type === ConversationTypeEnum.EMAIL
            ? replyTextSubject
            : "",
          undefined,
          messageAttachments
        );

        setConversationItems(current => [...current, conversationItem]);
        setReplyText("");
        setMessageAttachments([]);
      } else if (isResponseError(response)) {
        let error = "Failed to send message.";
        if (response?.statusCode === 400) {
          error = response?.errorResult?.message as string;
          if (
            error.includes(
              "The message can only have a maximum of 10 attachments"
            )
          ) {
            error = "The message can only have a maximum of 10 attachments";
            setTextResponseMsg(error);
          }
        }
        TagManager.event({
          event: "productEvent",
          eventName: "Error Displayed",
          eventProperties: {
            packagedBusinessCapability: "OCC Inbox",
            commonCustomerId: customerId,
            communicationChannel: sendCommsRequest.type,
            replyContext: undefined,
            errorMessage: error
          }
        });
        if (response?.statusCode === 403) {
          props.onMessageSendFailedSetMsg("Compliance Issue");
          props.onMessageSendFailed();
        }

        if (!error.includes("attachment")) {
          setReplyText("");
        }
        toast.error(error);
        setLoading(false);
      }
    } else {
      window.open(selectedReplyOption?.replyUrl);
    }
    setLoading(false);
  };

  return (
    <ButtonContainer>
      {sendTextResponseMsg.length > 0 && (
        <Alert
          htmlId="MessageReplyWarning"
          role="info"
          title="File Upload Error"
          type="info"
        >
          {sendTextResponseMsg}
        </Alert>
      )}

      <Button
        data-testid={replyButtonTestId}
        disabled={applyDisabled}
        htmlId="conversation-reply-button"
        onClick={() => sendReply()}
      >
        {!selectedReplyOption ? (
          "Send"
        ) : !selectedReplyOption?.replyInline ? (
          <>
            <ArrowTopRightOnSquareIcon /> Reply In External Application
          </>
        ) : (
          getMessageButtonText()
        )}
        {loading && (
          <LoadingIndicator
            actionName="Loading After Reply Button Click"
            htmlId="conversation-reply-button-loading-indicator"
          />
        )}
      </Button>
    </ButtonContainer>
  );
}

export default MessageSendButton;
