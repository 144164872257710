import styled from "styled-components";

export const StyledPillFilter = styled.div`
  position: relative;

  > .MuiButtonBase-root.MuiChip-root {
    &:not(.add-filter-pill) {
      color: #000;
      border: 1px solid #b2b2b2;
      svg {
        color: #5a5a5a;
      }
    }
    &.no-display {
      display: none;
    }
  }
`;

export const StyledClearFilter = styled.div`
  position: relative;

  > .MuiButtonBase-root {
    transition: 0.3s;
    line-height: 1;

    &:focus {
      box-shadow: none;
      background-color: transparent;
    }

    &:hover {
      background-color: transparent;
      border-color: transparent;
    }
  }
`;
