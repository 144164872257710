import { formatDistanceToNow } from "date-fns";
import styled from "styled-components";
export interface DateDistanceToNowProps {
  date: string;
}

const StyledDateDistanceToNow = styled.span`
  cursor: default;
`;

const DateDistanceToNow = ({ date }: DateDistanceToNowProps) => {
  const currentDate = new Date(date).toLocaleString();
  const distanceToNow = formatDistanceToNow(new Date(currentDate));
  return (
    <StyledDateDistanceToNow
      className="date-distance-to-now-text"
      data-testid="date-distance-to-now-text"
      title={currentDate}
    >
      {distanceToNow}
    </StyledDateDistanceToNow>
  );
};
export default DateDistanceToNow;
