import {
  EmployeesRowsResponse,
  LinkRequest,
  SearchResponse,
  User,
  UserManagementState
} from "@coxauto-ui/tenant/interfaces";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isEmpty, isNull } from "lodash";
import {
  getDealershipRequest,
  getEmployees,
  getLinkRequest,
  getUnlinkRequest,
  getUserByUsername,
  getUserList
} from "@coxauto-ui/tenant/api";
import { RootAdminState } from "./store";

export const USER_MANAGEMENT_FEATURE_KEY = "userManagement";
export const DEFAULT_ERROR_MESSAGE =
  ", please contact the ams-cxm-escalations channel.";

export const initialUserManagementState: UserManagementState = {
  users: [],
  loadingStatus: "not loaded",
  usersOnProcess: [],
  toast: undefined,
  errorMessage: undefined,
  dealerInfo: undefined
};

export const fetchListEmployees = createAsyncThunk(
  "userManagement/fetchListEmployees",
  async (loadMore: boolean, { getState }) => {
    const state = getState() as RootAdminState;
    const MAX_NUMBER_OF_CALLS = 10;
    const { employeesNextUrl } = state.userManagement;
    let nextUrl = loadMore ? employeesNextUrl : "";
    let calls = 0;
    let response: EmployeesRowsResponse;
    do {
      response = (await getEmployees(nextUrl)) as EmployeesRowsResponse;
      calls++;
      nextUrl = response.next;
    } while (
      response.items.length === 0 &&
      calls < MAX_NUMBER_OF_CALLS &&
      nextUrl
    );
    return { users: response.items, nextUrl: response.next, loadMore };
  }
);

export const fetchLinkBridgeUserRequest = createAsyncThunk(
  "userManagement/fetchLinkUser",
  async (request: LinkRequest) => {
    return await getLinkRequest(request);
  }
);

const fetchDealershipInfo = async (id: string) => {
  const commonCore = await getDealershipRequest({
    principal: "commonCore",
    dealerGroupId: id
  });
  if (isNull(commonCore)) {
    const vinsolutions = await getDealershipRequest({
      principal: "vinsolutions",
      dealerGroupId: id
    });
    return vinsolutions;
  }
  return commonCore;
};

export const fetchSearch = createAsyncThunk(
  "userManagement/fetchSearch",
  async (id: string): Promise<SearchResponse> => {
    const [dealership, usernames, users] = await Promise.all([
      fetchDealershipInfo(id),
      fetchUserByUsername(id),
      fetchUserList(id)
    ]);
    const totalUsers = [...users, ...usernames] as User[];
    const unique = [
      ...new Map(totalUsers.map(item => [item.id, item])).values()
    ];
    return {
      dealershipInfo: dealership,
      users: unique
    };
  }
);

const getErrorMessage = (hasEmployees: boolean, hasDealership: boolean) => {
  if (!hasEmployees && !hasDealership) {
    return "Not found";
  }
  if (hasDealership && !hasEmployees) {
    return "There are no users for this dealership. Please check the Vin CRM user settings";
  }
  return "";
};

export const fetchUnLinkBridgeUserRequest = createAsyncThunk(
  "userManagement/fetchUnlinkUser",
  async (request: LinkRequest) => {
    return await getUnlinkRequest(request);
  }
);

const fetchUserList = async (id: string) => {
  const [commonCoreResponse, vinsolutionsRespose] = await Promise.all([
    getUserList({
      principal: "commonCore",
      dealershipId: id
    }),
    getUserList({
      principal: "vinsolutions",
      dealershipId: id
    })
  ]);
  const commonCore = isNull(commonCoreResponse) ? [] : commonCoreResponse.items;
  const vinsolutions = isNull(vinsolutionsRespose)
    ? []
    : vinsolutionsRespose.items;
  return [...commonCore, ...vinsolutions];
};

const fetchUserByUsername = async (username: string) => {
  const [commonCoreResponse, vinsolutionsResponse] = await Promise.all([
    getUserByUsername({
      principal: "commonCore",
      username
    }),
    getUserByUsername({
      principal: "vinsolutions",
      username
    })
  ]);

  const commonCore = isNull(commonCoreResponse) ? [] : [commonCoreResponse];

  const vinsolutions = isNull(vinsolutionsResponse)
    ? []
    : [vinsolutionsResponse];

  return [...commonCore, ...vinsolutions];
};

const addPendingUsers = (arg: LinkRequest, state: UserManagementState) => {
  const { userName } = arg;
  const usersOnProcess = state.usersOnProcess || [];
  state.usersOnProcess = [...usersOnProcess, userName];
};

const removePendingUsers = (arg: LinkRequest, state: UserManagementState) => {
  const { userName } = arg;
  const usersOnProcess = state.usersOnProcess || [];
  state.usersOnProcess = usersOnProcess.filter(user => user !== userName);
};

export const userManagementSlice = createSlice({
  name: USER_MANAGEMENT_FEATURE_KEY,
  initialState: initialUserManagementState,
  reducers: {
    setUsers: (state, action) => {
      return { ...state, users: action.payload };
    },
    clearError: state => {
      return { ...state, errorMessage: undefined };
    },
    clearState: state => {
      return initialUserManagementState;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchListEmployees.fulfilled, (state, action) => {
        state.errorMessage = "";
        state.loadingStatus = "loaded";
        if (!action.payload.loadMore) {
          state.users = [...action.payload.users];
        } else {
          state.users = [...state.users, ...action.payload.users];
        }
        state.employeesNextUrl = action.payload.nextUrl;
      })
      .addCase(fetchListEmployees.pending, state => {
        state.dealerInfo = "Employees";
        state.loadingStatus = "loading";
      })
      .addCase(fetchListEmployees.rejected, state => {
        state.errorMessage =
          "Sorry, User Management  failed" + DEFAULT_ERROR_MESSAGE;
        state.loadingStatus = "error";
        state.users = [];
        state.employeesNextUrl = undefined;
      })
      .addCase(fetchLinkBridgeUserRequest.fulfilled, (state, action) => {
        const { userName } = action.meta.arg;
        state.toast = {
          message: `${userName} successfully linked`,
          type: "success"
        };
        removePendingUsers(action.meta.arg, state);
        state.errorMessage = "";
        state.loadingStatus = "loaded";
      })
      .addCase(fetchLinkBridgeUserRequest.pending, (state, action) => {
        addPendingUsers(action.meta.arg, state);
        state.loadingStatus = "loading";
      })
      .addCase(fetchLinkBridgeUserRequest.rejected, (state, action) => {
        const { userName } = action.meta.arg;
        state.toast = {
          message: `${userName} failed to link`,
          type: "error"
        };
        removePendingUsers(action.meta.arg, state);
        state.errorMessage =
          "Sorry, linking this user to CXM failed" + DEFAULT_ERROR_MESSAGE;
        state.loadingStatus = "error";
      })
      .addCase(fetchUnLinkBridgeUserRequest.fulfilled, (state, action) => {
        const { userName } = action.meta.arg;
        state.toast = {
          message: `${userName} successfully unlinked`,
          type: "success"
        };
        removePendingUsers(action.meta.arg, state);
        state.errorMessage = "";
        state.loadingStatus = "loaded";
      })
      .addCase(fetchUnLinkBridgeUserRequest.pending, (state, action) => {
        addPendingUsers(action.meta.arg, state);
        state.loadingStatus = "loading";
      })
      .addCase(fetchUnLinkBridgeUserRequest.rejected, (state, action) => {
        const { userName } = action.meta.arg;
        state.toast = {
          message: `${userName} failed to unlink`,
          type: "error"
        };
        removePendingUsers(action.meta.arg, state);
        state.errorMessage =
          "Sorry, unlinking this user from CXM failed" + DEFAULT_ERROR_MESSAGE;
        state.loadingStatus = "error";
      })
      .addCase(fetchSearch.fulfilled, (state, action) => {
        const { dealershipInfo, users } = action.payload;
        state.errorMessage = getErrorMessage(
          !isEmpty(users),
          !isNull(dealershipInfo)
        );
        state.loadingStatus = "loaded";
        state.users = users;
        state.dealerInfo = isNull(dealershipInfo) ? undefined : dealershipInfo;
      })
      .addCase(fetchSearch.pending, state => {
        state.loadingStatus = "loading";
        state.employeesNextUrl = undefined;
        state.dealerInfo = undefined;
        state.users = [];
      })
      .addCase(fetchSearch.rejected, state => {
        state.loadingStatus = "error";
        state.users = [];
        state.errorMessage =
          "Sorry, your search failed" + DEFAULT_ERROR_MESSAGE;
      });
  }
});

export const userManagementReducer = userManagementSlice.reducer;

export const userManagementActions = userManagementSlice.actions;

export const getUserManagementState = (rootState: any): UserManagementState =>
  rootState[USER_MANAGEMENT_FEATURE_KEY];
