enum ListMode {
  mobileList = "mobileList",
  tabletList = "tabletList",
  desktopList = "desktopList",
  mobileCustomer = "mobileCustomer",
  tabletCustomer = "tabletCustomer",
  desktopCustomer = "desktopCustomer"
}

export default ListMode;
