import {
  ConversationContentType,
  ExternalInteraction,
  PhoneInteraction
} from "@coxauto-ui/communications-interfaces";

export const isPhoneMessageContent = (
  content: ConversationContentType
): content is PhoneInteraction => {
  return (content as PhoneInteraction).callUrl !== undefined;
};

export const isExternalMessageContent = (
  content: ConversationContentType
): content is ExternalInteraction => {
  return (content as ExternalInteraction).externalUrl !== undefined;
};
