// we should probably move this stuff to an api project if/when we create one
export type CustomerValidationErrorFields =
  | "firstName"
  | "lastName"
  | "cellPhone"
  | "homePhone"
  | "workPhone"
  | "primaryEmail"
  | "alternateEmail";

export type CustomerValidationErrorDetails = {
  Field: CustomerValidationErrorFields;
  Messages: string[];
};

export class CustomerValidationError extends Error {
  Errors: CustomerValidationErrorDetails[];
  constructor(message: string, errors: CustomerValidationErrorDetails[]) {
    super(message);
    this.Errors = errors;
  }
}

// this should probably go into an api project if/when we do that
export async function getCustomerValidationErrorDetails(response: Response) {
  if (response.status === 400) {
    const data = await response.json();

    if (data?.errors) {
      const errors: CustomerValidationErrorDetails[] = [];

      for (const fieldName in data?.errors) {
        const fieldNameFormatted =
          fieldName.charAt(0).toLowerCase() + fieldName.slice(1);
        errors.push({
          Field: fieldNameFormatted as CustomerValidationErrorFields,
          Messages: data.errors[fieldName]
        });
      }

      return errors;
    }
  }

  return undefined;
}
