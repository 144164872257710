import { getCookie } from "@coxauto-ui/ccxm/util/get-browser-values";
import {
  COOKIE_ACTING_USER_NON_PROD,
  COOKIE_ACTING_USER_PROD,
  NON_PROD_PIPELINES_DOMAIN,
  PROD_PIPELINES_DOMAIN,
  PipelinesDomain,
  isPipelinesDomain
} from "@coxauto-ui/ccxm/interfaces";

const mapCookieName: Record<PipelinesDomain, string> = {
  [NON_PROD_PIPELINES_DOMAIN]: COOKIE_ACTING_USER_NON_PROD,
  [PROD_PIPELINES_DOMAIN]: COOKIE_ACTING_USER_PROD
};

const getViewAsCookieName = () => {
  const domain = window.location.hostname;
  if (isPipelinesDomain(domain)) {
    return mapCookieName[domain];
  }
  return COOKIE_ACTING_USER_NON_PROD;
};

export function expireViewAsCookie() {
  const viewUsCookieName = getViewAsCookieName();
  deleteCookie(viewUsCookieName);
}

const deleteCookie = (name: string) => {
  document.cookie =
    `${name}=;` +
    `domain=${window.location.hostname};` +
    "expires=Thu, 01 Jan 1970 00:00:00 UTC;" +
    "path=/;" +
    "Max-Age=-99999999;" +
    "SameSite=None;" +
    "Secure";
};

export function getViewAsStatus(): string {
  const viewAsCookieValue = getCookie(getViewAsCookieName());
  return viewAsCookieValue ?? "";
}
