import { ListMode } from "@coxauto-ui/ccxm/interfaces";
import { getConfigurationState } from "@coxauto-ui/ccxm/store";
import getListMode from "@coxauto-ui/ccxm/util/get-list-mode";
import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

export function useInsightsListMode() {
  const { viewportMode } = useSelector(getConfigurationState, shallowEqual);
  const { customerId } = useSelector(getConfigurationState, shallowEqual);
  const [insightsListMode, setInsightsListMode] = useState<ListMode>(
    getListMode(customerId, viewportMode)
  );

  useEffect(() => {
    const size = getListMode(customerId, viewportMode);
    setInsightsListMode(size);
  }, [customerId, viewportMode]);

  return insightsListMode;
}

export default useInsightsListMode;
