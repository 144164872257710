// eslint-disable-next-line no-restricted-imports
import { useMemo, useState } from "react";
import styled from "styled-components";
import { ChevronDownIcon } from "@interstate/components/Icons/ChevronDownIcon";
import { ChevronUpIcon } from "@interstate/components/Icons/ChevronUpIcon";
import {
  CheckBoxList,
  CheckboxListOptions
} from "@interstate/components/CheckBoxList";

/* eslint-disable-next-line */
export interface CcxmReusableCollapsibleCheckboxListProps {
  className?: string;
  errorMessage?: string;
  expandedByDefault?: boolean;
  hasError?: boolean;
  htmlId: string;
  inline?: boolean;
  label: string;
  layout?: "vertical" | "horizontal";
  name: string;
  /**
   * onChange callback. Called with `cb(cxEvent, isValid, domEvent)`.
   * Open the console to view returned data from fired onChange event.
   * - cxEvent: Returns component specific data whose shape is `{target: {name, value}}`.
   * - isValid: Boolean that specifies if the control is in error.
   * - domEvent: The unaltered event returned from the input's interaction, if exists.
   */
  onChange: (...args: any[]) => any;
  options: CheckboxListOptions[];
  required?: boolean;
  values?: string[];
}

export const StyledCollapsibleCheckboxList = styled.div`
  .checkbox label {
    font-weight: 500;
  }

  // Interstate defect displays an erroneous svg inside an unselected disabled checkbox
  .MuiFormControlLabel-root .MuiCheckbox-root:not(.Mui-checked) svg {
    display: none;
  }
`;

export const StyledCollapsibleCheckboxListTitle = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    margin-top: -3px;
  }
`;

export const StyledLabel = styled.h4`
  opacity: 1;
  color: #000;
  margin: 0 0 16px;
`;

export const StyledDivider = styled.hr`
  margin: 16px 0 16px;
`;

export function CcxmReusableCollapsibleCheckboxList({
  expandedByDefault = true,
  label,
  htmlId,
  className,
  errorMessage,
  hasError,
  inline,
  layout,
  name,
  onChange,
  options,
  required,
  values
}: CcxmReusableCollapsibleCheckboxListProps) {
  const [expanded, setExpanded] = useState(expandedByDefault);

  const toggleExpandedRow = () => {
    setExpanded(b => !b);
  };

  const normalizedLabel = useMemo(() => {
    return label.toLowerCase().replace(" ", "-");
  }, [label]);

  return (
    <StyledCollapsibleCheckboxList data-testid={htmlId} id={htmlId}>
      <StyledDivider data-testid={`${htmlId}-divider`} />
      <StyledCollapsibleCheckboxListTitle onClick={toggleExpandedRow}>
        <StyledLabel data-testid={`${htmlId}-label`} id={`${htmlId}-label`}>
          {label}
        </StyledLabel>
        {expanded ? (
          <div id={`${htmlId}-up-arrow-${normalizedLabel}`}>
            <ChevronUpIcon
              data-testid={`${htmlId}-up-arrow-${normalizedLabel}`}
            />
          </div>
        ) : (
          <div id={`${htmlId}-down-arrow-${normalizedLabel}`}>
            <ChevronDownIcon
              data-testid={`${htmlId}-down-arrow-${normalizedLabel}`}
            />
          </div>
        )}
      </StyledCollapsibleCheckboxListTitle>
      {expanded && (
        <div className={className}>
          <CheckBoxList
            displayLabel={false}
            errorMessage={errorMessage}
            hasError={hasError}
            htmlId={htmlId}
            inline={inline}
            layout={layout}
            name={name}
            options={options}
            required={required}
            spacing={"sm"}
            values={values}
            onChange={onChange}
          />
        </div>
      )}
    </StyledCollapsibleCheckboxList>
  );
}

export default CcxmReusableCollapsibleCheckboxList;
