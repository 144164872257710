import classnames from "classnames";
import classNames from "classnames";
import { Fragment, ReactNode } from "react";

/* eslint-disable-next-line */
export interface CcxmReusableLabelledInsightDisplayProps {
  appendedContent?: ReactNode;
  className?: string;
  content: {
    className?: string;
    id: string;
    "data-testid": string;
    value: ReactNode;
  }[];
  contentDelimiter?: string;
  "data-testid": string;
  displayLabel?: boolean;
  htmlId: string;
  label?: string;
  multiline?: boolean;
}

// This component is very complex for the limited reusable value it provides.
// I believe its solving the right problem the wrong way, but I didn't have enough time to create a better structure
// We will continue to look for simplified, low cost of entry components to solve the same problem
// This sort of component would greatly benefit from a story book implementation
export function CcxmReusableLabelledInsightDisplay({
  appendedContent,
  className,
  content,
  contentDelimiter,
  displayLabel = false,
  htmlId,
  label,
  multiline = true,
  ...other
}: CcxmReusableLabelledInsightDisplayProps) {
  return (
    <div
      className={classnames(className, "labelled-insight-display")}
      data-testId={other["data-testid"]}
      id={htmlId}
    >
      <div className={classnames("main", { singleline: !multiline })}>
        {displayLabel && (
          <label
            className={classNames({ multiline })}
            data-testid={`${other["data-testid"]}-label`}
            id={`${htmlId}-label`}
          >
            {label}
          </label>
        )}
        {content.map(({ className, id, value, ...other }, index) => {
          return (
            <Fragment key={id}>
              <span
                {...other}
                className={classNames(className, {
                  multiline,
                  singleline: !multiline
                })}
                id={id}
              >
                {value}
              </span>
              {multiline === false && index !== content.length - 1 && (
                <span
                  className="delimiter"
                  data-testid={`${other["data-testid"]}-delimiter`}
                >
                  {contentDelimiter}
                </span>
              )}
            </Fragment>
          );
        })}
      </div>
      {appendedContent && (
        <>
          {multiline === false && (
            <span
              className="delimiter"
              data-testid={`${other["data-testid"]}-delimiter`}
            >
              {contentDelimiter}
            </span>
          )}
          <div className={classnames({ multiline }, "appended")}>
            {appendedContent}
          </div>
        </>
      )}
    </div>
  );
}

export default CcxmReusableLabelledInsightDisplay;
