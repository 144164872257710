export enum SystemOfRecord {
  VIN = "VIN",
  COMMON = "COMMON"
}

export enum SupportedIdP {
  IDS = "IDS",
  BRIDGE = "BRIDGE"
}

export interface SystemOfRecordConfig {
  customerSystem: SystemOfRecord;
}

export interface IdPConfig {
  getJwtOverride?: () => Promise<string>;
  supportedIdP: SupportedIdP;
  errorCallback: (errorMessage: string) => void;
}

export enum FeatureFlags {
  DisableReply = "DisableReply"
}
