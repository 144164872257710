import { URLSearchParamsInit, useSearchParams } from "react-router-dom";
import { useRoutingContext } from "./context";
import { DefaultTab } from "@coxauto-ui/customer-interfaces";

export interface SetSearchParamsProps {
  multiDealConsumerId?: string;
  dealId?: string;
  defaultCustomerTab?: DefaultTab;
}

export function useRoutingSearchParams(
  defaultInit?: URLSearchParamsInit
): [URLSearchParams, (params: SetSearchParamsProps) => void] {
  const context = useRoutingContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const customSetSearchParams = (params: SetSearchParamsProps) => {
    if (!context.isModule) {
      const urlParams = new URLSearchParams();
      urlParams.set("multiDealConsumerId", params.multiDealConsumerId || "");
      urlParams.set("dealId", params.dealId || "");
      urlParams.set("defaultCustomerTab", params.defaultCustomerTab || "");
      setSearchParams(urlParams);
    }

    const multiDealConsumerId =
      params.multiDealConsumerId && params.multiDealConsumerId.length > 0
        ? params.multiDealConsumerId
        : null;

    const dealId =
      params.dealId && params.dealId.length > 0 ? params.dealId : null;

    const defaultCustomerTab =
      params.defaultCustomerTab && params.defaultCustomerTab.length > 0
        ? params.defaultCustomerTab
        : null;

    context.setSelectedMultiDealCustomerId(multiDealConsumerId);
    context.setSelectedDealId(dealId);
    context.setDefaultCustomerTab(defaultCustomerTab);
  };

  return [searchParams, customSetSearchParams];
}
