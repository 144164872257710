/**
 * Okta-react does not provide SecureRoute after react-router v5
 */
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { LoadingIndicator } from "@interstate/components/LoadingIndicator";
import authClient from "@coxauto-ui/core/auth/get-auth-client-crw";
import { expireViewAsCookie } from "@coxauto-ui/ccxm/util/get-view-as";
import { sessionIdLocalStorageKey } from "@coxauto-ui/util/get-session-identifier";

const originalUriSessionStorageKey = "crw-original-uri";

export const SecureRoute = () => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const token = await authClient.getAuth();

        const originalUri = sessionStorage.getItem(
          originalUriSessionStorageKey
        );

        if (originalUri) {
          sessionStorage.removeItem(originalUriSessionStorageKey);

          navigate(originalUri);
        }

        setAccessToken(token);
      } catch (error: any) {
        if (error.code === "LOGIN_REQUIRED") {
          sessionStorage.setItem(
            originalUriSessionStorageKey,
            window.location.pathname +
              window.location.search +
              window.location.hash
          );

          authClient.startInteractiveAuth();
        }
        if (error.code === "TIMED_OUT") {
          authClient.startInteractiveAuth();
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (document.cookie.includes(`LogUserOutCRW=true`)) {
      document.cookie =
        "LogUserOutCRW=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;Max-Age=-99999999;SameSite=None;Secure";
      expireViewAsCookie();
      window.sessionStorage.clear();
      localStorage.removeItem(sessionIdLocalStorageKey); // required for bridge bar
      authClient.clearStorage();
    }
  });

  return accessToken ? <Outlet /> : <LoadingIndicator />;
};
