import {
  BaseColors,
  EventResultEnum
} from "@coxauto-ui/communications-interfaces";
import { InformationCircleIcon } from "@interstate/components/Icons";
import styled from "styled-components";
import { messageDeliveryFailed, messageDeliveryFailedIcon } from "../testIds";
import { useContext } from "react";
import { ConversationContext } from "../context";

const StyledContainer = styled.div`
  display: block;
`;

const StyledMessageEventType = styled.div`
  display: inline-block;
`;

const StyledIconInfoOutline = styled(InformationCircleIcon)<{
  baseColors: BaseColors;
}>`
  color: ${props => props.baseColors.red600};
  display: inline-block;
  vertical-align: middle;
`;

const StyledMessageEventTypeError = styled.span<{ baseColors: BaseColors }>`
  color: ${props => props.baseColors.red600};
  font-size: 12px;
  margin-left: 0.2rem;
  display: inline-block;
  vertical-align: middle;
`;

export interface MessageEventResultProps {
  eventResult: EventResultEnum;
}

export function MessageEventResult(props: MessageEventResultProps) {
  const { eventResult } = props;
  const { baseColors } = useContext(ConversationContext);

  if (eventResult === EventResultEnum.FAILED) {
    return (
      <StyledContainer>
        <StyledMessageEventType>
          <StyledIconInfoOutline
            baseColors={baseColors}
            data-testid={messageDeliveryFailedIcon}
          />
          <StyledMessageEventTypeError
            baseColors={baseColors}
            data-testid={messageDeliveryFailed}
          >
            Message delivery failed
          </StyledMessageEventTypeError>
        </StyledMessageEventType>
      </StyledContainer>
    );
  }

  return null;
}

export default MessageEventResult;
