import { CcxmReusableBridgeBarFriendlySlidingDrawer } from "@coxauto-ui/ccxm/reusable/bridge-bar-friendly-sliding-drawer";
import { CustomerConversationApp } from "@coxauto-ui/communications-customer-conversation";
import { ConversationTypeEnum } from "@coxauto-ui/communications-interfaces";
import {
  Button,
  SelectInput,
  TextInput,
  ToggleSwitch
} from "@coxauto-ui/core-cx";
import authClient from "@coxauto-ui/core/auth/get-auth-client-crw";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

/* eslint-disable-next-line */
export interface CustomerConversationPageProps {}

export interface CustomerConversationPageState {
  customerId: string;
  disableReply: boolean;
  showCustomerInformation: boolean;
  showSlidingDrawer: boolean;
  slidingDrawerPositionRight: boolean;
  slidingDrawerWidth: string;
  title?: string;
  defaultTab?: ConversationTypeEnum;
  messageContent?: string;
}

export interface CustomerConversationPageSavedState {
  savedCustomerId: string;
  savedDisableReply: boolean;
  savedShowCustomerInformation: boolean;
  savedShowSlidingDrawer: boolean;
  savedSlidingDrawerPositionRight: boolean;
  savedSlidingDrawerWidth: number;
  savedTitle?: string;
  savedDefaultTab?: ConversationTypeEnum;
  savedMessageContent?: string;
}

const StyledCustomerConversationPage = styled.div`
  display: grid;
  height: calc(100vh - 42px);
  grid-template-columns: 400px 1fr;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    "header content"
    "settings content";
  grid-gap: 16px;
  margin: 16px;

  .cx-drawer__panel {
    z-index: 3;
  }

  .cx-drawer__background {
    z-index: 2;
  }
`;

const StyledHeader = styled.div`
  grid-area: header;
`;

const StyledSettings = styled.div`
  grid-area: settings;
`;

const StyledContent = styled.div`
  grid-area: content;
  position: relative;
`;

const StyledSlidingDrawerContent = styled.div`
  position: relative;
  height: 100%;
`;

const defaultState: CustomerConversationPageState = {
  customerId: "",
  disableReply: false,
  showCustomerInformation: true,
  showSlidingDrawer: false,
  slidingDrawerPositionRight: false,
  slidingDrawerWidth: "530",
  title: undefined,
  defaultTab: undefined,
  messageContent: undefined
};

const defaultSavedState: CustomerConversationPageSavedState = {
  savedCustomerId: "",
  savedDisableReply: false,
  savedShowCustomerInformation: true,
  savedShowSlidingDrawer: false,
  savedSlidingDrawerPositionRight: false,
  savedSlidingDrawerWidth: 530,
  savedTitle: undefined,
  savedDefaultTab: undefined,
  savedMessageContent: undefined
};

const htmlId = "customer-conversation-page";

export function CommonConversationPage(props: CustomerConversationPageProps) {
  const [
    {
      customerId,
      defaultTab,
      disableReply,
      messageContent,
      showCustomerInformation,
      showSlidingDrawer,
      slidingDrawerPositionRight,
      slidingDrawerWidth,
      title
    },
    setState
  ] = useState(defaultState);
  const [
    {
      savedCustomerId,
      savedDisableReply,
      savedShowCustomerInformation,
      savedShowSlidingDrawer,
      savedSlidingDrawerPositionRight,
      savedSlidingDrawerWidth,
      savedTitle,
      savedDefaultTab,
      savedMessageContent
    },
    setSavedState
  ] = useState(defaultSavedState);
  const [bridgeDisplayName, setBridgeDisplayName] = useState<string>("");

  useEffect(() => {
    (async () => {
      const claims = await authClient.getIdTokenClaims();
      setBridgeDisplayName(claims?.name || "");
    })();
  }, []);

  const onChange = useCallback(
    (e: { target: { name: string; value: string } }) => {
      setState(prev => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    },
    []
  );

  const onClick = useCallback(
    (e: { target: { name: string; value: string } }) => {
      setState(prev => {
        return { ...prev, [e.target.name]: e.target.value === "checked" };
      });
    },
    []
  );

  const onHide = useCallback(() => {
    setSavedState(prev => {
      return {
        ...prev,
        savedCustomerId: "",
        savedShowSlidingDrawer: !prev.savedShowSlidingDrawer
      };
    });
  }, []);

  const onButtonClick = useCallback(() => {
    setSavedState({
      savedCustomerId: customerId,
      savedDisableReply: disableReply,
      savedShowCustomerInformation: showCustomerInformation,
      savedShowSlidingDrawer: showSlidingDrawer,
      savedSlidingDrawerPositionRight: slidingDrawerPositionRight,
      savedSlidingDrawerWidth:
        (slidingDrawerWidth && parseInt(slidingDrawerWidth, 10)) || 400,
      savedTitle: title,
      savedDefaultTab: defaultTab,
      savedMessageContent: messageContent
    });
  }, [
    customerId,
    disableReply,
    showCustomerInformation,
    showSlidingDrawer,
    slidingDrawerPositionRight,
    slidingDrawerWidth,
    title,
    defaultTab,
    messageContent
  ]);

  const occConfig = useMemo(() => {
    return {
      username: bridgeDisplayName,
      disableReply: savedDisableReply,
      defaultTab: savedDefaultTab,
      messageContent: savedMessageContent
    };
  }, [
    bridgeDisplayName,
    savedDefaultTab,
    savedDisableReply,
    savedMessageContent
  ]);

  return (
    <StyledCustomerConversationPage id={htmlId}>
      <StyledHeader id={`${htmlId}-header`}>
        <h1
          data-testid={`${htmlId}-header-title`}
          id={`${htmlId}-header-title`}
        >
          Customer Conversation Page
        </h1>
        <p
          data-testid={`${htmlId}-header-description`}
          id={`${htmlId}-header-description`}
        >
          This is the Customer Conversation demo page. Enter a common consumer
          id to launch the component
        </p>
      </StyledHeader>
      <StyledSettings id={`${htmlId}-settings`}>
        <TextInput
          data-testid={`${htmlId}-customer-id`}
          htmlId={`${htmlId}-customer-id`}
          label="Common Consumer Id"
          name="customerId"
          value={customerId}
          onChange={onChange}
        />
        <TextInput
          data-testid={`${htmlId}-title`}
          htmlId={`${htmlId}-title`}
          label="Title"
          name="title"
          value={title || ""}
          onChange={onChange}
        />
        <TextInput
          data-testid={`${htmlId}-message-content`}
          htmlId={`${htmlId}-message-content`}
          label="Message content"
          name="messageContent"
          value={messageContent || ""}
          onChange={onChange}
        />
        <SelectInput
          data-testid={`${htmlId}-default-tab`}
          htmlId={`${htmlId}-default-tab`}
          label="Default Tab"
          name="defaultTab"
          options={[
            { value: ConversationTypeEnum.TEXT, label: "TEXT" },
            { value: ConversationTypeEnum.EMAIL, label: "EMAIL" },
            { value: ConversationTypeEnum.PHONE, label: "PHONE" },
            { value: ConversationTypeEnum.ALL, label: "ALL" }
          ]}
          onChange={onChange}
        />
        <ToggleSwitch
          checked={disableReply}
          data-testid={`${htmlId}-disable-reply`}
          htmlId={`${htmlId}-disable-reply`}
          label="Disable Reply"
          name="disableReply"
          onClick={onClick}
        />
        <ToggleSwitch
          checked={showCustomerInformation}
          data-testid={`${htmlId}-show-customer-info`}
          htmlId={`${htmlId}-show-customer-info`}
          label="Show Customer Information"
          name="showCustomerInformation"
          onClick={onClick}
        />
        <Button
          data-testid={`${htmlId}-launch-customer-conversation-button`}
          disabled={customerId === ""}
          htmlId={`${htmlId}-launch-customer-conversation-button`}
          onClick={onButtonClick}
        >
          {savedCustomerId === "" ? "Launch OCC Inbox" : "Update OCC Inbox"}
        </Button>
        <hr />
        <TextInput
          data-testid={`${htmlId}-sliding-drawer-width`}
          htmlId={`${htmlId}-sliding-drawer-width`}
          inputSuffix="px"
          label="Sliding Drawer Width"
          name="slidingDrawerWidth"
          value={slidingDrawerWidth}
          onChange={onChange}
        />
        <ToggleSwitch
          checked={showSlidingDrawer}
          data-testid={`${htmlId}-show-sliding-drawer`}
          htmlId={`${htmlId}-show-sliding-drawer`}
          label="Show the Customer Conversation in a Sliding Drawer"
          name="showSlidingDrawer"
          onClick={onClick}
        />
        <ToggleSwitch
          checked={slidingDrawerPositionRight}
          data-testid={`${htmlId}-sliding-drawer-position-right`}
          htmlId={`${htmlId}-sliding-drawer-position-right`}
          label="Position the Sliding Drawer on the right boundary"
          name="slidingDrawerPositionRight"
          onClick={onClick}
        />
      </StyledSettings>
      <StyledContent id={`${htmlId}-content`}>
        {savedCustomerId && !savedShowSlidingDrawer && (
          <CustomerConversationApp
            customerId={savedCustomerId}
            getAuthOverride={authClient.getAuth}
            occConfig={occConfig}
            showCustomerInfo={savedShowCustomerInformation}
            title={savedTitle}
          />
        )}
      </StyledContent>
      {savedCustomerId && savedShowSlidingDrawer && (
        <CcxmReusableBridgeBarFriendlySlidingDrawer
          data-testid={`${htmlId}-sliding-drawer`}
          htmlId={`${htmlId}-sliding-drawer`}
          panelWidth={savedSlidingDrawerWidth}
          position={savedSlidingDrawerPositionRight ? "right" : "left"}
          show={savedShowSlidingDrawer}
          onHide={onHide}
        >
          <StyledSlidingDrawerContent>
            <CustomerConversationApp
              customerId={savedCustomerId}
              getAuthOverride={authClient.getAuth}
              occConfig={occConfig}
              showCustomerInfo={savedShowCustomerInformation}
              title={savedTitle}
            />
          </StyledSlidingDrawerContent>
        </CcxmReusableBridgeBarFriendlySlidingDrawer>
      )}
    </StyledCustomerConversationPage>
  );
}

export default CommonConversationPage;
