import { createSlice } from "@reduxjs/toolkit";
import { ConfigurationState } from "@coxauto-ui/ccxm/interfaces";
import getViewportMode from "@coxauto-ui/ccxm/util/get-viewport-mode";

export const CONFIGURATION_FEATURE_KEY = "configuration";

export const initialConfigurationState: ConfigurationState = {
  customerId: null,
  viewportMode: getViewportMode()
};

export const configurationSlice = createSlice({
  name: CONFIGURATION_FEATURE_KEY,
  initialState: initialConfigurationState,
  reducers: {
    setCustomerId: (state, action) => {
      return { ...state, customerId: action.payload };
    },
    setViewportMode: (state, action) => {
      return { ...state, viewportMode: action.payload };
    }
  }
});

export const configurationReducer = configurationSlice.reducer;

export const configurationActions = configurationSlice.actions;

export const getConfigurationState = (rootState: any): ConfigurationState =>
  rootState[CONFIGURATION_FEATURE_KEY];
