import { useContext, useEffect } from "react";
import { ConversationContext, ConversationDetailsContext } from "../context";
import {
  ContactDetail,
  ConversationTypeEnum as type
} from "@coxauto-ui/communications-interfaces";
import {
  SelectInput,
  SelectInputEventValue
} from "@interstate/components/SelectInput";
import { conversationChannelSelectorId } from "../testIds";
import { InterstateOnChangeEvent } from "@interstate/components/InterstateEvents";

export interface ChannelValueSelectorProps {
  isDisabled: boolean;
}

export function ChannelValueSelector({
  isDisabled
}: ChannelValueSelectorProps) {
  const {
    currentConversationType,
    selectedChannelValues,
    setSelectedChannelValues,
    recentContactEmail,
    recentContactText
  } = useContext(ConversationDetailsContext);

  const { contactDetails } = useContext(ConversationContext);

  const onChannelSelection = (
    e: InterstateOnChangeEvent<SelectInputEventValue>
  ) => {
    if (!currentConversationType) return;
    const value = e.target.value?.toString();
    if (value === undefined) return;
    const item = selectedChannelValues?.get(currentConversationType);

    if (item && item !== value) {
      const newMap = new Map(selectedChannelValues);

      newMap.set(currentConversationType, value);
      setSelectedChannelValues(newMap);
    }
  };

  const details = (): ContactDetail[] => {
    const contactChannels = contactDetails.contactChannels[
      currentConversationType || type.NONE
    ]?.filter(x => x.value);
    return contactChannels ?? [];
  };
  const getConsentStatus = (val: string, cansend: boolean) => {
    if (cansend) return val;
    else return "";
  };
  useEffect(() => {
    const detailMap = new Map<type, string>([
      [type.EMAIL, recentContactEmail],
      [type.TEXT, recentContactText],
      [type.PHONE, recentContactText]
    ]);
    setSelectedChannelValues(detailMap);
  }, [recentContactEmail, recentContactText, setSelectedChannelValues]);

  return (
    <SelectInput
      data-testid={conversationChannelSelectorId}
      disabled={isDisabled}
      displayDeselectOption={false}
      displayLabel={
        currentConversationType === type.PHONE ||
        currentConversationType === type.TEXT
      }
      htmlId="conversation-channel-pane-dropdown"
      label="Choose Phone Number"
      name="ConversationChannelValueDropdown"
      options={details().map((channel: ContactDetail) => {
        return {
          value: channel.formattedValue,
          label:
            channel.formattedValue +
            " " +
            getConsentStatus(channel.consent.status, !channel.consent.canSend),
          disabled: !channel.consent.canSend
        };
      })}
      value={selectedChannelValues?.get(currentConversationType || type.NONE)}
      onChange={onChannelSelection}
    />
  );
}

export default ChannelValueSelector;
