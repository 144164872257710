import { Environment } from "@coxauto-ui/communications-interfaces";

const nonprodEnvs = ["localhost", "np.", "np-", "dev", "dev2", "qa"];

export const deriveEnvironmentName = () => {
  const url = window.location.href;
  return nonprodEnvs.some(x => url.includes(x))
    ? Environment.nonprod
    : Environment.prod;
};
