import { StrictMode, useEffect, useState } from "react";
import { Provider } from "react-redux";
import { store } from "@coxauto-ui/ccxm/store";
import { CxThemeProvider, ToastContainer } from "@coxauto-ui/core/cx";
import { InterstateThemeProvider } from "@interstate/components/InterstateThemeProvider";
import { interstateThemeRegistry } from "@interstate/components/InterstateTheming";
import { LoadingIndicator } from "@interstate/components/LoadingIndicator";
import { CcxmCloseMyDealsList } from "@coxauto-ui/ccxm/close-my-deals/list";
import { createGlobalStyle } from "styled-components";
import AppWrapper from "../app-wrapper";
import authClient from "@coxauto-ui/core/auth/get-auth-client-crw";
import { RoutingContextProvider } from "@coxauto-ui/ccxm-routing";

const GlobalStyle = createGlobalStyle`
  .MuiPopper-root {
    z-index: 4;
  }
  .Toastify__toast-container {
    transform: translate(0,18px);
  }
  body {
    background-color: #fff;
  }
`;

const interstateThemes = [interstateThemeRegistry];

export interface CRWDealListModuleProps {
  getAuthOverride?: () => Promise<string>;
}

export function CRWDealListModule({ getAuthOverride }: CRWDealListModuleProps) {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (document.cookie.includes(`LogUserOutCRW=true`)) {
      document.cookie =
        "LogUserOutCRW=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;Max-Age=-99999999;SameSite=None;Secure";
      authClient.clearStorage();
    }
  });

  useEffect(() => {
    if (getAuthOverride) {
      authClient.getAuth = getAuthOverride;
    }

    (async () => {
      try {
        const result = await authClient.getAuth();

        setAccessToken(result);
        setIsLoading(false);
      } catch (ex) {
        setAccessToken(null);
        setIsLoading(false);
      }
    })();
  }, [getAuthOverride]);

  return accessToken ? (
    <RoutingContextProvider isModule={true}>
      <Provider store={store}>
        <StrictMode>
          <CxThemeProvider themeName={"Cx"}>
            <InterstateThemeProvider
              applicationName="CRW"
              scopeName="CRW"
              themeRegistries={interstateThemes}
            >
              <AppWrapper>
                <CcxmCloseMyDealsList />
                <ToastContainer />
                <GlobalStyle />
              </AppWrapper>
            </InterstateThemeProvider>
          </CxThemeProvider>
        </StrictMode>
      </Provider>
    </RoutingContextProvider>
  ) : isLoading ? (
    <InterstateThemeProvider
      applicationName="CRW"
      scopeName="CRW"
      themeRegistries={interstateThemes}
    >
      <LoadingIndicator />
    </InterstateThemeProvider>
  ) : (
    <div>Sorry, something went wrong. Please try again later.</div>
  );
}

export default CRWDealListModule;
