import { loadRemoteModule } from "@coxauto-ui/core/module-federation";
import React, { Suspense } from "react";

const BusinessTestPageModule = React.lazy(() =>
  loadRemoteModule("customer", "./BusinessTestPageModule")
);

export function BusinessTestPage() {
  return (
    <Suspense fallback={<div>Loading test page...</div>}>
      <BusinessTestPageModule />
    </Suspense>
  );
}
