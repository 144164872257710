import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootAdminState } from "./store";
import {
  ChangeDealerConfigurationRequest,
  CommonOrgDealerRequest,
  DealerItem,
  DealerManagementState,
  DealerStats,
  DealersRowsResponse,
  SearchDealersRequest
} from "@coxauto-ui/tenant/interfaces";
import {
  addDealer,
  getDealerStats,
  getDealers,
  postDealerConfiguration,
  searchDealers
} from "@coxauto-ui/tenant/api";

export const DEALER_MANAGEMENT_FEATURE_KEY = "dealerManagement";

export const initialDealerManagementState: DealerManagementState = {
  dealers: [],
  loadingStatus: "not loaded",
  dealerStats: null,
  nextUrl: ""
};

export const fetchListDealers = createAsyncThunk(
  "dealerManagement/fetchListDealers",
  async (loadMore: boolean | undefined, { getState }) => {
    const state = getState() as RootAdminState;
    const { nextUrl } = state.dealerManagement;
    const next = loadMore ? nextUrl || "" : "";
    return (await getDealers(next)) as DealersRowsResponse;
  }
);

export const fetchAddDealer = createAsyncThunk(
  "dealerManagement/fetchAddDealer",
  async (request: CommonOrgDealerRequest) => {
    return (await addDealer(request)) as DealerItem;
  }
);

export const fetchSearchDealers = createAsyncThunk(
  "dealerManagement/searchDealers",
  async ({ commonOrgDealer }: SearchDealersRequest) => {
    return await searchDealers(commonOrgDealer);
  }
);

export const fetchDealerStats = createAsyncThunk(
  "dealerManagement/fetchDealerStats",
  async () => {
    return (await getDealerStats()) as DealerStats;
  }
);

export const fetchChangeDealerConfiguration = createAsyncThunk(
  "dealerManagement/fetchChangeDealerConfiguration",
  async (request: ChangeDealerConfigurationRequest) => {
    if (request) {
      await postDealerConfiguration(request.dealerConfiguration);
      return request;
    }
    return null;
  }
);

export const dealerManagementSlice = createSlice({
  name: DEALER_MANAGEMENT_FEATURE_KEY,
  initialState: initialDealerManagementState,
  reducers: {
    setDealers: (state, action) => {
      return { ...state, dealers: action.payload };
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchListDealers.pending, state => {
      state.loadingStatus = "loading";
    });
    builder.addCase(fetchListDealers.fulfilled, (state, action) => {
      if (state.nextUrl === "") {
        state.dealers = action.payload.items;
      } else {
        state.dealers = [...state.dealers, ...action.payload.items];
      }
      state.nextUrl = action.payload.next;
      state.loadingStatus = "loaded";
    });
    builder.addCase(fetchListDealers.rejected, state => {
      state.loadingStatus = "error";
      state.nextUrl = "";
      state.errorMessage =
        "Sorry, Dealer Management failed, please contact ams-cxm-escalations channel.";
    });

    builder.addCase(fetchChangeDealerConfiguration.pending, state => {
      state.loadingStatus = "loading";
    });
    builder.addCase(
      fetchChangeDealerConfiguration.fulfilled,
      (state: DealerManagementState, action) => {
        const dealerConfiguration = action.payload?.dealerConfiguration;
        if (dealerConfiguration) {
          state.dealers = state.dealers.map(dealer => {
            if (dealer.id === dealerConfiguration.id) {
              return {
                ...dealer,
                dealerConfiguration
              };
            }
            return dealer;
          });
        }
        state.loadingStatus = "loaded";
      }
    );
    builder
      .addCase(fetchChangeDealerConfiguration.rejected, (state, action) => {
        const enabling = action.meta.arg.enabling;
        if (enabling) {
          state.errorMessage =
            "Sorry, enabling this dealer for CXM failed, please contact the ams-cxm-escalations channel.";
        } else {
          state.errorMessage =
            "Sorry, disabling this dealer for CXM failed, please contact the ams-cxm-escalations channel.";
        }
        state.loadingStatus = "error";
      })
      .addCase(fetchAddDealer.pending, state => {
        state.loadingStatus = "loading";
        state.newDealer = "";
      })
      .addCase(fetchAddDealer.fulfilled, (state, action) => {
        state.newDealer = action.payload.id;
        state.loadingStatus = "loaded";
      })
      .addCase(fetchAddDealer.rejected, (state, action) => {
        state.errorMessage = action.error.message;
        state.newDealer = "";
        state.loadingStatus = "error";
      })
      .addCase(fetchDealerStats.pending, state => {
        state.loadingStatus = "loading";
      })
      .addCase(fetchDealerStats.fulfilled, (state, action) => {
        state.dealerStats = action.payload;
        state.loadingStatus = "loaded";
      })
      .addCase(fetchDealerStats.rejected, (state, action) => {
        state.errorMessage =
          "Sorry, Dealer Management failed, please contact ams-cxm-escalations channel.";
        state.dealerStats = null;
        state.loadingStatus = "error";
      })
      .addCase(fetchSearchDealers.pending, (state, action) => {
        state.loadingStatus = "loading";
        state.nextUrl = "";
        state.newDealer = "";
      })
      .addCase(fetchSearchDealers.fulfilled, (state, action) => {
        state.dealers = action.payload;
        state.loadingStatus = "loaded";
      })
      .addCase(fetchSearchDealers.rejected, (state, action) => {
        state.errorMessage =
          "Sorry, Dealer Management failed, please contact ams-cxm-escalations channel.";
        state.loadingStatus = "error";
      });
  }
});

export const dealerManagementReducer = dealerManagementSlice.reducer;

export const dealerManagementActions = dealerManagementSlice.actions;

export const getDealerManagementState = (
  rootState: any
): DealerManagementState => rootState[DEALER_MANAGEMENT_FEATURE_KEY];
