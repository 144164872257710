import { loadRemoteModule } from "@coxauto-ui/core/module-federation";
import React, { Suspense } from "react";

const CustomerCardTestPageModule = React.lazy(() =>
  loadRemoteModule("customer", "./CustomerCardTestPageModule")
);

export default function CustomerCardPage() {
  return (
    <Suspense fallback={<div>Loading test page...</div>}>
      <CustomerCardTestPageModule />
    </Suspense>
  );
}
