import {
  FilterSequence,
  FilterSequenceFilterType,
  OptionFilterComponentType,
  OptionFilters,
  TextFilters
} from "@coxauto-ui/ccxm/interfaces";
import {
  CcxmReusableCollapsibleCheckboxList,
  CcxmReusableDropdownSelect
} from "@coxauto-ui/ccxm/reusable/collapsible-checkbox-list";
import { ReactNode, useCallback, useMemo } from "react";
import styled from "styled-components";

/* eslint-disable-next-line */
export interface CcxmInsightsCheckboxFilterProps {
  disabled: boolean;
  filterSequence: FilterSequence[];
  optionFilters: OptionFilters;
  textFilters: TextFilters;
  onChange: (
    optionFiltersToApply: OptionFilters,
    textFiltersToApply: TextFilters
  ) => void;
}

const StyledFiltersWrapper = styled.div`
  .form-group {
    text-align: left;

    > .control-label {
      font-weight: bold;
    }

    .checkbox label {
      flex-wrap: nowrap;
    }

    .cx-checkbox input[type="checkbox"] {
      flex-shrink: 0;
    }
  }
`;

export function CcxmInsightsCheckboxFilter({
  disabled,
  filterSequence,
  optionFilters,
  textFilters,
  onChange
}: CcxmInsightsCheckboxFilterProps) {
  const onFilterSelect = useCallback(
    (e: { target: { name: string; value: string[] } }) => {
      const optionFiltersToApply = {
        ...optionFilters,
        [e.target.name]: {
          ...optionFilters[e.target.name],
          selectedValues: e.target.value
        }
      };

      onChange(optionFiltersToApply, textFilters);
    },
    [optionFilters, textFilters, onChange]
  );

  const onDropdownSelect = useCallback(
    (e: { target: { name: string; value: string | null } }) => {
      const optionFiltersToApply = {
        ...optionFilters,
        [e.target.name]: {
          ...optionFilters[e.target.name],
          selectedValues: !e.target.value ? [] : [e.target.value]
        }
      };

      onChange(optionFiltersToApply, textFilters);
    },
    [optionFilters, textFilters, onChange]
  );

  const collapsibleCheckboxFilters = useMemo(() => {
    return filterSequence.reduce<ReactNode[]>(
      (list, { categoryType, filterType }) => {
        if (filterType === FilterSequenceFilterType.optionFilter) {
          const { displayName, options, selectedValues, componentType } =
            optionFilters[categoryType];
          const component =
            componentType === OptionFilterComponentType.optionList ? (
              <CcxmReusableCollapsibleCheckboxList
                data-selectedoptions={JSON.stringify(selectedValues)}
                expandedByDefault={true}
                htmlId={`${categoryType.toLowerCase()}-checkbox-list`}
                key={`${categoryType}-checkbox-list`}
                label={displayName}
                name={categoryType}
                options={options.map(x => ({
                  disabled,
                  value: x.value,
                  label:
                    categoryType === "vehicleModel" ||
                    categoryType === "vehicleMake" ||
                    categoryType === "dealLifecycleStatus" ||
                    categoryType === "userId" ||
                    categoryType === "isAccepted"
                      ? `${x.label} (${x.count})`
                      : x.label
                }))}
                values={selectedValues}
                onChange={onFilterSelect}
              />
            ) : componentType ===
              OptionFilterComponentType.optionSingleSelect ? (
              <CcxmReusableDropdownSelect
                data-selectedoptions={JSON.stringify(selectedValues)}
                disabled={disabled}
                htmlId={`${categoryType.toLowerCase()}-dropdown-single-select-list`}
                key={`${categoryType}-dropdown-single-select-list`}
                label={displayName}
                name={categoryType}
                options={options}
                placeholder="Any"
                size="small"
                value={selectedValues.length === 0 ? "" : selectedValues[0]}
                onChange={onDropdownSelect}
              />
            ) : null;
          // eslint-disable-next-line no-param-reassign
          list = [...list, component];
        }
        return list;
      },
      []
    );
  }, [filterSequence, optionFilters, onFilterSelect, onDropdownSelect]);

  return (
    <StyledFiltersWrapper
      data-testid="checkbox-filters"
      id="checkbox-filter-wrapper"
    >
      {collapsibleCheckboxFilters}
    </StyledFiltersWrapper>
  );
}

export default CcxmInsightsCheckboxFilter;
