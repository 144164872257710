import { useContext, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { ToastContainer } from "@interstate/components/Toast";
import { ConversationContext, ConversationDetailsContext } from "./context";
import { ConversationPaneHeader } from "./conversation-pane-header";
import { ReplyApp } from "./reply/app";
import { LoadingIndicator } from "@interstate/components/LoadingIndicator";
import {
  BaseColors,
  ConversationItem,
  ConversationTypeEnum,
  ReplyOption,
  SendCommunicationAttachmentResponse
} from "@coxauto-ui/communications-interfaces";
import ConversationPaneTabs from "./conversation-pane-tabs";
import { conversationListError, conversationListTestId } from "./testIds";
import { MessageItemDetails } from "./list/item-details";
import ConversationPaneContent from "./conversation-pane-content";
import { useInterstateToken } from "@interstate/components/InterstateThemeProvider";

const StyledConversationPane = styled.div<{ baseColors: BaseColors }>`
  margin: 0.25em;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: ${props => props.baseColors.white};
  .cx-tabs__content {
    display: none;
  }
  .conversation-icon.gray {
    color: ${props => props.baseColors.gray400};
  }
  .conversation-icon.red {
    color: ${props => props.baseColors.red600};
  }
  .conversation-icon.green {
    color: ${props => props.baseColors.green500};
  }
  .conversation-icon.blue {
    color: ${props => props.baseColors.blue600};
  }
  .conversation-icon.orange {
    color: ${props => props.baseColors.orange400};
  }
  .item {
    .message-item {
      padding-top: 0.75em;
      padding-bottom: 0.75em;
      word-wrap: break-word;
    }
    .message-item-bubble {
      border-radius: 0.3em;
      cursor: pointer;
      padding: 0.5em;
      display: inline-block;
      max-width: 85%;
      text-align: left;
      min-width: 51%;
    }
    .message-item-bubble.full {
      max-width: 100%;
      min-width: 100%;
    }
    .message-item-inbound {
      text-align: left;
    }
    .message-item-inbound-bubble {
      background-color: ${props => props.baseColors.white};
      color: ${props => props.baseColors.black};
      margin-bottom: 4px;
    }
    .message-item-attachment-inbound-bubble {
      padding: 0em;
      margin-bottom: 4px;
    }
    .message-item-attachment-inbound-view {
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
    }
    .message-item-outbound {
      text-align: right;
    }
    .message-item-outbound-bubble {
      background-color: ${props => props.baseColors.sky100};
      color: ${props => props.baseColors.black};
      margin-bottom: 4px;
    }
    .message-item-outbound-bubble-error {
      background-color: ${props => props.baseColors.gray100};
      color: ${props => props.baseColors.gray700};
    }
    .message-item-attachment-outbound-bubble {
      padding: 0em;
      margin-bottom: 4px;
    }
    .message-item-attachment-outbound-view {
      margin-top: 5px;
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-end;
    }
    .attachment-view-container {
      border-radius: 8px;
      width: 150px;
      height: 150px;
      background-color: ${props => props.baseColors.gray100};
      box-shadow: 0 0 4px 0 ${props => props.baseColors.gray300};
      cursor: pointer;
    }
    .attachment-view-thumbnail-container {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: ${props => props.baseColors.gray400};
      .attachment-view-thumbnail {
        border-radius: 8px;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .fallback-container {
        text-align: center;
      }
      .photo-icon {
        font-size: xx-large;
      }
      .error-message {
        margin-top: 0;
        font-size: 10px;
      }
      .attachment-view-thumbnail-download-container {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
    .attachment-audio-container {
      height: 48px;
      padding: 10px;
    }
    .attachment-play-audio-container {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .loading-audio {
        text-align: center;
      }
      .audio-player {
        display: block;
        cursor: auto;
        height: 2em;
      }
      .audio-with-download-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
      }
    }
  }
`;

const StyledReplyApp = styled.div<{ baseColors: BaseColors }>`
  position: relative;
  background-color: ${props => props.baseColors.white};
`;

export function ConversationPane() {
  const { customerName, error, setBaseColors, occConfig } =
    useContext(ConversationContext);
  const [conversationItems, setConversationItems] = useState<
    ConversationItem[]
  >([]);
  const [displayConversationItems, setDisplayConversationItems] = useState<
    ConversationItem[]
  >([]);
  const [selectedReplyOption, setSelectedReplyOption] =
    useState<ReplyOption | null>(null);
  const [selectedConversationItem, setSelectedConversationItem] =
    useState<ConversationItem | null>(null);
  const [replyText, setReplyText] = useState(occConfig.messageContent ?? "");
  const [replyTextSubject, setReplyTextSubject] = useState("");
  const [canLoadList, setCanLoadList] = useState(false);
  const [latestConversationItemType, setLatestConversationItemType] =
    useState<ConversationTypeEnum | null>(null);
  const [currentConversationType, setCurrentConversationType] =
    useState<ConversationTypeEnum | null>(null);
  useEffect(() => {
    setCanLoadList(customerName?.trim().length > 0);
  }, [customerName]);
  const [recentContactEmail, setRecentContactEmail] = useState("");
  const [recentContactText, setRecentContactText] = useState("");
  const [xSignature, setXSignature] = useState("");
  const [selectedChannelValues, setSelectedChannelValues] = useState<Map<
    ConversationTypeEnum,
    string
  > | null>(null);
  const [messageCallContacted, setMessageCallContacted] = useState("");
  const [messageCallType, setMessageCallType] = useState("");
  const [messageAttachments, setMessageAttachments] = useState<
    SendCommunicationAttachmentResponse[]
  >([]);
  const colorsSetRef = useRef(false);
  const getToken = useInterstateToken();
  const baseColors = useMemo(
    () => ({
      sky50: getToken("base.color.sky.50"),
      sky100: getToken("base.color.sky.100"),
      white: getToken("base.color.common.white"),
      black: getToken("base.color.common.black"),
      gray50: getToken("base.color.gray.50"),
      gray100: getToken("base.color.gray.100"),
      gray300: getToken("base.color.gray.300"),
      gray400: getToken("base.color.gray.400"),
      gray700: getToken("base.color.gray.700"),
      red600: getToken("base.color.red.600"),
      green500: getToken("base.color.green.500"),
      blue600: getToken("base.color.blue.600"),
      blue700: getToken("base.color.blue.700"),
      orange400: getToken("base.color.orange.400")
    }),
    [getToken]
  );

  useEffect(() => {
    if (!colorsSetRef.current) {
      setBaseColors(baseColors);
      colorsSetRef.current = true;
    }
  }, [baseColors, setBaseColors]);

  useEffect(() => {
    setReplyText(occConfig.messageContent ?? "");
  }, [occConfig]);

  const providerValue = useMemo(() => {
    return {
      conversationItems,
      displayConversationItems,
      selectedConversationItem,
      selectedReplyOption,
      replyText,
      replyTextSubject,
      latestConversationItemType,
      currentConversationType,
      selectedChannelValues,
      recentContactEmail,
      recentContactText,
      messageCallContacted,
      messageCallType,
      xSignature,
      messageAttachments,
      setConversationItems,
      setDisplayConversationItems,
      setSelectedConversationItem,
      setSelectedReplyOption,
      setReplyText,
      setReplyTextSubject,
      setLatestConversationItemType,
      setCurrentConversationType,
      setSelectedChannelValues,
      setRecentContactEmail,
      setRecentContactText,
      setMessageCallContacted,
      setMessageCallType,
      setXSignature,
      setMessageAttachments
    };
  }, [
    conversationItems,
    displayConversationItems,
    selectedConversationItem,
    selectedReplyOption,
    replyText,
    replyTextSubject,
    latestConversationItemType,
    currentConversationType,
    selectedChannelValues,
    recentContactEmail,
    recentContactText,
    messageCallContacted,
    messageCallType,
    xSignature,
    messageAttachments
  ]);

  if (error)
    return (
      <span data-testid={conversationListError} id={conversationListError}>
        {error}
      </span>
    );
  return (
    <ConversationDetailsContext.Provider value={providerValue}>
      {(canLoadList && (
        <StyledConversationPane
          baseColors={baseColors}
          data-testid={conversationListTestId}
        >
          <ToastContainer />
          <ConversationPaneHeader />
          {!selectedConversationItem && <ConversationPaneTabs />}
          {!selectedConversationItem && <ConversationPaneContent />}
          {selectedConversationItem && <MessageItemDetails />}
          <StyledReplyApp baseColors={baseColors}>
            <ReplyApp />
          </StyledReplyApp>
        </StyledConversationPane>
      )) || (
        <LoadingIndicator
          htmlId="conversation-pane-loading-indicator"
          text="Loading Communications"
        />
      )}
    </ConversationDetailsContext.Provider>
  );
}

export default ConversationPane;
