import styled from "styled-components";

// We export these styles to be consumed in a single location so they are rendered once.
// This is a performance optimization
export const StyledLabelledInsightDisplayWrapper = styled.div`
  .labelled-insight-display {
    display: flex;
    height: min-content;
    min-width: 0;

    .delimiter {
      margin: 0 8px;
      color: #959595;
    }

    .main {
      flex: 0 1 auto;
      overflow: hidden;
      min-width: 0;
      max-width: 100%;
      line-height: 20px;

      &.singleline {
        display: flex;
        align-items: Baseline;
      }

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
      }

      .multiline {
        width: 100%;
        display: block;
      }
    }

    .appended {
      display: flex;
      gap: 8px;
      flex: 1 0 auto;
      align-items: baseline;

      &.multiline {
        margin-left: 8px;
        align-self: flex-end;
      }
    }
  }
`;
