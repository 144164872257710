import { useContext } from "react";
// eslint-disable-next-line
import { TagManager } from "@coxauto-ui/core-third-party-ga4";
import { ConversationContext, ConversationDetailsContext } from "../context";
import { ArrowUturnLeftIcon } from "@interstate/components/Icons";
import { Button } from "@interstate/components/Button";
import { ConversationTypeEnum } from "@coxauto-ui/communications-interfaces";
import { itemDetailsBackButtonId } from "../testIds";

export function ItemDetailsBackButton() {
  const { customerId } = useContext(ConversationContext);
  const { selectedConversationItem, setSelectedConversationItem } = useContext(
    ConversationDetailsContext
  );

  const goBack = () => {
    TagManager.event({
      event: "productEvent",
      eventName: "Return To Conversation",
      eventProperties: {
        packagedBusinessCapability: "OCC Inbox",
        commonCustomerId: customerId,
        communicationChannel: undefined,
        replyContext: undefined,
        errorMessage: undefined
      }
    });
    setSelectedConversationItem(null);
  };

  return (
    <Button
      buttonStyle="secondary"
      data-testid={itemDetailsBackButtonId}
      id={itemDetailsBackButtonId}
      size="small"
      startIcon={<ArrowUturnLeftIcon />}
      onClick={goBack}
    >
      {(() => {
        switch (selectedConversationItem?.type) {
          case ConversationTypeEnum.EMAIL:
            return <div>Back To Emails</div>;
          case ConversationTypeEnum.TEXT:
            return <div>Back To Texts</div>;
          default:
            return <div>Back To Phone</div>;
        }
      })()}
    </Button>
  );
}
