import { ResponseError } from "../interfaces/api-error";

export const getErrorMessage = (response: ResponseError) => {
  return JSON.stringify(response);
};

export const isResponseError = (
  response: unknown
): response is ResponseError => {
  return (response as ResponseError).isError;
};
