/* exported from a scope: feature lib + would make us pull the entire card lib with the current export*/
// import { CustomerCardTabTypes } from "@coxauto-ui/customer-card";
// duplicating for now
export enum CustomerCardTabTypes {
  information = "information",
  deal = "deal",
  inbox = "inbox"
}

/**
 * Returns the link to navigate to the customer card
 *
 * @remarks
 * This method is intended to take the current path/query string into consideration
 * and return the correct link based on current app state
 *
 * @param consumerId - consumer identifier from the CRW DB
 * @param dealId - deal identifier from the CRW DB
 * @param params - intended to be the params object from the useParams react-router-dom hook
 * @param pathname - intended to be the current document pathname
 * @param search - intended to be the search string from the useLocation react-router-dom hook
 * @param defaultCustomerTab - optional param for setting which customer card tab is loaded by default
 */
const deriveCustomerViewLink = (
  consumerId: string,
  dealId: string,
  currentCustomerId: string,
  pathname: string,
  search: string,
  defaultCustomerTab?: CustomerCardTabTypes
) => {
  let link = "";
  const searchParam = new URLSearchParams(search);

  searchParam.set("dealId", dealId);

  if (defaultCustomerTab) {
    searchParam.set("defaultCustomerTab", defaultCustomerTab);
  } else {
    searchParam.delete("defaultCustomerTab");
  }

  const queryString = "?" + searchParam;

  if (currentCustomerId) {
    link = `${pathname.replace(currentCustomerId, consumerId)}${queryString}`;
  } else if (pathname.endsWith("/")) {
    link = `${pathname}${consumerId}${queryString}`;
  } else {
    link = `${pathname}/${consumerId}${queryString}`;
  }

  return link;
};

export default deriveCustomerViewLink;
