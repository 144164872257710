import { EmployeeRoleType, Participant } from "@coxauto-ui/ccxm/interfaces";
import { CcxmReusableLabelledInsightDisplay } from "@coxauto-ui/ccxm/reusable/labelled-insight-display";
import { BaseChip } from "@interstate/components/Chip/Chip/ChipBase";
import { chipLinkStyle } from "@interstate/components/Chip/Chip/chipLinkStyle";
import { chipLinkDisabledStyle } from "@interstate/components/Chip/Chip/chipDisabledStyle";
/**
 * The Interstate Popover has many issues for our use case:
 * - The popover doesn't display in the correct location
 * - The popover cannot accept an onClick to handle the stop propagation method
 * - The scrolling gets into a bad display state sometimes
 * - The styling is very complex and difficult to override for our needs
 *
 * For now, I have abandoned it entirely in favor of a really lean overlay ported from React-Bootstrap into its own library.
 * It does not come with any styles, so everything is hand rolled. If we want to reuse this component in the future,
 * we should create a new component in the reusable folder
 *
 *  */
import Overlay from "react-overlays/Overlay";
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Placement } from "react-overlays/cjs/usePopper";
import { useInterstateTheme } from "@interstate/components/InterstateThemeProvider";
import styled from "styled-components";
import { XMarkIcon } from "@interstate/components/Icons/XMarkIcon";

/* eslint-disable-next-line */
export interface EmployeesInsightDisplayProps {
  dealId: string;
  employees: Participant[];
  htmlId: string;
  recordRow: number;
}

interface EmployeesInsightDisplayState {
  primarySalesPerson: Participant | null;
  salesManager: Participant | null;
  otherEmployees: Participant[];
}

const StyledOverlayContent = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding: 16px;
  border: 1px solid #b2b2b2;
  box-shadow: 0 0 4px 0 #b2b2b2;
  position: relative;

  .pointer,
  .pointer-helper {
    position: absolute;
    background-color: #fff;
    transform: rotate(45deg) translateX(-50%);
    left: 50%;
  }

  .pointer {
    width: 12px;
    height: 12px;
    bottom: -11px;
    border: 1px solid #b2b2b2;
    box-shadow: 0 0 4px 0 #b2b2b2;
  }

  .pointer-helper {
    width: 14px;
    height: 14px;
    bottom: -10px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    h5 {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      margin: 0;
      padding: 0;
    }

    svg {
      height: 16px;
      width: 16px;
      font-weight: 400;
    }
  }

  .label-value-wrapper {
    margin: 0 8px;

    :not(:last-child) {
      margin-bottom: 8px;
    }

    label {
      margin: 0 4px 0 0;
    }
  }
`;

export function EmployeesInsightDisplay({
  dealId,
  employees,
  htmlId,
  recordRow
}: EmployeesInsightDisplayProps) {
  const overlayRef = useRef(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [{ primarySalesPerson, salesManager, otherEmployees }, setState] =
    useState<EmployeesInsightDisplayState>({
      primarySalesPerson: null,
      salesManager: null,
      otherEmployees: []
    });
  const theme = useInterstateTheme();
  useEffect(() => {
    let _primarySalesPerson = null;
    let _salesManager = null;
    const _otherEmployees = [];
    if (employees.length > 0) {
      for (let i = 0; i < employees.length; i++) {
        if (
          _primarySalesPerson === null &&
          employees[i].employeeRoleType === EmployeeRoleType.primarySalesperson
        ) {
          _primarySalesPerson = employees[i];
        } else if (
          _salesManager === null &&
          employees[i].employeeRoleType === EmployeeRoleType.salesManager
        ) {
          _salesManager = employees[i];
        } else {
          _otherEmployees.push(employees[i]);
        }
      }
    }
    setState({
      primarySalesPerson: _primarySalesPerson,
      salesManager: _salesManager,
      otherEmployees: _otherEmployees
    });
  }, [employees, primarySalesPerson, salesManager]);

  const employeesContent = useMemo(() => {
    return [
      {
        "data-testid": `${htmlId}-${recordRow}-primary-salesperson`,
        "data-userid": primarySalesPerson?.userId,
        id: `${htmlId}-${dealId}-primary-salesperson`,
        value: `Primary Salesperson: ${
          primarySalesPerson ? primarySalesPerson.name : "--"
        }`
      },
      {
        "data-testid": `${htmlId}-${recordRow}-sales-manager`,
        "data-userid": salesManager?.userId,
        id: `${htmlId}-${dealId}-sales-manager`,
        value: `Sales Manager: ${salesManager ? salesManager.name : "--"}`
      }
    ];
  }, [dealId, htmlId, primarySalesPerson, recordRow, salesManager]);

  const toggleOverlay: MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
    setShowOverlay(b => !b);
  };

  const hideOverlay = useCallback(() => {
    setShowOverlay(false);
  }, []);

  const getOverlayContent = useCallback(
    ({
      props,
      arrowProps,
      placement
    }: {
      props: Record<string, any> & {
        ref: (instance: HTMLElement | null) => void;
        style: React.CSSProperties;
        "aria-labelledby"?: string | undefined;
      };
      arrowProps: Record<string, any> & {
        ref: (instance: HTMLElement | null) => void;
        style: React.CSSProperties;
      };
      placement: Placement;
    }) => (
      <StyledOverlayContent
        data-testid={`${htmlId}-${recordRow}-other-employees`}
        {...props}
      >
        <div className="pointer" />
        <div className="pointer-helper" />
        <div className="header">
          <h5 data-testid={`${htmlId}-${recordRow}-other-employees-title`}>
            Other Employees
          </h5>
          <div
            data-testid={`${htmlId}-${recordRow}-other-employees-close-icon`}
            onClick={toggleOverlay}
          >
            <XMarkIcon />
          </div>
        </div>
        {otherEmployees.map(({ employeeRoleDisplayName, name, userId }) => {
          return (
            <div
              className="label-value-wrapper"
              data-testid={`${htmlId}-${recordRow}-other-employees-${userId}`}
              key={userId}
            >
              <label
                data-testid={`${htmlId}-${recordRow}-other-employees-${userId}-label`}
                id={`employees-${userId}-employee-role-display-name`}
              >
                {employeeRoleDisplayName}:
              </label>
              <span
                data-testid={`${htmlId}-${recordRow}-other-employees-${userId}-name`}
                id={`employees-${userId}-name`}
              >
                {name}
              </span>
            </div>
          );
        })}
      </StyledOverlayContent>
    ),
    [htmlId, otherEmployees, recordRow]
  );

  return (
    <CcxmReusableLabelledInsightDisplay
      displayLabel
      appendedContent={
        otherEmployees.length > 0 ? (
          <>
            <BaseChip
              className="LinkChip popover-action"
              data-testid={`${htmlId}-${recordRow}-other-employees-button`}
              id={`${htmlId}-${dealId}-other-employees-button`}
              label={`+ ${otherEmployees.length}`}
              ref={overlayRef}
              sx={{ ...chipLinkStyle(theme), ...chipLinkDisabledStyle(theme) }}
              onClick={toggleOverlay}
            />
            <Overlay
              rootClose
              offset={[0, 10]}
              placement="top"
              show={showOverlay}
              target={overlayRef}
              onHide={hideOverlay}
            >
              {getOverlayContent}
            </Overlay>
          </>
        ) : undefined
      }
      className="employees"
      content={employeesContent}
      data-testid={`${htmlId}-${recordRow}-employees`}
      htmlId={`${htmlId}-${dealId}-card-employees`}
      label="Employees"
    />
  );
}

export default EmployeesInsightDisplay;
