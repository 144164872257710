import { useEffect, useMemo, useState } from "react";

import styled from "styled-components";
import { CcxmReusableColumnHeader } from "@coxauto-ui/ccxm/reusable/column-header";
import { CcxmAmpCard } from "@coxauto-ui/ccxm/amp/card";
import {
  AmpColumnNames,
  FilterSequence,
  FilterSequenceFilterType,
  ListMode,
  LoadingStatus,
  OpportunityInsightsRecordsEntity,
  OptionFilters,
  Paging,
  TextFilters
} from "@coxauto-ui/ccxm/interfaces";
import {
  AmpCardStyles,
  StyledResponsiveGridWrapper
} from "@coxauto-ui/ccxm/reusable/responsive-grid-wrapper";
import getSelectedFilterCategories from "@coxauto-ui/util/get-selected-filter-categories";
import { Alert } from "@interstate/components/Alert/Alert/Alert";

interface CcxmAmpCardsProps {
  filterSequence: FilterSequence[];
  listMode: ListMode;
  loadingStatus: LoadingStatus;
  opportunityRecords: OpportunityInsightsRecordsEntity[];
  optionFilters: OptionFilters;
  paging: Paging;
  textFilters: TextFilters;
  visibleColumns: string[];
}

const StyledCcxmAmpCards = styled.div`
  position: relative;
`;

const StyledAmpColumnHeaders = styled(CcxmReusableColumnHeader)`
  white-space: nowrap;
`;

const StyledStickyAmpColumnHeaders = styled(StyledAmpColumnHeaders)`
  position: sticky;
  right: 0;
  border-left: 1px solid #b2b6bf;

  &.amp-actions-column-header {
    padding-left: 16px;
  }

  &:after {
    box-shadow: inset -24px 0 14px -12px rgb(0 0 0 / 10%);
    bottom: -1px;
    content: "";
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    -webkit-transform: translate(-100%);
    transform: translate(-100%);
    transition: box-shadow 0.3s;
    width: 30px;
  }
`;

const StyledCards = styled(StyledResponsiveGridWrapper)<{
  columnCount: number;
}>`
  display: grid;
  position: relative;
  padding-bottom: 16px;

  &.mobileList,
  &.mobileCustomer,
  &.desktopCustomer {
    grid-template-columns: 50% 50%;
  }

  &.tabletList,
  &.tabletCustomer,
  &.desktopList {
    grid-template-columns: repeat(
      ${({ columnCount }) => columnCount},
      minmax(min-content, 1fr)
    );

    > div:nth-child(n + ${({ columnCount }) => columnCount + 1}):nth-child(
        -n + ${({ columnCount }) => columnCount * 2}
      ) {
      border-top: none;
    }
  }
`;

export function CcxmAmpCards({
  filterSequence,
  listMode,
  loadingStatus,
  opportunityRecords,
  optionFilters,
  paging,
  textFilters,
  visibleColumns
}: CcxmAmpCardsProps) {
  const [styleBuilderArray, setStyleBuilderArray] = useState([]);

  useEffect(() => {
    setStyleBuilderArray(Array.from({ length: paging.pageNumber }));
  }, [paging.pageNumber]);

  const showDealershipCol = useMemo(() => {
    return visibleColumns.includes(AmpColumnNames.Dealership);
  }, [visibleColumns]);

  const columnCount = useMemo(() => {
    return showDealershipCol
      ? Object.keys(AmpColumnNames).length
      : Object.keys(AmpColumnNames).length - 1;
  }, [showDealershipCol]);

  const analyticsFilterList = useMemo(() => {
    return getSelectedFilterCategories({
      filterSequence,
      optionFilters,
      textFilters
    });
  }, [filterSequence, optionFilters, textFilters]);

  const hasSelectedFilters = useMemo(() => {
    let hasSelectedFilter = false;
    for (let i = 0; i < filterSequence.length; i++) {
      if (
        filterSequence[i].filterType === FilterSequenceFilterType.optionFilter
      ) {
        if (
          optionFilters[filterSequence[i].categoryType].selectedValues.length >
          0
        ) {
          hasSelectedFilter = true;
          break;
        }
      } else if (
        filterSequence[i].filterType === FilterSequenceFilterType.textFilter
      ) {
        if (
          textFilters[filterSequence[i].categoryType].textInputValue.length > 0
        ) {
          hasSelectedFilter = true;
          break;
        }
      }
    }
    return hasSelectedFilter;
  }, [filterSequence, optionFilters, textFilters]);

  const alertMessage = useMemo(() => {
    if (hasSelectedFilters) {
      return "Sorry, there are no results available. Please try a different selection.";
    }

    return "Sorry, there are no opportunities at this time. Please try again later.";
  }, [hasSelectedFilters]);

  return (
    <StyledCcxmAmpCards>
      {opportunityRecords.length === 0 &&
        loadingStatus === LoadingStatus.loaded && (
          <Alert
            data-testid="opportunity-list-no-results-alert"
            role={"alert"}
            type={"info"}
          >
            {alertMessage}
          </Alert>
        )}
      <StyledCards className={listMode} columnCount={columnCount}>
        {opportunityRecords.length > 0 &&
          (listMode === "desktopList" || listMode === "tabletList") && (
            <>
              <StyledAmpColumnHeaders
                columnCount={columnCount}
                data-testid={"amp-customer-column-header"}
              >
                Customer
              </StyledAmpColumnHeaders>
              <StyledAmpColumnHeaders
                columnCount={columnCount}
                data-testid={"amp-campaigns-column-header"}
              >
                Campaign
              </StyledAmpColumnHeaders>
              {showDealershipCol && (
                <StyledAmpColumnHeaders
                  columnCount={columnCount}
                  data-testid={"amp-dealership-column-header"}
                >
                  Dealership
                </StyledAmpColumnHeaders>
              )}
              <StyledAmpColumnHeaders
                columnCount={columnCount}
                data-testid={"amp-current-vehicle-column-header"}
              >
                Current Vehicle
              </StyledAmpColumnHeaders>
              <StyledAmpColumnHeaders
                columnCount={columnCount}
                data-testid={"amp-market-level-column-header"}
              >
                Market Level
              </StyledAmpColumnHeaders>
              <StyledAmpColumnHeaders
                columnCount={columnCount}
                data-testid={"amp-offer-payment-diff-column-header"}
              >
                Offer Payment Diff
              </StyledAmpColumnHeaders>
              <StyledAmpColumnHeaders
                columnCount={columnCount}
                data-testid={"amp-equity-column-header"}
              >
                Equity
              </StyledAmpColumnHeaders>
              <StyledAmpColumnHeaders
                columnCount={columnCount}
                data-testid={"amp-finance-type-column-header"}
              >
                Finance Type
              </StyledAmpColumnHeaders>
              <StyledAmpColumnHeaders
                columnCount={columnCount}
                data-testid={"amp-term-remaining-column-header"}
              >
                Term Remaining
              </StyledAmpColumnHeaders>
              <StyledAmpColumnHeaders
                columnCount={columnCount}
                data-testid={"amp-next-service-appt-column-header"}
              >
                Next Service Appt
              </StyledAmpColumnHeaders>
              <StyledStickyAmpColumnHeaders
                className="amp-actions-column-header"
                columnCount={columnCount}
                data-testid={"amp-actions-column-header"}
              >
                Actions
              </StyledStickyAmpColumnHeaders>
            </>
          )}
        {opportunityRecords.map(
          (
            {
              campaign,
              consumerId,
              dealerName,
              equity,
              financeType,
              firstName,
              isCompleted,
              lastName,
              marketLevel,
              nextServiceAppt,
              offerPaymentDiff,
              opportunityId,
              sellingVehicle,
              termRemainingInMonths
            },
            index
          ) => {
            return (
              <CcxmAmpCard
                analyticsFilterList={analyticsFilterList}
                campaign={campaign}
                consumerFirstName={firstName}
                consumerId={consumerId}
                consumerLastName={lastName}
                dealershipName={dealerName}
                equity={equity}
                financeType={financeType}
                isCompleted={isCompleted}
                isLastRow={index + 1 === opportunityRecords.length}
                key={opportunityId}
                listMode={listMode}
                marketLevel={marketLevel}
                nextServiceAppt={nextServiceAppt}
                offerPaymentDiff={offerPaymentDiff}
                opportunityId={opportunityId}
                recordRow={index + 1}
                sellingVehicle={sellingVehicle}
                showConsumerInfo={true}
                showDealershipCol={showDealershipCol}
                termRemainingInMonths={termRemainingInMonths}
              />
            );
          }
        )}
        {styleBuilderArray.map((_, index) => (
          <AmpCardStyles
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            rowCount={paging.pageSize}
            rowStart={(index + 1) * paging.pageSize - paging.pageSize + 1}
            showDealershipCol={showDealershipCol}
          />
        ))}
      </StyledCards>
    </StyledCcxmAmpCards>
  );
}

export default CcxmAmpCards;
