import {
  LoadingIndicator as CXLoadingIndicator,
  LoadingIndicatorProps as CXLoadingIndicatorProps
} from "@coxauto-ui/core-cx";
import { useEffect, useState } from "react";
import { NewRelic } from "@coxauto-ui/core-third-party-newrelic";
export * from "./LoadingIndicatorInterstate";

export interface LoadingIndicatorProps extends CXLoadingIndicatorProps {
  /** Action name that will be logged to NewRelic. Otherwise htmlId is used */
  actionName?: string;
}

export function LoadingIndicator(props: LoadingIndicatorProps) {
  const [startTime] = useState(new Date());
  useEffect(() => {
    return () => {
      const endTime = new Date();
      const duration = endTime.getTime() - startTime.getTime();
      const actionName = getActionName(props.htmlId, props.actionName);
      NewRelic.addPageAction("Loading Spinner Finished", {
        Duration: duration,
        Action: actionName
      });
    };
  }, [props.htmlId, props.actionName, startTime]);
  return <CXLoadingIndicator {...props} />;
}
function getActionName(htmlId: string, actionName: string | undefined): string {
  if (actionName) return actionName;
  if (htmlId.endsWith("loading")) return htmlId;
  return htmlId + "-loading";
}
