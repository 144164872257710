import { verifyNewRelicTrackingLoaded } from "./helper";
import { KVP } from "@coxauto-ui/core-interfaces-third-party-newrelic";

/**
 * Reports a browser PageAction event to New Relic One along with a name and optional attributes.
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/add-page-action/
 */
function addPageAction(name: string, attributes: KVP) {
  if (!verifyNewRelicTrackingLoaded()) {
    return false;
  }
  window.newrelic!.addPageAction(name, attributes);
  return true;
}

/**
 * Adds a unique name and ID to identify releases with multiple JavaScript bundles on the same page.
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/add-release/
 */
function addRelease(releaseName: string, releaseId: string) {
  if (!verifyNewRelicTrackingLoaded()) {
    return false;
  }
  window.newrelic!.addRelease(releaseName, releaseId);
  return true;
}

/**
 * Adds a JavaScript object with a custom name, start time, etc. to an in-progress session trace.
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/addtotrace-browser-agent-api/
 */
function addToTrace(customObject: KVP) {
  if (!verifyNewRelicTrackingLoaded()) {
    return false;
  }
  window.newrelic!.addToTrace(customObject);
  return true;
}

/**
 * Records an additional time point as "finished" in a session trace, and sends the event to New Relic One.
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/finished/
 */
function finished(timeStamp: Date) {
  if (!verifyNewRelicTrackingLoaded()) {
    return false;
  }
  window.newrelic!.finished(timeStamp);
  return true;
}

/**
 * Identifies a browser error without disrupting your app's operations.
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/noticeerror-browser-agent-api/
 */
function noticeError(error: Error, customAttributes?: KVP) {
  if (!verifyNewRelicTrackingLoaded()) {
    return false;
  }
  return window.newrelic!.noticeError(error, customAttributes);
}

/**
 * Adds a user-defined attribute name and value to subsequent events on the page.
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/setcustomattribute-browser-agent-api/
 */
const setCustomAttribute = function (key: string, value: string | number) {
  if (!verifyNewRelicTrackingLoaded()) {
    return false;
  }
  window.newrelic!.setCustomAttribute(key, value);
  return true;
};

/**
 * Allows selective ignoring of known errors that the browser agent captures.
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/set-error-handler/
 */
function setErrorHandler(callback: (err: any) => boolean) {
  if (!verifyNewRelicTrackingLoaded()) {
    return false;
  }
  window.newrelic!.setErrorHandler(callback);
  return true;
}

/**
 * Groups page views to help URL structure or to capture the URL's routing information.
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/setpageviewname-browser-agent-api/
 */
function setPageViewName(name: string, host: string) {
  if (!verifyNewRelicTrackingLoaded()) {
    return false;
  }
  window.newrelic!.setPageViewName(name, host);
  return true;
}

/**
 * Change the values associated with a SPA interaction before the interaction is saved .
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/spa-on-end/
 */
function onEnd(callback: () => void) {
  if (!verifyNewRelicTrackingLoaded()) {
    return false;
  }
  return window.newrelic!.onEnd(callback);
}

/**
 * Gives SPA routes more accurate names than default names. Monitors specific routes rather than by default grouping.
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/setcurrentroutename-browser-spa-api/
 */
function setCurrentRouteName(name: string) {
  if (!verifyNewRelicTrackingLoaded()) {
    return false;
  }
  window.newrelic!.setCurrentRouteName(name);
  return true;
}

export const standard = {
  addPageAction,
  addRelease,
  addToTrace,
  finished,
  noticeError,
  onEnd,
  setCurrentRouteName,
  setCustomAttribute,
  setErrorHandler,
  setPageViewName
};
