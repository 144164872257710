import {
  ConversationItem,
  ConversationTypeEnum
} from "@coxauto-ui/communications-interfaces";

export const getRecentEmailSubject = (
  conversationItems: ConversationItem[]
): string => {
  // Sort conversation items by timestamp in descending order (most recent first)
  const sortedItems = [...conversationItems].sort((a, b) => {
    const aTimestamp = new Date(a.date);
    const bTimestamp = new Date(b.date);

    return bTimestamp.getTime() - aTimestamp.getTime();
  });

  // Find the first item with a non-empty subject
  const mostRecentItemWithSubject = sortedItems.find(
    item => item.subject !== "" && item.type === ConversationTypeEnum.EMAIL
  );

  // Return the subject of the found item, or an empty string if not found
  return mostRecentItemWithSubject?.subject || "";
};
