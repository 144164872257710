import { loadRemoteModule } from "@coxauto-ui/core/module-federation";
import { InterstateOnChangeEvent } from "@interstate/components/InterstateEvents";
import {
  TextInput,
  TextInputEventValue
} from "@interstate/components/TextInput";
import React, { Suspense, useState } from "react";

const UnifiedInbox = React.lazy(() =>
  loadRemoteModule("communications", "./UnifiedInbox")
);

export default function UnifiedInboxPage() {
  const [consumerId, setConsumerId] = useState("");
  const [jwt, setJwt] = useState("");

  const onConsumerTextInputChange = (
    e: InterstateOnChangeEvent<TextInputEventValue>
  ) => {
    setConsumerId(e.target.value?.toString() || "");
  };

  const onJwtTextInputChange = (
    e: InterstateOnChangeEvent<TextInputEventValue>
  ) => {
    setJwt(e.target.value?.toString() || "");
  };

  return (
    <div>
      <TextInput
        label={"Consumer Id"}
        name={"consumer-id"}
        onChange={onConsumerTextInputChange}
      />
      <TextInput label={"JWT"} name={"jwt"} onChange={onJwtTextInputChange} />
      {consumerId && jwt ? (
        <Suspense fallback={<div>Loading test page...</div>}>
          <UnifiedInbox
            consumerId={consumerId}
            featureFlags={{
              DisableReply: false
            }}
            idPConfig={{
              getJwtOverride: () => Promise.resolve(jwt),
              supportedIdP: "IDS",
              errorCallback: (message: string) => {
                // do nothing!!
              }
            }}
            systemOfRecordConfig={{
              customerSystem: "VIN"
            }}
            title={"IT LIVES!"}
          />
        </Suspense>
      ) : (
        <div />
      )}
    </div>
  );
}
