import {
  ConversationTypeEnum,
  EventResultEnum
} from "@coxauto-ui/communications-interfaces";
import {
  ChatBubbleLeftIcon,
  EnvelopeIcon,
  InformationCircleIcon,
  PhoneIcon
} from "@interstate/components/Icons";
import {
  conversationIconChatTestId,
  conversationIconEmailTestId,
  conversationIconPhoneTestId,
  conversationIconTestId,
  conversationIconTextTestId,
  conversationTitleTestId
} from "../testIds";
import { useCallback, useMemo } from "react";

export interface ChannelTypeIconProps {
  conversationType: ConversationTypeEnum;
  eventResult: EventResultEnum;
}

export function ChannelTypeIcon({
  conversationType,
  eventResult
}: ChannelTypeIconProps) {
  const getIcon = useCallback(() => {
    if (conversationType === ConversationTypeEnum.EMAIL)
      return (
        <span data-testid={conversationTitleTestId} title={eventResult}>
          <EnvelopeIcon data-testid={conversationIconEmailTestId} />
        </span>
      );
    else if (conversationType === ConversationTypeEnum.PHONE)
      return (
        <span data-test-id={conversationTitleTestId} title={eventResult}>
          <PhoneIcon data-testid={conversationIconPhoneTestId} />
        </span>
      );
    else if (conversationType === ConversationTypeEnum.TEXT)
      return (
        <span data-testid={conversationTitleTestId} title={eventResult}>
          <ChatBubbleLeftIcon data-testid={conversationIconTextTestId} />
        </span>
      );
    else if (conversationType === ConversationTypeEnum.CHAT)
      return <ChatBubbleLeftIcon data-testid={conversationIconChatTestId} />;
    else return <InformationCircleIcon />;
  }, [conversationType, eventResult]);

  const className = useMemo(() => {
    if (conversationType === ConversationTypeEnum.CHAT) {
      return "green";
    }
    switch (eventResult) {
      case EventResultEnum.INPROGRESS:
        return "gray";
      case EventResultEnum.SUCCESS:
        return "green";
      case EventResultEnum.FAILED:
        return "red";
      case EventResultEnum.SPOKE:
        return "green";
      case EventResultEnum.NOANSWER:
        return "red";
      case EventResultEnum.LEFTMESSAGE:
        return "orange";

      default:
        return "blue";
    }
  }, [conversationType, eventResult]);

  return (
    <span
      className={`conversation-icon ${className}`}
      data-testid={conversationIconTestId}
    >
      {getIcon()}
    </span>
  );
}
