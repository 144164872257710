import styled from "styled-components";
import { useEffect, useMemo, useState } from "react";
import {
  DealInspectorResponse,
  RawDealEvent
} from "@coxauto-ui/tenant/interfaces";
import { LoadingIndicator } from "@interstate/components/LoadingIndicator";
import {
  SearchInput,
  SearchInputChangeEventValue
} from "@interstate/components/SearchInput";
import { Button } from "@interstate/components/Button";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { getDealInspector } from "@coxauto-ui/tenant/api";
import { InterstateOnChangeCallback } from "@interstate/components/InterstateEvents";
import { deriveEnvironmentName } from "@coxauto-ui/ccxm-util-derive-environment";
import TenantDealInspectorViewer from "./tenant-deal-inspector-viewer";

const StyledDelSearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  && > div:first-child {
    flex-basis: 30%;
    flex-grow: 0;
  }
  .&& > div:last-child {
    flex-grow: 1;
  }
`;
const StyledButtonLogs = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledContainer = styled.div`
  padding: 20px;
`;
const StyledNotResults = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;

const splunkBaseUrl =
  "https://coxauto.splunkcloud.com/en-US/app/CAI_Roundabout/search?q=search";
const splunkQueriesKeys = {
  LOGS: "logs",
  COMMON_CONSUMER_ERROR: "commonConsumerError",
  INGESTION_FAILURE_BY_DEAL_ID: "ingestionFailureByDealId"
} as const;
const { LOGS, COMMON_CONSUMER_ERROR, INGESTION_FAILURE_BY_DEAL_ID } =
  splunkQueriesKeys;
const splunkQueries: Record<
  typeof splunkQueriesKeys[keyof typeof splunkQueriesKeys],
  string
> = {
  [LOGS]:
    'index=vin_app cai_app="cxm-insights-ingestion-api" cai_environment="ENV"| spath "Properties.DealId"',
  [COMMON_CONSUMER_ERROR]:
    'index=vin_app cai_app="cxm-insights-ingestion-api" cai_environment="ENV" | spath "Properties.DealId" | spath MessageTemplate | search MessageTemplate="Invalid request to common consumer - {@errors}"',
  [INGESTION_FAILURE_BY_DEAL_ID]:
    'index=vin_app cai_app="cxm-insights-ingestion-api" cai_environment="ENV"| spath "Properties.DealId" | search "Properties.DealId"=xxxx'
};

export function TenantDealInspector() {
  const [dealInspectorData, setDealInspectorData] =
    useState<DealInspectorResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [dealId, setDealId] = useState("");
  const environment = deriveEnvironmentName();

  const openLink = (
    queryKey: typeof splunkQueriesKeys[keyof typeof splunkQueriesKeys],
    dealId?: string
  ) => {
    const query = splunkQueries[queryKey];
    const url = `${splunkBaseUrl} ${query
      .replace("xxxx", dealId || "")
      .replace("ENV", environment)}`;
    const encodedUrl = encodeURI(url);
    window.open(encodedUrl, "_blank");
  };

  const NotResults = (
    <StyledNotResults>
      <h3>No results found</h3>
    </StyledNotResults>
  );

  useEffect(() => {
    document.title = "Deal Inspector";
  }, []);

  const hasInfo = useMemo(
    () =>
      dealInspectorData?.deal?.dealId ||
      dealInspectorData?.rawDealEvents?.length,
    [dealInspectorData]
  );

  const onClickSearchDealId = async () => {
    setIsLoading(true);
    setHasSearched(true);
    try {
      const data = await getDealInspector(dealId);
      setDealInspectorData(data);
    } catch (error) {
      setDealInspectorData(null);
    } finally {
      setIsLoading(false);
    }
  };

  const onInputChange: InterstateOnChangeCallback<
    SearchInputChangeEventValue
  > = event => {
    const value = event.target.value || "";
    setDealId(String(value).trim());
  };

  const ingestedDeal = () => {
    return (
      <TenantDealInspectorViewer
        data={dealInspectorData?.deal}
        title="Ingested deal"
      />
    );
  };

  const changelog = () => {
    return (
      <TenantDealInspectorViewer
        data={dealInspectorData?.deal?.changeLog || {}}
        title="ChangeLog"
      />
    );
  };

  const rawDealEvents = (rawDeal: RawDealEvent, index: number) => {
    const deal = JSON.parse(rawDeal.messageBody);
    return (
      <TenantDealInspectorViewer
        data={deal}
        title={`DealXG payload ${index + 1}`}
      />
    );
  };

  return (
    <StyledContainer>
      <StyledDelSearchContainer>
        <SearchInput
          data-testid="textinput-search-deal"
          disableSearch={!dealId}
          id="textinput-search-deal"
          inputValue={dealId}
          name="textinput-search-deal"
          placeholder="Search by Deal id"
          size="small"
          style="primary"
          onInputChange={onInputChange}
          onSearchClick={onClickSearchDealId}
        />
        <StyledButtonLogs>
          <Button size="small" onClick={() => openLink(LOGS)}>
            Splunk Logs
          </Button>
          <Button size="small" onClick={() => openLink(COMMON_CONSUMER_ERROR)}>
            Search by Error Type
          </Button>
          {hasInfo && (
            <Button
              size="small"
              onClick={() =>
                openLink(
                  INGESTION_FAILURE_BY_DEAL_ID,
                  dealInspectorData?.deal?.dealId
                )
              }
            >
              Ingestion Failure by DealID
            </Button>
          )}
        </StyledButtonLogs>
      </StyledDelSearchContainer>
      {!isLoading && !hasInfo && hasSearched && NotResults}
      {isLoading && <LoadingIndicator />}
      {!isLoading && hasSearched && dealInspectorData && (
        <>
          {ingestedDeal()}
          {changelog()}
          {dealInspectorData?.rawDealEvents?.map((rawDeal, index) => (
            <div key={rawDeal.eventAuditId}>
              {rawDealEvents(rawDeal, index)}
            </div>
          ))}
        </>
      )}
    </StyledContainer>
  );
}

export default TenantDealInspector;
