import { useContext } from "react";
import { ConversationDetailsContext } from "../context";
import { TextArea } from "@interstate/components/TextArea";
import { replyInputTestId } from "../testIds";
import { ConversationTypeEnum } from "@coxauto-ui/communications-interfaces";
import { TextInputEventValue } from "@interstate/components/TextInput";
import { InterstateOnChangeEvent } from "@interstate/components/InterstateEvents";

export interface MessageInputProps {
  isDisabled: boolean;
}

export function MessageInput({ isDisabled }: MessageInputProps) {
  const { replyText, currentConversationType, setReplyText } = useContext(
    ConversationDetailsContext
  );

  const onInputChange = (
    iEvent: InterstateOnChangeEvent<TextInputEventValue>
  ) => {
    const changeVal = iEvent.target.value?.toString();
    if (changeVal === undefined) return;
    setReplyText(changeVal);
  };

  const messageText = () => {
    if (currentConversationType === ConversationTypeEnum.PHONE)
      return "Call Notes";
    else return "Message";
  };

  const placeholderText = () => {
    if (currentConversationType === ConversationTypeEnum.PHONE)
      // Disclaimer message for calls logged
      return "CAI is not responsible for calls placed to customers on do not call lists";
    else return "";
  };

  return (
    <TextArea
      required
      autoComplete="off"
      data-testid={replyInputTestId}
      disabled={isDisabled}
      htmlId="conversation-message-input"
      label={messageText()}
      maxRows={11}
      minRows={4}
      name="ConversationMessageInput"
      placeholder={placeholderText()}
      value={replyText}
      onChange={onInputChange}
    />
  );
}

export default MessageInput;
