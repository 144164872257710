import {
  BaseColors,
  ConversationItem
} from "@coxauto-ui/communications-interfaces";
import styled from "styled-components";
import { ChannelTypeIcon } from "../core/channel-type-icon";
import { messageItemSenderAgeId, messageItemSenderNameId } from "../testIds";
import { get12hrsTime } from "@coxauto-ui/communications-util";
import { useContext } from "react";
import { ConversationContext } from "../context";

export const StyledConversationItemType = styled.span`
  vertical-align: middle;
  padding: 0.25em;
`;
export const StyledConversationItemName = styled.span`
  font-weight: 500;
  padding: 0.25em;
  padding-left: 0em;
`;
export const StyledConversationItemAge = styled.span<{
  baseColors: BaseColors;
}>`
  padding: 0.25em;
  padding-left: 0em;
  color: ${props => props.baseColors.gray700};
  font-size: 0.8em;
`;

export interface ConversationListItemProps {
  conversationItem: ConversationItem;
}

export function MessageItemHeader(props: ConversationListItemProps) {
  const _conversationItem = props.conversationItem;
  const { baseColors } = useContext(ConversationContext);

  return (
    <div>
      <StyledConversationItemType>
        <ChannelTypeIcon
          conversationType={_conversationItem.type}
          eventResult={_conversationItem.eventResult}
        />
      </StyledConversationItemType>
      <StyledConversationItemName
        data-testid={messageItemSenderNameId + _conversationItem.key}
        id={messageItemSenderNameId + _conversationItem.key}
      >
        {_conversationItem.senderName} -
      </StyledConversationItemName>
      <StyledConversationItemAge
        baseColors={baseColors}
        data-testid={messageItemSenderAgeId + _conversationItem.key}
        id={messageItemSenderAgeId + _conversationItem.key}
      >
        {get12hrsTime(new Date(_conversationItem.date))}
      </StyledConversationItemAge>
    </div>
  );
}

export default MessageItemHeader;
