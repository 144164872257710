export function get12hrsTime(date: Date) {
  const formatedTime = date
    .toLocaleString([], {
      hour: "numeric",
      minute: "2-digit"
    })
    .toLowerCase();

  return formatedTime;
}
