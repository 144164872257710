import { fetchWrapper } from "@coxauto-ui/core-http";
import { getBaseUrl, getHeader } from "../config";
import { ResponseError } from "./interfaces/api-error";
import {
  GetCommunicationsContentResponse,
  GetCommunicationsDetailRequest
} from "./interfaces/get-communications-details";
import { Environment } from "@coxauto-ui/communications-interfaces";

export async function getCommunicationsDetails(
  data: GetCommunicationsDetailRequest,
  env: Environment
): Promise<string | ResponseError> {
  const url = `${getBaseUrl(env)}CxM/message-details/${
    data.messageDetailId
  }?consumer=${data.ccID}&ConsumerRecordSystem=${data.consumerRecordSystem}`;

  const header = await getHeader();

  const response =
    await fetchWrapper.getAsync<GetCommunicationsContentResponse>(url, header);
  if (response && response.data && !response.hasError) {
    return response.data.content ?? "";
  } else {
    const error: ResponseError = {
      isError: true,
      statusCode: response.statusCode,
      errorResult: response.error
    };
    return error;
  }
}
