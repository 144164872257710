import { useLocation } from "react-router-dom";

export function useRoutingLocation() {
  // const context = useRoutingContext();
  const { pathname, search } = useLocation();

  // pre-built wrapper for future potential module vs. single page app use cases
  // to follow the same pattern

  return { pathname, search };
}
