import format from "date-fns/format";

const formattedNumber = new Intl.NumberFormat(`en-US`, {
  currency: `USD`,
  style: "currency"
});

export const formatCurrency = (amount: number | null) => {
  if (amount) {
    const formattedValue = formattedNumber.format(amount).slice(0, -3);
    if (amount >= 0) {
      return formattedValue;
    } else {
      return `(${formattedValue.replace("-", "")})`;
    }
  } else {
    return "--";
  }
};

export const formatDate = (
  dateString: string | null,
  formatType = "M/d/yy"
) => {
  if (dateString && formatType) {
    const parsed = new Date(dateString.replace(/-/g, "/").replace(/T.+/, ""));
    return format(parsed, formatType);
  } else {
    return dateString;
  }
};
