import { getUserProfileState } from "@coxauto-ui/ccxm/store";
import { useCxmFlags } from "@coxauto-ui/core/third-party/launch-darkly";
import { shallowEqual, useSelector } from "react-redux";
import styled from "styled-components";

/* eslint-disable-next-line */
export interface FeatureEnablementProps {}

const StyledFeatureEnablement = styled.div`
  background: #fff;
  padding: 16px;
`;

export function FeatureEnablement(props: FeatureEnablementProps) {
  const { bridgeUserName, viewAsUsername } = useSelector(
    getUserProfileState,
    shallowEqual
  );
  const flags = useCxmFlags();

  return (
    <StyledFeatureEnablement>
      <h1
        data-bridgeusername={bridgeUserName}
        data-testid="feature-enablement-header"
      >{`Feature Enablement for ${bridgeUserName}`}</h1>
      {viewAsUsername?.length > 0 && (
        <span
          data-testid="feature-enablement-viewing-as-info"
          data-viewasusername={viewAsUsername}
        >{`viewing as ${viewAsUsername}`}</span>
      )}
      <h4 data-testid="feature-enablement-title">
        This user is enabled for...
      </h4>
      <ul>
        {flags["nx.cxm.edit-dealer-settings"] === true && (
          <li data-testid="feature-enablement-can-edit-dealer-settings">
            <b>Can edit dealer settings</b>{" "}
            <span>(nx.cxm.edit-dealer-settings)</span>
          </li>
        )}
        {flags["nx.cxm.show-user-management"] === true && (
          <li data-testid="feature-enablement-can-view-user-management">
            <b>Can view user management</b>{" "}
            <span>(nx.cxm.show-user-management)</span>
          </li>
        )}
        {flags["nx.cxm.show-vin-employee-management"] === true && (
          <li data-testid="feature-enablement-can-view-vin-employee-management">
            <b>Can view vin employee management</b>{" "}
            <span>(nx.cxm.show-vin-employee-management)</span>
          </li>
        )}
        {flags["nx.cxm.test"] === true && (
          <li data-testid="feature-enablement-test">
            <b>Can access features behind the test flag</b>{" "}
            <span>(nx.cxm.test)</span>
          </li>
        )}
      </ul>
    </StyledFeatureEnablement>
  );
}

export default FeatureEnablement;
