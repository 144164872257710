import React, { useEffect } from "react";
import authClient from "@coxauto-ui/core/auth/get-auth-client-tenant-admin";
import RealTimeInfo from "./tenant-admin-realtime-info";
import { deriveEnvironmentName } from "@coxauto-ui/ccxm-util-derive-environment";
import { SignalRProvider } from "@coxauto-ui/core/real-time-signalr";

const baseUrls = {
  nonprod: "https://cxm-realtime-api.np-pipelines.app.coxautoinc.com/",
  prod: "https://cxm-realtime-api.pipelines.app.coxautoinc.com/"
};

const url = baseUrls[deriveEnvironmentName()];

export const TenantAdminRealTimeInfo = () => {
  useEffect(() => {
    document.title = "Real Time Info";
  }, []);
  return (
    <SignalRProvider
      accessTokenFactory={() => authClient.getAuth()}
      url={`${url}Hubs/Deals`}
    >
      <RealTimeInfo />
    </SignalRProvider>
  );
};
