import React from "react";
import { Navigate } from "react-router-dom";
import { CcxmCloseMyDealsList } from "@coxauto-ui/ccxm/close-my-deals/list";
import { CcxmAmpList } from "@coxauto-ui/ccxm/amp/list";
import { AdminModule } from "@coxauto-ui/tenant/admin";
import { ErrorTest } from "../../pages/error-test";
import CustomerCardPage from "../../pages/customer-card-page/customer-card-page";
import CommonConversationPage from "../../pages/customer-conversation-page/customer-conversation-page";
import FeatureEnablement from "../../pages/feature-enablement/feature-enablement";
import { CustomerSearchPage } from "../../pages/customer-search-page/customer-search-page";
import { BusinessTestPage } from "../../pages/business-page/business-add-page";
import TestDealListModule from "../../pages/test-module/test-deal-list-module";
import authClient from "@coxauto-ui/core/auth/get-auth-client-crw";
import { DealerUserAccess, USER_HOME_PAGE } from "@coxauto-ui/ccxm-routing";
import UnifiedInboxPage from "../../pages/unified-inbox-page/unified-inbox-page";

export type RouteDefinition = {
  element: React.ReactNode;
  path: string;
  title?: string;
};

// Choosing to test this method in route Utils
export const getRouteDefinitions = (): RouteDefinition[] => {
  const routeList = [
    {
      element: (
        <DealerUserAccess>
          <CcxmCloseMyDealsList />
        </DealerUserAccess>
      ),
      paths: [
        "/list/deals",
        "/list/deals/:customerId",
        "/list/deals/:customerId/:location"
      ],
      title: "Deal List"
    },
    {
      element: (
        <DealerUserAccess>
          <CcxmAmpList />
        </DealerUserAccess>
      ),
      paths: [
        "/list/opportunities",
        "/list/opportunities/:customerId",
        "/list/opportunities/:customerId/:location"
      ],
      title: "Opportunity List"
    },
    {
      element: <AdminModule getAuthOverride={authClient.getAuth} />,
      // The tenant admin has it own sub-routes definitions.
      // you can see in the get-admin-route-definitions.tsx file
      paths: ["/admin/*"],
      title: ""
    },
    {
      element: <CustomerCardPage />,
      paths: ["/customer-card"],
      title: "Customer Card"
    },
    {
      element: <UnifiedInboxPage />,
      paths: ["/unified-inbox"],
      title: "Unified Inbox"
    },
    {
      element: <CustomerSearchPage />,
      paths: ["/customer-card/search"],
      title: "Customer Search"
    },
    {
      element: <BusinessTestPage />,
      paths: ["/business"],
      title: "Business Test Harness"
    },
    {
      element: <CommonConversationPage />,
      paths: ["/customer-conversation"],
      title: "Customer Inbox"
    },
    {
      element: <ErrorTest />,
      paths: ["/error-test"],
      title: "Error Boundary Test"
    },
    {
      element: <FeatureEnablement />,
      paths: ["/feature-enablement"],
      title: "Feature Enablement"
    },
    {
      element: <TestDealListModule />,
      paths: ["/test-deal-list-module"], // default path patterns must be listed last in route definitions
      title: "Test Deal List Module"
    },
    {
      element: (
        <DealerUserAccess>
          <Navigate to={USER_HOME_PAGE} />
        </DealerUserAccess>
      ),
      paths: ["/", "/list", "*"], // default path patterns must be listed last in route definitions
      title: "Home"
    }
  ];

  return routeList.flatMap(({ element, paths, title }) =>
    paths.map(path => ({
      element,
      path,
      title
    }))
  );
};
