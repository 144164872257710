import { environments } from "@coxauto-ui/ccxm/util/derive-environment";
import { tagManagerArgs } from "@coxauto-ui/core-interfaces-third-party-gtm";

const getInitialGtmConfig = (env: environments): tagManagerArgs => {
  return {
    dataLayer: {
      dataLayerReady: false,
      eventProperties: {
        listContext: "none",
        commonCustomerId: "",
        errorMessage: undefined,
        actionContext: "none",
        listPlacement: 0
      },
      common: {
        dataLayerVersion: 2,
        application: {
          name: "CxM",
          version: "1.0",
          environment: env,
          isProduction: env === "prod"
        }
      }
    },
    dataLayerName: "dataLayer",
    gtmId: "GTM-MKS8WZ3"
  };
};

export default getInitialGtmConfig;
