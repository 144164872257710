enum AmpColumnNames {
  Customer = "Customer",
  Campaign = "Campaign",
  Dealership = "Dealership",
  CurrentVehicle = "CurrentVehicle",
  MarketLevel = "MarketLevel",
  OfferPaymentDiff = "OfferPaymentDiff",
  Equity = "Equity",
  FinanceType = "FinanceType",
  TermRemaining = "TermRemaining",
  NextServiceAppt = "NextServiceAppt",
  Actions = "Actions"
}

export default AmpColumnNames;
