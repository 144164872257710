import {
  FilterSequence,
  FilterSequenceFilterType,
  OptionFilters,
  TextFilters
} from "@coxauto-ui/ccxm/interfaces";

const getSelectedFilterCategories = ({
  filterSequence,
  textFilters,
  optionFilters
}: {
  filterSequence: FilterSequence[];
  textFilters: TextFilters;
  optionFilters: OptionFilters;
}) => {
  const selectedCategories: string[] = [];
  filterSequence.forEach(filter => {
    if (filter.filterType === FilterSequenceFilterType.textFilter) {
      if (textFilters[filter.categoryType]?.textInputValue.length > 0) {
        selectedCategories.push(filter.categoryType);
      }
    } else if (filter.filterType === FilterSequenceFilterType.optionFilter) {
      if (optionFilters[filter.categoryType]?.selectedValues.length > 0) {
        selectedCategories.push(filter.categoryType);
      }
    }
  });

  return selectedCategories.join(", ");
};

export default getSelectedFilterCategories;
