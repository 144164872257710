import { differenceInCalendarDays, formatDistance } from "date-fns";

export function getDisplayableDate(comparisonDate: Date) {
  let formattedDate;
  const dayCount = differenceInCalendarDays(new Date(), comparisonDate);
  if (dayCount <= 28) {
    formattedDate = formatDistance(comparisonDate, new Date(), {
      addSuffix: true,
      includeSeconds: true
    });
  } else {
    formattedDate = comparisonDate.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric"
    });
  }

  return formattedDate;
}
