import { getUserProfileState } from "@coxauto-ui/ccxm/store";

import { TagManager } from "@coxauto-ui/core-third-party-ga4";
import {
  FallbackProps,
  JustifyLeftFlex,
  StackedFlex,
  StyledErrorMessage,
  StyledFailureState,
  StyledFailureStateContent,
  StyledHeader,
  StyledHeaderText,
  StyledIconExclamation,
  StyledShowErrorBtn,
  StyledSubHeader,
  getErrorMessage,
  getStackTraceMessage
} from "@coxauto-ui/core/errors";
import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { trackInNewRelic } from "@coxauto-ui/core-third-party-newrelic";

const onRefreshClick = () => {
  window.location.reload();
};

export function FailureState({ error }: FallbackProps) {
  const [showError, setShowError] = useState(false);
  const { dealerGroupId } = useSelector(getUserProfileState, shallowEqual);

  useEffect(() => {
    TagManager.event({
      event: "productEvent",
      eventName: "Error Displayed",
      eventProperties: {
        listContext: undefined,
        commonCustomerId: undefined,
        errorMessage: getErrorMessage(error),
        actionContext: undefined,
        listPlacement: undefined
      }
    });

    trackInNewRelic({
      componentAction: "PageLoad",
      locationLoadedFrom: document.title,
      dealerId: dealerGroupId,
      destinationUrl: window.location.href,
      errorContext: (error as Error).stack?.toString()
    });
  }, [dealerGroupId, error]);

  return (
    <StyledFailureState>
      <StyledFailureStateContent>
        <StyledHeader>
          <StyledIconExclamation data-testid="failure-state-left-warning-icon" />
          <StyledHeaderText data-testid="failure-state-title">
            Application Error
          </StyledHeaderText>
        </StyledHeader>
        <StyledSubHeader data-testid="failure-state-main-text">
          Sorry, something went wrong. Please try again. If this error
          continues, please connect with your support team. <br />
        </StyledSubHeader>
        <StackedFlex>
          <JustifyLeftFlex>
            <StyledShowErrorBtn
              data-testid="failure-state-error-details-button"
              htmlId="show-error"
              onClick={() => setShowError(true)}
            >
              Error Details
            </StyledShowErrorBtn>
            <StyledShowErrorBtn
              data-testid="failure-state-refresh-button"
              htmlId="return"
              onClick={onRefreshClick}
            >
              Refresh
            </StyledShowErrorBtn>
          </JustifyLeftFlex>
          <br />
          {showError && (
            <StyledErrorMessage data-testid="failure-stack-trace">
              {getStackTraceMessage(error)}
            </StyledErrorMessage>
          )}
        </StackedFlex>
      </StyledFailureStateContent>
    </StyledFailureState>
  );
}

export default FailureState;
