import styled from "styled-components";
import { Button } from "@interstate/components/Button";
import { ClipboardIcon } from "@interstate/components/Icons/ClipboardIcon";
import { CodeBracketIcon } from "@interstate/components/Icons/CodeBracketIcon";

const StyledDelSearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  && > div:first-child {
    flex-basis: 30%;
    flex-grow: 0;
  }
  .&& > div:last-child {
    flex-grow: 1;
  }
`;
const StyledButtonLogs = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledDealInspector = styled.div`
  margin-top: 20px;
`;

const StyledJsonViewer = styled.pre`
  margin-top: 20px;
  max-height: 500px;
`;

interface TenantDealInspectorProps {
  title: string;
  data?: object;
  consoleLogTitle?: string;
}

export function TenantDealInspectorViewer({
  data = {},
  title,
  consoleLogTitle
}: TenantDealInspectorProps) {
  const stringData = JSON.stringify(data, null, 2);
  const isDataEmpty = !data || Object.keys(data).length === 0;
  const handleCopy = () => {
    navigator.clipboard.writeText(stringData);
  };
  const handleConsole = () => {
    console.log(consoleLogTitle || title, data);
  };
  return (
    <StyledDealInspector>
      <StyledDelSearchContainer>
        <h3 data-testid="deal-inspector-title">{title}</h3>
        {!isDataEmpty && (
          <StyledButtonLogs>
            <Button
              data-testid="deal-inspector-copy"
              endIcon={<ClipboardIcon />}
              size="small"
              onClick={handleCopy}
            >
              Copy
            </Button>
            <Button
              data-testid="deal-inspector-console"
              endIcon={<CodeBracketIcon />}
              size="small"
              onClick={handleConsole}
            >
              Console
            </Button>
          </StyledButtonLogs>
        )}
      </StyledDelSearchContainer>
      <StyledJsonViewer data-testid="deal-inspector-json">
        {isDataEmpty ? "" : stringData}
      </StyledJsonViewer>
    </StyledDealInspector>
  );
}

export default TenantDealInspectorViewer;
