import { useParams } from "react-router-dom";
import { useRoutingContext } from "./context";

export type RoutingParams = {
  customerId?: string;
};

export function useRoutingParams() {
  const context = useRoutingContext();
  const params = useParams<RoutingParams>();

  const customerId = params.customerId || context.selectedCustomerId || null;

  if (
    context.isModule === false &&
    params.customerId !== context.selectedCustomerId
  ) {
    context.setSelectedCustomerId(params.customerId || null);
  }

  return { customerId };
}
