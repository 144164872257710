import {
  ConversationItem,
  ConversationTypeEnum,
  EventResultEnum
} from "@coxauto-ui/communications-interfaces";
import { useContext, useMemo } from "react";
import { ConversationContext, ConversationDetailsContext } from "../context";
import {
  messageItemInboundAttachmentBubbleId,
  messageItemInboundBubbleId,
  messageItemInboundId,
  messageItemOutboundAttachmentBubbleId,
  messageItemOutboundBubbleId,
  messageItemOutboundId
} from "../testIds";
import MessageItemContent from "./item-content";
import MessageItemHeader from "./item-header";
import MessageItemBottom from "./item-bottom";
import ItemAttachment from "./item-attachment";

export interface MessageItemProps {
  conversationItem: ConversationItem;
}

export function MessageItem({ conversationItem }: MessageItemProps) {
  const { replyOptions } = useContext(ConversationContext);
  const { setSelectedConversationItem, setSelectedReplyOption } = useContext(
    ConversationDetailsContext
  );

  const changeSelectedReplyOption = () => {
    const newSelectedReplyOption = replyOptions.find(
      (option: { type: string }) => option.type === conversationItem.type
    );
    if (newSelectedReplyOption) setSelectedReplyOption(newSelectedReplyOption);
    setSelectedConversationItem(conversationItem);
  };

  const messageItemBubbleClassName = useMemo(() => {
    let className = "message-item-bubble";
    if (conversationItem.type === ConversationTypeEnum.EMAIL) {
      className += " full";
    }

    if (conversationItem.direction === "inbound") {
      className += " message-item-inbound-bubble";
      return className;
    }

    if (conversationItem.eventResult === EventResultEnum.FAILED) {
      className += " message-item-outbound-bubble-error";
    } else {
      className += " message-item-outbound-bubble";
    }

    return className;
  }, [conversationItem]);

  return (
    <div className="item">
      {conversationItem.direction.toString().toLowerCase() === "inbound" ? (
        <div
          className="message-item message-item-inbound"
          data-testid={messageItemInboundId + conversationItem.key}
        >
          <MessageItemHeader conversationItem={conversationItem} />
          {conversationItem.content.text.length > 0 && (
            <div
              className={`${messageItemBubbleClassName}`}
              data-testid={messageItemInboundBubbleId + conversationItem.key}
              onClick={changeSelectedReplyOption}
            >
              <MessageItemContent
                content={conversationItem.content}
                contentId={conversationItem.key}
                conversationDirection={conversationItem.direction}
                eventResult={conversationItem.eventResult}
                numberOfAttachments={conversationItem.attachments.length}
                recipientContactInfo={conversationItem.recipientContactInfo}
                type={conversationItem.type}
              />
            </div>
          )}
          {conversationItem.attachments
            .filter(_ => conversationItem.type !== ConversationTypeEnum.EMAIL)
            .map(p => (
              <div
                data-testid={
                  messageItemInboundAttachmentBubbleId + p.attachmentId
                }
                key={messageItemInboundAttachmentBubbleId + p.attachmentId}
              >
                <ItemAttachment
                  attachmentBubbleClassName={`${messageItemBubbleClassName} message-item-attachment-inbound-bubble`}
                  attachmentId={p.attachmentId}
                  attachmentName={p.fileName}
                  attachmentType={p.type}
                  attachmentUrl={p.url}
                  attachmentViewClassName="message-item-attachment-inbound-view"
                  coxAutoMessageId={conversationItem.key}
                  date={conversationItem.date}
                />
              </div>
            ))}
          <MessageItemBottom conversationItem={conversationItem} />
        </div>
      ) : (
        <div
          className="message-item message-item-outbound"
          data-testid={messageItemOutboundId + conversationItem.key}
        >
          <MessageItemHeader conversationItem={conversationItem} />
          {conversationItem.content.text.length > 0 && (
            <div
              className={`${messageItemBubbleClassName}`}
              data-testid={messageItemOutboundBubbleId + conversationItem.key}
              onClick={changeSelectedReplyOption}
            >
              <MessageItemContent
                content={conversationItem.content}
                contentId={conversationItem.key}
                conversationDirection={conversationItem.direction}
                eventResult={conversationItem.eventResult}
                numberOfAttachments={conversationItem.attachments.length}
                recipientContactInfo={conversationItem.recipientContactInfo}
                type={conversationItem.type}
              />
            </div>
          )}
          {conversationItem.attachments
            .filter(_ => conversationItem.type !== ConversationTypeEnum.EMAIL)
            .map(p => (
              <div
                data-testid={
                  messageItemOutboundAttachmentBubbleId + p.attachmentId
                }
                key={messageItemOutboundAttachmentBubbleId + p.attachmentId}
              >
                <ItemAttachment
                  attachmentBubbleClassName={`${messageItemBubbleClassName} message-item-attachment-outbound-bubble`}
                  attachmentId={p.attachmentId}
                  attachmentName={p.fileName}
                  attachmentType={p.type}
                  attachmentUrl={p.url}
                  attachmentViewClassName="message-item-attachment-outbound-view"
                  coxAutoMessageId={conversationItem.key}
                  date={conversationItem.date}
                />
              </div>
            ))}
          <MessageItemBottom conversationItem={conversationItem} />
        </div>
      )}
    </div>
  );
}

export default MessageItem;
