import { HttpRequest, fetchWrapper } from "@coxauto-ui/core-http";
import { getBaseUrl } from "../config";
import { ResponseError } from "./interfaces/api-error";
import { SendCommunicationRequest } from "./interfaces/send-communication";
import { SendLogCallDetailsRequest } from "./interfaces/send-log-call";
import { sendCommunicationEmail } from "./send-communication-email";
import { sendCommunicationText } from "./send-communication-text";
import { Environment } from "@coxauto-ui/communications-interfaces";
import authClient from "@coxauto-ui/core-auth-get-auth-client-customer-conversation";

export async function sendCommunication(
  sendComms: SendCommunicationRequest,
  env: Environment
): Promise<boolean | ResponseError> {
  switch (sendComms.type) {
    case "text": {
      const textRequest = {
        consumerId: sendComms.consumerId,
        consumerPhoneNumber: sendComms.consumerContactInfo,
        consumerRecordSystem: sendComms.consumerRecordSystem,
        content: sendComms.content,
        attachments: sendComms.attachments
      };
      return await sendCommunicationText(textRequest, env);
    }
    case "email": {
      const emailRequest = {
        consumerId: sendComms.consumerId,
        consumerEmail: sendComms.consumerContactInfo,
        consumerRecordSystem: sendComms.consumerRecordSystem,
        body: sendComms.content,
        subject: sendComms.subject,
        attachments: sendComms.attachments
      };
      return await sendCommunicationEmail(emailRequest, env);
    }
    default:
      return false;
  }
}

export async function sendLogCall(
  logCallDetails: SendLogCallDetailsRequest,
  env: Environment
) {
  const url = `${getBaseUrl(env)}cxm/logcall`;

  const bridgeJwt = await authClient.getAuth();

  const request: HttpRequest = {
    url,
    method: "POST",
    body: logCallDetails,
    headers: { Authorization: `Bearer ${bridgeJwt}` }
  };
  const response = await fetchWrapper.sendAsyncText(request);
  if (response.statusCode !== 200 || response.hasError) {
    const error: ResponseError = {
      isError: true,
      statusCode: response.statusCode,
      errorResult: response.error
    };
    return error;
  }
  return true;
}
