import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DealUpdatesRecordsState } from "@coxauto-ui/ccxm/interfaces";
export const LIMIT_DEAL_UPDATES = 200;
export const DEAL_UPDATES_RECORDS_FEATURE_KEY = "dealUpdatesRecords";

export const initialDealUpdatesRecordsState: DealUpdatesRecordsState = {
  dealUpdates: [],
  showDealUpdates: false
};

export const dealUpdateRecordsSlice = createSlice({
  name: DEAL_UPDATES_RECORDS_FEATURE_KEY,
  initialState: initialDealUpdatesRecordsState,
  reducers: {
    showDealUpdates: (state: DealUpdatesRecordsState) => {
      state.showDealUpdates = true;
    },
    addDealUpdate: (
      state: DealUpdatesRecordsState,
      action: PayloadAction<string>
    ) => {
      const newDealUpdate = new Set([...state.dealUpdates, action.payload]);
      if (newDealUpdate.size > LIMIT_DEAL_UPDATES) {
        newDealUpdate.delete(state.dealUpdates[0]);
      }
      state.dealUpdates = Array.from(newDealUpdate);
    },
    clearDealUpdates: (state: DealUpdatesRecordsState) => {
      state.dealUpdates = [];
      state.showDealUpdates = false;
    }
  }
});

export const dealUpdatesRecordsReducer = dealUpdateRecordsSlice.reducer;
export const dealUpdatesRecordsActions = dealUpdateRecordsSlice.actions;

export const getDealUpdatesRecordsState = (
  rootState: any
): DealUpdatesRecordsState => rootState[DEAL_UPDATES_RECORDS_FEATURE_KEY];

export const selectDealUpdatesToShow = (rootState: DealUpdatesRecordsState) => {
  const state = getDealUpdatesRecordsState(rootState);
  return state?.showDealUpdates ? state.dealUpdates : [];
};
