import { BridgeAuthClient } from "@bridge/auth-js";
import { AuthConfiguration, BridgeRuntime, bridge } from "@bridge/okta-auth";
import {
  deriveEnvironmentName,
  environments
} from "@coxauto-ui/ccxm-util-derive-environment";

const configurations: { [key: string]: AuthConfiguration } = {
  [environments.nonprod]: {
    idp: bridge(BridgeRuntime.NON_PRODUCTION),
    clientId: "0oaj7hbzoiULXaWM3357",
    scopes: [
      "bridge.signin",
      "openid",
      "cxm-private.cxm-ui-bff.insights.read",
      "cxm-private.cxm-ui-bff.insights.read"
    ],
    redirectUri: `${window.location.origin}/`
  },
  [environments.prod]: {
    clientId: "0oaj7hcoekerOmcDu357",
    idp: bridge(BridgeRuntime.PRODUCTION),
    scopes: [
      "bridge.signin",
      "openid",
      "cxm-private.cxm-ui-bff.insights.read",
      "cxm-private.cxm-ui-bff.insights.read"
    ],
    redirectUri: `${window.location.origin}/`
  }
};

const environment = deriveEnvironmentName();

const authClient = BridgeAuthClient.buildFrom(configurations[environment], {
  init: async authClient => {
    const hasExpiredAccessToken = await authClient.hasExpiredAccessToken();

    if (hasExpiredAccessToken) {
      authClient.clearStorage();
    }
  }
});

export function overrideGetAuth(getAuthOverride: () => Promise<string>) {
  authClient.getAuth = getAuthOverride;
}

export default authClient;
