/* eslint-disable prettier/prettier */ // <--doing this due to massive `cr` errors that won't resolve
import { shallowEqual, useSelector } from "react-redux";
import { expireViewAsCookie } from "@coxauto-ui/ccxm/util/get-view-as";
import styled from "styled-components";
import {
  fetchUserProfile,
  getUserProfileState,
  useAppDispatch
} from "@coxauto-ui/ccxm/store";
import { Button } from "@interstate/components/Button";

const StyledTenantAdmin = styled.div`
  background: #ffd6c2;
  text-align: center;
  position: relative;
`;

const StyledUsernameArea = styled.div`
  position: relative;
`;

const StyledUsernameContent = styled.div`
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButtonStopViewingAs = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0px;
`;

export function TenantAdminViewAsBanner() {
  const dispatch = useAppDispatch();
  const { viewAsUsername } = useSelector(getUserProfileState, shallowEqual);

  const onClickStopViewingAs = () => {
    expireViewAsCookie();
    dispatch(fetchUserProfile(""));
  };

  return (
    <div>
      {viewAsUsername && (
        <StyledTenantAdmin>
          <StyledUsernameArea>
            <StyledUsernameContent
              data-testid="banner-viewing-as"
              id="banner-viewing-as"
            >
              You are viewing as {viewAsUsername}
            </StyledUsernameContent>
            <StyledButtonStopViewingAs>
              <Button
                data-testid="button-stop-viewing-as"
                htmlId="button-stop-viewing-as"
                size={"small"}
                onClick={onClickStopViewingAs}
              >
                Stop "Viewing As"
              </Button>
            </StyledButtonStopViewingAs>
          </StyledUsernameArea>
        </StyledTenantAdmin>
      )}
    </div>
  );
}

export default TenantAdminViewAsBanner;
