import { ConversationTypeEnum } from "@coxauto-ui/communications-interfaces";
import { getRecentEmailSubject } from "@coxauto-ui/communications-util";
import { Alert } from "@interstate/components/Alert";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ConversationContext, ConversationDetailsContext } from "../context";
import { replyAreaTestId } from "../testIds";
import MessageSendButton from "./message-send-button";
import MessageInput from "./message-input";
import { MessageSubjectInput } from "./message-subject-input";
import { ChannelValueSelector } from "./channel-value-selector";
import MessageSendUnavailable from "./message-send-unavailable";
import { MessageCallInfo } from "./message-call-info";
import MessageAttachmentUpload from "./message-attachment-upload";

const StyledReplyApp = styled.div<{
  conversationType: ConversationTypeEnum | undefined;
  hidden: boolean;
}>`
  border-radius: 5px;
  padding: 0.5em;
  .control-label {
    color: #42454a;
  }

  display: ${props => props.hidden && "none"};
`;
const StyledReplyTop = styled.div`
  border-bottom: 1px solid #e9e9e9;
`;
const StyledReplySubjectBottom = styled.div`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`;
const StyledReplyBottom = styled.div`
  display: flex;
`;
const StyledContainerAlert = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;

export function ReplyApp() {
  const [messageFailedToSend, setMessageFailedToSend] = useState(false);
  const [messageSentResponseFailedMsg, setMessageSentResponseFailedMsg] =
    useState("");

  const { replyOptions, disableReply } = useContext(ConversationContext);
  const {
    selectedReplyOption,
    setSelectedReplyOption,
    currentConversationType,
    conversationItems
  } = useContext(ConversationDetailsContext);

  useEffect(() => {
    const selectedOption = replyOptions.find(
      option => option.type === currentConversationType?.valueOf()
    );
    setSelectedReplyOption(selectedOption || null);
  }, [currentConversationType, replyOptions, setSelectedReplyOption]);

  const getComplianceError = () => {
    return `Customer has opted out of receiving ${currentConversationType} messages.`;
  };

  const msgSendFailedUpdateMsg = (msg: string) => {
    if (msg === "Compliance Issue") {
      setMessageSentResponseFailedMsg(getComplianceError());
      return;
    }
    setMessageSentResponseFailedMsg(msg);
  };

  if (disableReply)
    return (
      <MessageSendUnavailable
        textMessage={'Send function is Unavailable in "View as" Mode'}
        title={"View As"}
      />
    );
  return (
    <div>
      <StyledReplyApp
        conversationType={currentConversationType || undefined}
        data-testid={replyAreaTestId}
        hidden={
          !currentConversationType ||
          currentConversationType === ConversationTypeEnum.ALL
        }
        id="conversation-list-reply-pane"
      >
        {currentConversationType !== ConversationTypeEnum.PHONE && (
          <ChannelValueSelector isDisabled={!selectedReplyOption} />
        )}
        <StyledReplySubjectBottom>
          <MessageSubjectInput
            initialValue={getRecentEmailSubject(conversationItems)}
            isDisabled={!selectedReplyOption || messageFailedToSend}
          />
          {(!currentConversationType ||
            currentConversationType === ConversationTypeEnum.PHONE) && (
            <MessageCallInfo />
          )}
        </StyledReplySubjectBottom>
        <StyledReplyBottom>
          <MessageInput
            isDisabled={!selectedReplyOption || messageFailedToSend}
          />
        </StyledReplyBottom>
        <StyledReplyTop>
          {(!currentConversationType ||
            currentConversationType === ConversationTypeEnum.TEXT) && (
            <MessageAttachmentUpload
              isDisabled={!selectedReplyOption || messageFailedToSend}
            />
          )}
          {(!selectedReplyOption || messageFailedToSend) && (
            <StyledContainerAlert>
              <Alert htmlId="MessageReplyWarning" role="warning" type="warning">
                {messageFailedToSend && messageSentResponseFailedMsg}
                {!messageFailedToSend && getComplianceError()}
              </Alert>
            </StyledContainerAlert>
          )}
          <MessageSendButton
            onMessageSendFailed={() => {
              setMessageFailedToSend(true);
            }}
            onMessageSendFailedSetMsg={msgSendFailedUpdateMsg}
          />
        </StyledReplyTop>
      </StyledReplyApp>
    </div>
  );
}

export default ReplyApp;
