import { Environment } from "@coxauto-ui/communications-interfaces";
import { deriveEnvironmentName } from "./lib/util/derive-environment";
import authClient from "@coxauto-ui/core-auth-get-auth-client-customer-conversation";

export type BaseUrlsObj = { [key in Environment]: string };

export type apiConfig = {
  baseUrls: BaseUrlsObj;
};

export const cxmOccConfig: apiConfig = {
  baseUrls: {
    [Environment.nonprod]:
      "https://cxm-occ-bff-api.np-pipelines.app.coxautoinc.com/api/",
    [Environment.prod]:
      "https://cxm-occ-bff-api.pipelines.app.coxautoinc.com/api/",
    [Environment.none]:
      "https://cxm-occ-bff-api.np-pipelines.app.coxautoinc.com/api/"
  }
};

export function getBaseUrl(env: Environment) {
  if (env !== Environment.none) return cxmOccConfig.baseUrls[env];
  else return cxmOccConfig.baseUrls[deriveEnvironmentName()];
}

export const getHeader = async () => {
  const bridgeJwt = await authClient.getAuth();

  return {
    Authorization: `Bearer ${bridgeJwt}`,
    Accept: "application/vnd.coxauto.v1+json",
    "Content-Type": "application/vnd.coxauto.v1+json"
  };
};
