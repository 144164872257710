import {
  Attachment,
  ConversationContentType,
  ConversationDirection,
  ConversationItem,
  ConversationTypeEnum,
  EventResultEnum,
  SendCommunicationAttachmentResponse,
  getAttachmentType
} from "@coxauto-ui/communications-interfaces";
import { getDisplayableDate } from "@coxauto-ui/communications-util";

export function getConversationItemFromSendAction(
  personName: string,
  direction: ConversationDirection,
  channelType: ConversationTypeEnum,
  contactDetail: string,
  date: Date,
  content: string,
  subject: string,
  eventResult?: EventResultEnum,
  attachments: SendCommunicationAttachmentResponse[] = []
): ConversationItem {
  return {
    age: getDisplayableDate(date),
    content: getConversationItemContentBySendAction(
      content,
      channelType,
      "",
      subject
    ),
    direction,
    senderName: personName,
    date: date.toISOString(),
    senderContactInfo: contactDetail,
    key: -(Math.floor(Math.random() * 90000) + 10000),
    type: channelType,
    phoneNumber: getContactDetailByType(contactDetail, channelType),
    emailAddress: getContactDetailByType(contactDetail, channelType),
    subject,
    eventResult: eventResult ?? EventResultEnum.INPROGRESS,
    attachments: getAttachmentsBySendAction(attachments)
  };
}

function getConversationItemContentBySendAction(
  content: string,
  channelType: ConversationTypeEnum,
  recordingDeepLink: string,
  subject: string
): ConversationContentType {
  const text = content.trim();
  const shortSubject = subject;
  if (channelType === ConversationTypeEnum.PHONE) {
    return {
      text,
      callUrl: recordingDeepLink
    };
  } else {
    return { text, shortSubject };
  }
}

function getContactDetailByType(
  contactDetail: string,
  channelType: string
): string {
  let detail = "";
  switch (channelType) {
    case "text":
      detail = contactDetail;
      break;
    case "email":
      detail = contactDetail;
      break;
    default:
      detail = "";
  }
  return detail;
}

function getAttachmentsBySendAction(
  attachmentIds: SendCommunicationAttachmentResponse[]
): Attachment[] {
  return attachmentIds.map(p => {
    return {
      attachmentId: p.key,
      fileName: p.fileName,
      type: getAttachmentType(p.contentType),
      URL: p.url
    };
  });
}

export function contactEnumToEventResultEnum(item: string): EventResultEnum {
  switch (item.toUpperCase()) {
    case "YES": {
      return EventResultEnum.SPOKE;
    }
    case "NO": {
      return EventResultEnum.NOANSWER;
    }
    case "MACHINE_ANSWERED": {
      return EventResultEnum.LEFTMESSAGE;
    }
    default: {
      return EventResultEnum.UNKNOWN;
    }
  }
}
