import {
  SelectInput,
  SelectInputEventValue
} from "@interstate/components/SelectInput";
import { useContext, useEffect, useState } from "react";
import { ConversationDetailsContext } from "../context";
import {
  ContactEnum,
  MessageCallContacted
} from "@coxauto-ui/communications-interfaces";
import { replyCallContactedDropDownTestId } from "../testIds";
import { InterstateOnChangeEvent } from "@interstate/components/InterstateEvents";

/* eslint-disable-next-line */
export interface MessageCallContactedInputProps {}

export function MessageCallContactedInput(
  props: MessageCallContactedInputProps
) {
  const [callContacted, setCallContacted] = useState(
    ContactEnum.YES.toString()
  );
  const { messageCallContacted, setMessageCallContacted } = useContext(
    ConversationDetailsContext
  );
  const onSelectedInputChange = (
    iEvent: InterstateOnChangeEvent<SelectInputEventValue>
  ) => {
    const changeVal = iEvent.target.value?.toString();
    if (changeVal === undefined) return;
    setCallContacted(changeVal);
    setMessageCallContacted(changeVal);
  };
  useEffect(() => {
    if (callContacted !== messageCallContacted)
      setMessageCallContacted(callContacted);
  }, [callContacted, messageCallContacted, setMessageCallContacted]);
  return (
    <SelectInput
      required
      data-testid={replyCallContactedDropDownTestId}
      displayDeselectOption={false}
      htmlId="messageCallContactedInput"
      label="Contacted"
      name="CallContacted"
      options={[
        {
          label: MessageCallContacted.SPOKE,
          value: ContactEnum.YES.toString()
        },
        {
          label: MessageCallContacted.NOANSWER,
          value: ContactEnum.NO.toString()
        },
        {
          label: MessageCallContacted.LEFTMESSAGE,
          value: ContactEnum.MACHINE_ANSWERED.toString()
        }
      ]}
      value={callContacted}
      onChange={onSelectedInputChange}
    />
  );
}

export default MessageCallContactedInput;
