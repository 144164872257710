import { Alert } from "@interstate/components/Alert";
import styled from "styled-components";
import {
  replySendUnavailableAlertTestId,
  replySendUnavailableTestId
} from "../testIds";

const StyledContainerAlert = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;
  padding-right: 16px;
  padding-left: 16px;
`;

export interface MessageSendUnavailableProps {
  title: string;
  textMessage: string;
}

export function MessageSendUnavailable({
  title,
  textMessage
}: MessageSendUnavailableProps) {
  return (
    <StyledContainerAlert data-testid={replySendUnavailableTestId}>
      <Alert
        data-testid={replySendUnavailableAlertTestId}
        htmlId={replySendUnavailableAlertTestId}
        role="warning"
        title={title}
        type="warning"
      >
        {textMessage}
      </Alert>
    </StyledContainerAlert>
  );
}

export default MessageSendUnavailable;
