import { configureStore } from "@reduxjs/toolkit";
import {
  CONFIGURATION_FEATURE_KEY,
  USER_PROFILE_FEATURE_KEY,
  configurationReducer,
  userProfileReducer
} from "@coxauto-ui/ccxm/store";
import {
  USER_MANAGEMENT_FEATURE_KEY,
  userManagementReducer
} from "./user-management.slice";
import {
  DEALER_MANAGEMENT_FEATURE_KEY,
  dealerManagementReducer
} from "./dealer-management.slice";

export const adminStore = configureStore({
  reducer: {
    [USER_PROFILE_FEATURE_KEY]: userProfileReducer,
    [CONFIGURATION_FEATURE_KEY]: configurationReducer,
    [USER_MANAGEMENT_FEATURE_KEY]: userManagementReducer,
    [DEALER_MANAGEMENT_FEATURE_KEY]: dealerManagementReducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware(),
  devTools: process.env["NODE_ENV"] !== "production",
  enhancers: []
});
export type RootAdminState = ReturnType<typeof adminStore.getState>;
export type AppDispatch = typeof adminStore.dispatch;
