export type BusinessValidationErrorFields =
  | BusinessLocationValidationErrorFields
  | "taxExemptStatus"
  | "businessType";

export type BusinessLocationValidationErrorFields =
  | "phoneNumber1"
  | "phone1Extension"
  | "phoneNumber2"
  | "phone2Extension"
  | "emailAddress1"
  | "emailAddress2"
  | "street1"
  | "street2"
  | "city"
  | "state"
  | "postalCode"
  | "county";

const problemDetailsMappings = new Map<string, string>([
  ["taxExemptStatus", "taxExemptStatus"],
  ["businessType", "businessType"],
  ["primaryLocation.PhoneNumber1", "phoneNumber1"],
  ["primaryLocation.Phone1Extension", "phone1Extension"],
  ["primaryLocation.PhoneNumber2", "phoneNumber2"],
  ["primaryLocation.Phone2Extension", "phone2Extension"],
  ["primaryLocation.EmailAddress1", "emailAddress1"],
  ["primaryLocation.EmailAddress2", "emailAddress2"],
  ["primaryLocation.Street1", "street1"],
  ["primaryLocation.Street2", "street2"],
  ["primaryLocation.City", "city"],
  ["primaryLocation.State", "state"],
  ["primaryLocation.PostalCode", "postalCode"],
  ["primaryLocation.County", "county"],
  ["billingLocation.PhoneNumber1", "phoneNumber1"],
  ["billingLocation.Phone1Extension", "phone1Extension"],
  ["billingLocation.PhoneNumber2", "phoneNumber2"],
  ["billingLocation.Phone2Extension", "phone2Extension"],
  ["billingLocation.EmailAddress1", "emailAddress1"],
  ["billingLocation.EmailAddress2", "emailAddress2"],
  ["billingLocation.Street1", "street1"],
  ["billingLocation.Street2", "street2"],
  ["billingLocation.City", "city"],
  ["billingLocation.State", "state"],
  ["billingLocation.PostalCode", "postalCode"],
  ["billingLocation.County", "county"],
  ["otherLocation.PhoneNumber1", "phoneNumber1"],
  ["otherLocation.Phone1Extension", "phone1Extension"],
  ["otherLocation.PhoneNumber2", "phoneNumber2"],
  ["otherLocation.Phone2Extension", "phone2Extension"],
  ["otherLocation.EmailAddress1", "emailAddress1"],
  ["otherLocation.EmailAddress2", "emailAddress2"],
  ["otherLocation.Street1", "street1"],
  ["otherLocation.Street2", "street2"],
  ["otherLocation.City", "city"],
  ["otherLocation.State", "state"],
  ["otherLocation.PostalCode", "postalCode"],
  ["otherLocation.County", "county"]
]);

export type BusinessValidationErrorDetails = {
  Field: BusinessValidationErrorFields;
  Messages: string[];
};

export class BusinessValidationError extends Error {
  Errors: BusinessValidationErrorDetails[];
  constructor(message: string, errors: BusinessValidationErrorDetails[]) {
    super(message);
    this.Errors = errors;
  }
}

export type BusinessLocationValidationErrorDetails = {
  Field: BusinessLocationValidationErrorFields;
  Messages: string[];
};

export class BusinessLocationValidationError extends Error {
  Errors: BusinessLocationValidationErrorDetails[];
  constructor(
    message: string,
    errors: BusinessLocationValidationErrorDetails[]
  ) {
    super(message);
    this.Errors = errors;
  }
}

// this should probably go into an api project if/when we do that
export async function getBusinessValidationErrorDetails(response: Response) {
  if (response.status === 400) {
    const data = await response.json();

    if (data?.errors) {
      const errors: BusinessValidationErrorDetails[] = [];

      for (const fieldName in data?.errors) {
        const fieldNameFormatted =
          fieldName.charAt(0).toLowerCase() + fieldName.slice(1);

        if (problemDetailsMappings.has(fieldNameFormatted)) {
          errors.push({
            Field: problemDetailsMappings.get(
              fieldNameFormatted
            ) as BusinessValidationErrorFields,
            Messages: data.errors[fieldName]
          });
        }
      }

      return errors;
    }
  }

  return undefined;
}
