import {
  AttachmentTypeEnum,
  BaseColors
} from "@coxauto-ui/communications-interfaces";
import {
  DocumentIcon,
  PhotoIcon,
  SpeakerWaveIcon,
  VideoCameraIcon
} from "@interstate/components/Icons";
import styled from "styled-components";
import {
  messageItemAttachmentIconAudio,
  messageItemAttachmentIconFile,
  messageItemAttachmentIconImage,
  messageItemAttachmentIconVideo
} from "../testIds";
import { useContext } from "react";
import { ConversationContext } from "../context";
export interface AttachmentIconProps {
  attachmentType: AttachmentTypeEnum;
}
export const StyledImageIcon = styled(PhotoIcon)<{ baseColors: BaseColors }>`
  height: 16px;
  width: 16px;
  color: ${props => props.baseColors.white};
`;
export const StyledVideoIcon = styled(VideoCameraIcon)<{
  baseColors: BaseColors;
}>`
  height: 16px;
  width: 16px;
  color: ${props => props.baseColors.white};
`;
export const StyledAudioIcon = styled(SpeakerWaveIcon)<{
  baseColors: BaseColors;
}>`
  height: 16px;
  width: 16px;
  color: ${props => props.baseColors.white};
`;
export const StyledFileIcon = styled(DocumentIcon)<{ baseColors: BaseColors }>`
  height: 16px;
  width: 16px;
  color: ${props => props.baseColors.white};
`;

export function AttachmentIcon({ attachmentType }: AttachmentIconProps) {
  const { baseColors } = useContext(ConversationContext);

  switch (attachmentType) {
    case AttachmentTypeEnum.IMAGE:
      return (
        <StyledImageIcon
          baseColors={baseColors}
          data-testid={messageItemAttachmentIconImage}
        />
      );
    case AttachmentTypeEnum.VIDEO:
      return (
        <StyledVideoIcon
          baseColors={baseColors}
          data-testid={messageItemAttachmentIconVideo}
        />
      );
    case AttachmentTypeEnum.AUDIO:
      return (
        <StyledAudioIcon
          baseColors={baseColors}
          data-testid={messageItemAttachmentIconAudio}
        />
      );
    default:
      return (
        <StyledFileIcon
          baseColors={baseColors}
          data-testid={messageItemAttachmentIconFile}
        />
      );
  }
}

export default AttachmentIcon;
