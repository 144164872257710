import { MouseEvent, useCallback } from "react";
import { AmpSellingVehicle, ListMode } from "@coxauto-ui/ccxm/interfaces";
// struggling to get the CX Popover to work for us. I have engaged Andrew Carr, this is a temp solution
import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { LoadingIndicatorInter } from "@coxauto-ui/core/components";
import { TagManager } from "@coxauto-ui/core-third-party-ga4";
import {
  formatCurrency,
  formatDate
} from "@coxauto-ui/ccxm/util/custom-formats";
import classNames from "classnames";
import {
  completeOpportunityAction,
  useAppDispatch
} from "@coxauto-ui/ccxm/store";
import { Button } from "@interstate/components/Button";
import { CheckmarkSolidIcon } from "@interstate/components/Icons/CheckmarkSolidIcon";
import { toast } from "@interstate/components/Toast";
import { useRouting, useRoutingContext } from "@coxauto-ui/ccxm-routing";

export interface CcxmAmpCardProps {
  analyticsFilterList: string;
  campaign: string;
  consumerId: string;
  consumerFirstName: string | null;
  consumerLastName: string | null;
  dealershipName: string;
  equity: number | null;
  financeType: string | null;
  isCompleted: boolean;
  isLastRow: boolean;
  listMode: ListMode;
  marketLevel: string | null;
  nextServiceAppt: string | null;
  offerPaymentDiff: number | null;
  opportunityId: string;
  recordRow: number;
  sellingVehicle: AmpSellingVehicle | null;
  showConsumerInfo: boolean;
  showDealershipCol: boolean;
  termRemainingInMonths: number;
}

export function CcxmAmpCard({
  analyticsFilterList,
  campaign,
  consumerId,
  consumerFirstName,
  consumerLastName,
  dealershipName,
  equity,
  financeType,
  isCompleted,
  isLastRow,
  listMode,
  marketLevel,
  nextServiceAppt,
  offerPaymentDiff,
  opportunityId,
  recordRow,
  sellingVehicle,
  showDealershipCol,
  termRemainingInMonths
}: CcxmAmpCardProps) {
  const cardRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(false);
  const push = useRouting();
  const dispatch = useAppDispatch();
  const { selectedCustomerId, setSelectedCustomerId } = useRoutingContext();

  useEffect(() => {
    if (listMode === ListMode.desktopCustomer && shouldScroll === true) {
      cardRef.current?.scrollIntoView();
      setShouldScroll(false);
    }
  }, [cardRef, listMode, shouldScroll]);

  const getActionContext = useCallback(() => {
    const isCustomerView = listMode.includes("Customer");
    return isCustomerView ? "fromCondensedList" : "fromList";
  }, [listMode]);

  const consumerFullName = useMemo(() => {
    return consumerFirstName || consumerLastName
      ? [consumerFirstName || "--", consumerLastName || "--"].join(" ")
      : "--";
  }, [consumerFirstName, consumerLastName]);

  const sellingVehicleName = useMemo(() => {
    return (
      sellingVehicle && (
        <div
          data-testid={`consumer-${recordRow}-current-vehicle`}
          id={`consumer-${opportunityId}-current-vehicle`}
        >
          <div
            data-testid={`consumer-${recordRow}-current-vehicle-year-make-model`}
            id={`consumer-${opportunityId}-current-vehicle-year-make-model`}
          >
            {sellingVehicle.make || sellingVehicle.model || sellingVehicle.year
              ? [
                  sellingVehicle.year || "--",
                  sellingVehicle.make || "--",
                  sellingVehicle.model || "--"
                ].join(" ")
              : "--"}
          </div>
        </div>
      )
    );
  }, [opportunityId, recordRow, sellingVehicle]);

  const borderMode = useMemo(() => {
    return listMode === ListMode.desktopCustomer &&
      selectedCustomerId === consumerId
      ? "highlighted"
      : "standard";
  }, [listMode, selectedCustomerId, consumerId]);

  const formattedNextServiceAppointment = useMemo(() => {
    try {
      if (!nextServiceAppt || nextServiceAppt.length === 0) {
        return nextServiceAppt;
      }
      return formatDate(nextServiceAppt, "M/d/yy");
    } catch (ex) {
      return nextServiceAppt;
    }
  }, [nextServiceAppt]);

  const formattedOfferPaymentDiff = useMemo(() => {
    try {
      if (offerPaymentDiff) {
        return formatCurrency(offerPaymentDiff);
      } else {
        return "--";
      }
    } catch (ex) {
      return offerPaymentDiff;
    }
  }, [offerPaymentDiff]);

  const formattedEquity = useMemo(() => {
    try {
      if (equity) {
        return formatCurrency(equity);
      } else {
        return "--";
      }
    } catch (ex) {
      return equity;
    }
  }, [equity]);

  const trackOpportunityCompletedClick = (
    recordRow: number,
    consumerId: string
  ) => {
    TagManager.event({
      event: "productEvent",
      eventName: "Opportunity Completed",
      eventProperties: {
        actionContext: getActionContext(),
        commonCustomerId: consumerId,
        errorMessage: undefined,
        filterCategories: analyticsFilterList,
        listContext: "AMP",
        listPlacement: recordRow
      }
    });
  };

  const onCompletedClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    trackOpportunityCompletedClick(recordRow, consumerId);
    setLoading(true);

    const completionResult = await dispatch(
      completeOpportunityAction({
        opportunityId,
        markComplete: !isCompleted
      })
    ).unwrap();

    if (completionResult.hasError) {
      toast.error("Something went wrong. Please try again later.");
    }

    setLoading(false);
  };

  const trackCustomerOpenClick = (recordRow: number, consumerId: string) => {
    TagManager.event({
      event: "productEvent",
      eventName: "Customer Opened",
      eventProperties: {
        actionContext: getActionContext(),
        commonCustomerId: consumerId,
        errorMessage: undefined,
        filterCategories: analyticsFilterList,
        listContext: "AMP",
        listPlacement: recordRow
      }
    });
  };

  const handleScrollSideEffect = () => {
    trackCustomerOpenClick(recordRow, consumerId);
    if (listMode === ListMode.desktopList) {
      setShouldScroll(true);
    }
  };

  const onOpportunityCardClick = () => {
    handleScrollSideEffect();
    setSelectedCustomerId(consumerId);
    push(`/list/opportunities/${consumerId}`);
  };

  const isCondensedView = useMemo(() => {
    return [
      ListMode.desktopCustomer,
      ListMode.mobileCustomer,
      ListMode.mobileList
    ].includes(listMode);
  }, [listMode]);

  return (
    <>
      <div
        className={classNames(
          `customer-${recordRow}`,
          borderMode,
          listMode,
          "cell",
          "left-cell",
          "top-cell",
          "no-word-wrap",
          {
            "bottom-cell":
              listMode === ListMode.desktopList ||
              listMode === ListMode.tabletList,
            "border-bottom":
              (listMode === ListMode.desktopList ||
                listMode === ListMode.tabletList) &&
              isLastRow,
            nowrap:
              listMode === ListMode.desktopList ||
              listMode === ListMode.tabletList
          }
        )}
        ref={cardRef}
        onClick={onOpportunityCardClick}
      >
        {isCondensedView && (
          <div
            className="cell-label"
            data-testid={`consumer-${recordRow}-full-name-label`}
          >
            Customer
          </div>
        )}
        <Link
          data-bordermode={`consumer-${recordRow}-${borderMode}`}
          data-consumerid={consumerId}
          data-consumername="consumer-insight-record-full-name"
          data-testid={`consumer-${recordRow}-full-name`}
          id={`consumer-${opportunityId}-button`}
          to={`/list/opportunities/${consumerId}`}
        >
          {consumerFullName}
        </Link>
      </div>
      <div
        className={classNames(
          `campaigns-${recordRow}`,
          borderMode,
          listMode,
          "cell",
          "nowrap",
          {
            "top-cell":
              listMode === ListMode.desktopList ||
              listMode === ListMode.tabletList,
            "right-cell":
              listMode === ListMode.mobileList ||
              listMode === ListMode.desktopCustomer,
            "bottom-cell":
              listMode === ListMode.desktopList ||
              listMode === ListMode.tabletList,
            "left-cell":
              listMode === ListMode.mobileList ||
              listMode === ListMode.desktopCustomer,
            "border-bottom":
              isLastRow &&
              (listMode === ListMode.desktopList ||
                listMode === ListMode.tabletList)
          }
        )}
        data-testid={`consumer-${recordRow}-campaigns`}
        onClick={onOpportunityCardClick}
      >
        {isCondensedView && (
          <div
            className="cell-label"
            data-testid={`consumer-${recordRow}-campaigns-label`}
          >
            Campaign
          </div>
        )}
        <div className="consumer-value">{campaign || "--"}</div>
      </div>
      {showDealershipCol && (
        <div
          className={classNames(
            `dealership-${recordRow}`,
            borderMode,
            listMode,
            "cell",
            "nowrap",
            {
              "top-cell":
                listMode === ListMode.desktopList ||
                listMode === ListMode.tabletList,
              "right-cell":
                listMode === ListMode.mobileList ||
                listMode === ListMode.desktopCustomer,
              "bottom-cell":
                listMode === ListMode.desktopList ||
                listMode === ListMode.tabletList,
              "border-bottom":
                isLastRow &&
                (listMode === ListMode.desktopList ||
                  listMode === ListMode.tabletList)
            }
          )}
          data-testid={`consumer-${recordRow}-dealership-name`}
        >
          {isCondensedView && (
            <div
              className="cell-label"
              data-testid={`consumer-${recordRow}-dealership-label`}
            >
              Dealership
            </div>
          )}
          <div className="consumer-value">{dealershipName || "--"}</div>
        </div>
      )}
      <div
        className={classNames(
          `current-vehicle-${recordRow}`,
          borderMode,
          listMode,
          "cell",
          "nowrap",
          {
            "top-cell":
              listMode === ListMode.desktopList ||
              listMode === ListMode.tabletList,
            "bottom-cell":
              listMode === ListMode.desktopList ||
              listMode === ListMode.tabletList,
            "left-cell":
              listMode === ListMode.mobileList ||
              listMode === ListMode.desktopCustomer,
            "border-bottom":
              isLastRow &&
              (listMode === ListMode.desktopList ||
                listMode === ListMode.tabletList)
          }
        )}
        onClick={onOpportunityCardClick}
      >
        {isCondensedView && (
          <div
            className="cell-label"
            data-testid={`consumer-${recordRow}-current-vehicle-label`}
          >
            Vehicle
          </div>
        )}
        <div className="consumer-value">{sellingVehicleName || "--"}</div>
      </div>
      <div
        className={classNames(
          `market-level-${recordRow}`,
          borderMode,
          listMode,
          "cell",
          "nowrap",
          {
            "top-cell":
              listMode === ListMode.desktopList ||
              listMode === ListMode.tabletList,
            "right-cell":
              listMode === ListMode.mobileList ||
              listMode === ListMode.desktopCustomer,
            "bottom-cell":
              listMode === ListMode.desktopList ||
              listMode === ListMode.tabletList,
            "border-bottom":
              isLastRow &&
              (listMode === ListMode.desktopList ||
                listMode === ListMode.tabletList)
          }
        )}
        data-testid={`consumer-${recordRow}-market-level`}
        onClick={onOpportunityCardClick}
      >
        {isCondensedView && (
          <div
            className="cell-label"
            data-testid={`consumer-${recordRow}-market-level-label`}
          >
            Market Level
          </div>
        )}
        <div className="consumer-value">
          {marketLevel?.toLocaleLowerCase() === "unknown"
            ? "--"
            : marketLevel || "--"}
        </div>
      </div>
      {!isCondensedView && (
        <div
          className={classNames(
            `offer-payment-diff-${recordRow}`,
            borderMode,
            listMode,
            "cell",
            "nowrap",
            "top-cell",
            "bottom-cell",
            {
              "border-bottom": isLastRow
            }
          )}
          data-testid={`consumer-${recordRow}-offer-payment-diff`}
          onClick={onOpportunityCardClick}
        >
          <div className="consumer-value">{formattedOfferPaymentDiff}</div>
        </div>
      )}
      <div
        className={classNames(
          `equity-${recordRow}`,
          borderMode,
          listMode,
          "cell",
          "nowrap",
          "top-cell",
          {
            "right-cell":
              listMode === ListMode.mobileList ||
              listMode === ListMode.desktopCustomer,
            "bottom-cell":
              listMode === ListMode.desktopList ||
              listMode === ListMode.tabletList,
            "border-bottom":
              isLastRow &&
              (listMode === ListMode.desktopList ||
                listMode === ListMode.tabletList)
          }
        )}
        data-testid={`consumer-${recordRow}-equity`}
        onClick={onOpportunityCardClick}
      >
        {isCondensedView && (
          <div
            className="cell-label"
            data-testid={`consumer-${recordRow}-equity-label`}
          >
            Equity
          </div>
        )}
        <div className="consumer-value">{formattedEquity || "--"}</div>
      </div>
      {!isCondensedView && (
        <div
          className={classNames(
            `finance-type-${recordRow}`,
            borderMode,
            listMode,
            "cell",
            "nowrap",
            "top-cell",
            "bottom-cell",
            {
              "border-bottom": isLastRow
            }
          )}
          data-testid={`consumer-${recordRow}-finance-type`}
          onClick={onOpportunityCardClick}
        >
          <div className="consumer-value">{financeType || "--"}</div>
        </div>
      )}
      {!isCondensedView && (
        <div
          className={classNames(
            `term-remaining-${recordRow}`,
            borderMode,
            listMode,
            "cell",
            "nowrap",
            "top-cell",
            "bottom-cell",
            {
              "border-bottom": isLastRow
            }
          )}
          data-testid={`consumer-${recordRow}-term-remaining-in-months`}
          onClick={onOpportunityCardClick}
        >
          <div className="consumer-value">{termRemainingInMonths || "--"}</div>
        </div>
      )}
      {!isCondensedView && (
        <div
          className={classNames(
            `next-service-appt-${recordRow}`,
            borderMode,
            listMode,
            "cell",
            "nowrap",
            "top-cell",
            "bottom-cell",
            {
              "border-bottom": isLastRow
            }
          )}
          data-testid={`consumer-${recordRow}-next-service-appt`}
          onClick={onOpportunityCardClick}
        >
          <div className="consumer-value">
            {formattedNextServiceAppointment || "--"}
          </div>
        </div>
      )}
      <div
        className={classNames(
          `actions-${recordRow}`,
          borderMode,
          listMode,
          "cell",
          "right-cell",
          "bottom-cell",
          {
            sticky:
              listMode === ListMode.desktopList ||
              listMode === ListMode.tabletList,
            "left-divider":
              listMode === ListMode.desktopList ||
              listMode === ListMode.tabletList,
            "top-cell":
              listMode === ListMode.desktopList ||
              listMode === ListMode.tabletList,
            "left-cell":
              listMode === ListMode.mobileList ||
              listMode === ListMode.desktopCustomer,
            "border-bottom": isLastRow
          }
        )}
      >
        <Button
          buttonStyle="secondary"
          className="amp-opportunity-list-completed-btn"
          data-testid={`consumer-${recordRow}-amp-opportunity-completed-button`}
          disabled={loading}
          htmlId={`consumer-${opportunityId}-amp-opportunity-completed-button`}
          size="small"
          startIcon={isCompleted && <CheckmarkSolidIcon />}
          onClick={onCompletedClick}
        >
          {loading ? (
            <div className="loading-indicator-wrapper">
              <LoadingIndicatorInter
                htmlId={`consumer-${opportunityId}-amp-opportunity-completed-loading-overlay`}
                size="small"
              />
            </div>
          ) : (
            <span>{isCompleted ? "Completed" : "Complete"}</span>
          )}
        </Button>
      </div>
    </>
  );
}

export default CcxmAmpCard;
