import {
  getAttachmentThumbnailForScratchpad,
  getErrorMessage,
  isResponseError
} from "@coxauto-ui/communications-api";
import {
  BaseColors,
  SendCommunicationAttachmentResponse
} from "@coxauto-ui/communications-interfaces";
import { LoadingIndicator } from "@coxauto-ui/core-components";
import { IconClose } from "@coxauto-ui/core-cx";
import { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ConversationContext } from "../context";
import { replyAttachmentScratchpadThumbnail } from "../testIds";
import { toast } from "@interstate/components/Toast";
import { VideoCameraIcon } from "@interstate/components/Icons";

export interface MessageAttachmentThumbnailProps {
  attachmentMeta: SendCommunicationAttachmentResponse;
  onRemoveThumbnail: (key: string, token: string) => void;
  fileType?: string;
}
const ThumbWrapper = styled.div<{ baseColors: BaseColors }>`
  position: relative;
  display: inline-block;
  margin-right: 2%;
  border-radius: 3px;
  border: 0.5px solid var(--surface-muted, ${props => props.baseColors.gray100});
  background: ${props => props.baseColors.blue700};

  &:hover {
    opacity: 0.6;
    background: ${props => props.baseColors.gray400};
    border: solid 1px;
  }
`;
const Thumb = styled.img`
  height: 40px;
  width: 40px;
`;
const CloseThumbIcon = styled(IconClose)<{ baseColors: BaseColors }>`
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: ${props => props.baseColors.gray400};
  border-radius: 100%;
  opacity: 0.9;
  font-weight: bold;
  border: solid 1px;
`;
const VideoAttachmentPreview = styled.div<{ baseColors: BaseColors }>`
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 5px;
  margin-top: 10px;
`;
const VideoIcon = styled(VideoCameraIcon)<{ baseColors: BaseColors }>`
  width: 20px;
  height: 20px;
  background: ${props => props.baseColors.blue700};
  color: ${props => props.baseColors.white};
`;

export function MessageAttachmentThumbnail(
  props: MessageAttachmentThumbnailProps
) {
  const { env, baseColors } = useContext(ConversationContext);
  const [imageUrl, setImageUrl] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getAttachmentThumbnailForScratchpad(
      props.attachmentMeta.key,
      props.attachmentMeta.token,
      env
    ).then(resultBinary => {
      if (isResponseError(resultBinary)) {
        toast.error(getErrorMessage(resultBinary));
      } else {
        const test = resultBinary as ArrayBuffer;
        const url = window.URL.createObjectURL(
          new Blob([new Uint8Array(test).buffer])
        );
        setImageUrl(url);
      }
      setIsLoading(false);
    });
  }, [env, props.attachmentMeta.key, props.attachmentMeta.token]);

  const onMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  return (
    <ThumbWrapper
      baseColors={baseColors}
      data-testid={replyAttachmentScratchpadThumbnail}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {(isLoading && (
        <LoadingIndicator
          actionName="Loading upload communication attachment thumbnail"
          htmlId="attachment-thumbnail-loading-indicator"
        />
      )) ||
        (props.fileType?.includes("video/") ? (
          <VideoAttachmentPreview baseColors={baseColors}>
            <VideoIcon baseColors={baseColors} />
          </VideoAttachmentPreview>
        ) : (
          <Thumb
            alt="..."
            src={imageUrl}
            title={props.attachmentMeta.fileName}
          />
        ))}
      {isHovered && (
        <CloseThumbIcon
          baseColors={baseColors}
          onClick={() =>
            props.onRemoveThumbnail(
              props.attachmentMeta.key,
              props.attachmentMeta.token
            )
          }
        />
      )}
    </ThumbWrapper>
  );
}

export default MessageAttachmentThumbnail;
