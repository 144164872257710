import { ReactNode } from "react";
import styled from "styled-components";

/* eslint-disable-next-line */
export interface CcxmReusableColumnHeaderProps {
  className?: string;
  columnCount: number;
  children?: ReactNode;
}

const StyledCcxmReusableColumnHeader = styled.div<{ columnCount: number }>`
  position: sticky;
  top: 0;
  background: #f5f6f7;
  border-top: 1px solid #b2b6bf;
  border-bottom: 1px solid #b2b6bf;
  padding: 16px 6px;
  font-weight: bold;
  font-size: 14px;
  z-index: 4;

  &:nth-child(1) {
    border-left: 1px solid #b2b6bf;
    border-top-left-radius: 4px;
    padding: 16px 6px 16px 16px;
  }

  &:nth-child(${({ columnCount }) => columnCount}) {
    border-right: 1px solid #b2b6bf;
    border-top-right-radius: 4px;
    padding: 16px 16px 16px 6px;
  }
`;

const CcxmReusableColumnHeader = ({
  columnCount,
  children,
  ...other
}: CcxmReusableColumnHeaderProps) => {
  return (
    <StyledCcxmReusableColumnHeader columnCount={columnCount} {...other}>
      {children}
    </StyledCcxmReusableColumnHeader>
  );
};

export default CcxmReusableColumnHeader;
