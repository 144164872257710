import { useCallback, useEffect, useMemo, useState } from "react";
import {
  BaseColors,
  ContactDetails,
  ConversationTypeEnum,
  Environment,
  OccConfig,
  ReplyOption,
  SystemOfRecord
} from "@coxauto-ui/communications-interfaces";
import { ConversationPane } from "./conversation-pane";
import {
  getContactDetails,
  getErrorMessage,
  isResponseError
} from "@coxauto-ui/communications-api";
import { ConversationContext } from "./context";

// eslint-disable-next-line
import { TagManager } from "@coxauto-ui/core-third-party-ga4";
import authClient from "@coxauto-ui/core-auth-get-auth-client-customer-conversation";
import { InterstateThemeProvider } from "@interstate/components/InterstateThemeProvider";

import { interstateThemeRegistry } from "@interstate/components/InterstateTheming/themes/interstateThemeRegistry";
export interface ConversationProps {
  customerId: string;
  customerSystem?: SystemOfRecord;
  /** When `true`, displays the title above the customer conversation*/
  showCustomerInfo?: boolean;
  /** Title override to display when `showCustomerInfo` is true. Defaults to customer name when undefined */
  title?: string;
  occConfig: OccConfig;
  getAuthOverride?: () => Promise<string>;
  env?: Environment;
}

export function CustomerConversationApp({
  customerId,
  customerSystem,
  showCustomerInfo = true,
  title,
  occConfig = {
    username: "",
    disableReply: false,
    defaultTab: undefined,
    messageContent: undefined
  },
  getAuthOverride,
  env = Environment.none
}: ConversationProps) {
  const [customerName, setCustomerName] = useState("");
  const [replyOptions, setReplyOptions] = useState<ReplyOption[]>([]);
  const [activeListTab, setActiveListTab] = useState(ConversationTypeEnum.ALL);
  const [error, setError] = useState("");
  const [disableReply, setDisableReply] = useState(occConfig.disableReply);
  const [getUserName, setGetUserName] = useState<() => string>(() => {
    return () => {
      return occConfig.username;
    };
  });
  const [contactDetails, setContactDetails] = useState<ContactDetails>(
    {} as ContactDetails
  );
  const [baseColors, setBaseColors] = useState<BaseColors>({} as BaseColors);

  useEffect(() => {
    if (getAuthOverride) {
      authClient.getAuth = getAuthOverride;
    }
  });

  const setCustomerConversationContext = useCallback(async () => {
    const contactDetails = await getContactDetails(
      {
        consumerId: customerId,
        consumerRecordSystem: customerSystem || SystemOfRecord.COMMON
      },
      env
    );

    if (isResponseError(contactDetails)) {
      setError(getErrorMessage(contactDetails));
    } else if (!contactDetails) {
      setError("no permissions");
    } else {
      setReplyOptions(contactDetails.replyOptions);
      setCustomerName(contactDetails.name.fullName);
      setContactDetails(contactDetails);
    }
  }, [customerId, env]);

  useEffect(() => {
    setError("");
  }, [customerId]);

  useEffect(() => {
    setCustomerConversationContext();
  }, [setCustomerConversationContext]);

  useEffect(() => {
    TagManager.event({
      event: "productEvent",
      eventName: "Conversation Opened",
      eventProperties: {
        packagedBusinessCapability: "OCC Inbox",
        commonCustomerId: customerId,
        communicationChannel: undefined,
        replyContext: undefined,
        errorMessage: undefined
      }
    });

    return () => {
      TagManager.event({
        event: "productEvent",
        eventName: "Conversation Closed",
        eventProperties: {
          packagedBusinessCapability: "OCC Inbox",
          commonCustomerId: customerId,
          communicationChannel: undefined,
          replyContext: undefined,
          errorMessage: undefined
        }
      });
    };
  }, [customerId]);

  useEffect(() => {
    setDisableReply(occConfig.disableReply);
    setGetUserName(() => () => occConfig.username);
  }, [occConfig]);

  const providerValue = useMemo(() => {
    return {
      activeListTab,
      customerId,
      customerSystem: customerSystem || SystemOfRecord.COMMON,
      customerName,
      disableReply,
      env,
      error,
      replyOptions,
      showCustomerInfo,
      title,
      contactDetails,
      baseColors,
      occConfig,
      getUserName,
      setActiveListTab,
      setError,
      setContactDetails,
      setBaseColors
    };
  }, [
    activeListTab,
    customerId,
    customerName,
    customerSystem,
    disableReply,
    env,
    error,
    replyOptions,
    showCustomerInfo,
    title,
    contactDetails,
    baseColors,
    occConfig,
    getUserName,
    setActiveListTab,
    setError,
    setContactDetails,
    setBaseColors
  ]);

  return (
    <InterstateThemeProvider
      applicationName="test"
      scopeName="test"
      themeRegistries={[interstateThemeRegistry]}
    >
      <ConversationContext.Provider value={providerValue}>
        <ConversationPane />
      </ConversationContext.Provider>
    </InterstateThemeProvider>
  );
}

export default CustomerConversationApp;
