import { ViewportMode } from "@coxauto-ui/ccxm/interfaces";

const viewportBreakpoints = {
  mobile: 480,
  tablet: 768,
  desktop: 1280
};

const getViewportMode = (): ViewportMode => {
  const { tablet, desktop } = viewportBreakpoints;
  const currentWidth = window.innerWidth;
  return currentWidth >= desktop
    ? ViewportMode.desktop
    : currentWidth >= tablet
    ? ViewportMode.tablet
    : ViewportMode.mobile;
};

export default getViewportMode;
