import { matchPath } from "react-router-dom";
import { getRouteDefinitions } from "./get-route-definitions";

export function getPageTitle(currentPath: string): string {
  const routes = getRouteDefinitions();

  for (const route of routes) {
    const matchedPath = matchPath(
      {
        path: route.path,
        end: true // This ensures an exact match; modify as needed
      },
      currentPath
    );

    if (matchedPath && route.title) {
      return route.title || "";
    }
  }

  return "";
}
