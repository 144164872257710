// eslint-disable-next-line @nx/enforce-module-boundaries
import { getUserProfileState } from "@coxauto-ui/ccxm/store";
import { shallowEqual, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { ADMIN_HOME_PAGE } from "./admin-access";

export const USER_HOME_PAGE = "/list/deals";

export const DealerUserAccess = ({
  children
}: {
  children: React.ReactElement;
}) => {
  const { isEmployee } = useSelector(getUserProfileState, shallowEqual);
  return isEmployee ? <Navigate to={ADMIN_HOME_PAGE} /> : children;
};

export default DealerUserAccess;
