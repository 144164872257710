import { configureStore } from "@reduxjs/toolkit";
import {
  CONFIGURATION_FEATURE_KEY,
  configurationReducer
} from "./configuration.slice";
import {
  CONSUMER_INSIGHTS_RECORDS_FEATURE_KEY,
  consumerInsightsRecordsReducer
} from "./consumer-insights-records.slice";
import {
  OPPORTUNITY_INSIGHTS_RECORDS_FEATURE_KEY,
  opportunityInsightsRecordsReducer
} from "./opportunity-insights-records.slice";
import {
  USER_PREFERENCES_FEATURE_KEY,
  userPreferencesReducer
} from "./user-preferences.slice";
import {
  USER_PROFILE_FEATURE_KEY,
  userProfileReducer
} from "./user-profile.slice";
import {
  DEAL_INSIGHTS_RECORDS_FEATURE_KEY,
  dealInsightsRecordsReducer
} from "./deal-insights-records.slice";
import {
  DEAL_UPDATES_RECORDS_FEATURE_KEY,
  dealUpdatesRecordsReducer
} from "./deal-updates-records.slice";

export const store = configureStore({
  reducer: {
    // please when adding a new reducer, add it also on the object defaultPreloadedState
    // that is on the default-preloaded-state.ts file to have a complete state on the jest tests
    [USER_PROFILE_FEATURE_KEY]: userProfileReducer,
    [CONFIGURATION_FEATURE_KEY]: configurationReducer,
    [USER_PREFERENCES_FEATURE_KEY]: userPreferencesReducer,
    [CONSUMER_INSIGHTS_RECORDS_FEATURE_KEY]: consumerInsightsRecordsReducer,
    [OPPORTUNITY_INSIGHTS_RECORDS_FEATURE_KEY]:
      opportunityInsightsRecordsReducer,
    [DEAL_INSIGHTS_RECORDS_FEATURE_KEY]: dealInsightsRecordsReducer,
    [DEAL_UPDATES_RECORDS_FEATURE_KEY]: dealUpdatesRecordsReducer
  },
  // Additional middleware can be passed to this array
  middleware: getDefaultMiddleware => getDefaultMiddleware(),
  devTools: process.env["NODE_ENV"] !== "production",
  // Optional Redux store enhancers
  enhancers: []
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
