import { createContext, useContext, useEffect, useState } from "react";
import {
  RealTimeMessage,
  SignalRProviderContext,
  SignalRProviderProps,
  UseSignalRMessage
} from "./types";
import {
  HubConnectionBuilder,
  JsonHubProtocol,
  LogLevel
} from "@microsoft/signalr";

const SignalRContext = createContext<SignalRProviderContext | undefined>(
  undefined
);

export const useSignalRMessage = (props: UseSignalRMessage) => {
  const context = useContext(SignalRContext);
  if (context === undefined) {
    throw new Error("useSignalMessage must be used within a SignalRProvider");
  }
  const { message, connected, isLoading } = context;
  return { message, connected, isLoading };
};

export const SignalRProvider = (props: SignalRProviderProps) => {
  const { children, url, logLevel, accessTokenFactory } = props;
  const [message, setMessage] = useState<RealTimeMessage>();
  const [connected, setConnected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const connectionBuilder = new HubConnectionBuilder();
      const connection = connectionBuilder
        .withUrl(url, {
          accessTokenFactory
        })
        .withAutomaticReconnect()
        .withHubProtocol(new JsonHubProtocol())
        .configureLogging(logLevel === undefined ? LogLevel.None : logLevel)
        .build();
      // maybe I can change it to listen other methods
      connection.on("broadcastMessage", data => {
        if (data) {
          setMessage({ data, timestamp: new Date() });
        }
      });
      connection.onclose(e => {
        setConnected(false);
      });
      connection
        .start()
        .catch(err => {
          setConnected(false);
          console.error(err.toString());
        })
        .then(() => {
          setIsLoading(false);
          setConnected(true);
        });
    })();
  }, []);

  return (
    <SignalRContext.Provider
      value={{ message, setMessage, connected, isLoading }}
    >
      {children}
    </SignalRContext.Provider>
  );
};
export default SignalRContext;
