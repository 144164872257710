import { replyLogACallInputHeader } from "../testIds";
import styled from "styled-components";
import MessageCallContactedInput from "./message-call-contacted-input";
import { MessageCallTypeInput } from "./message-call-type-input";
import { ChannelValueSelector } from "./channel-value-selector";
/* eslint-disable-next-line */

const StyledMessageCallInfo = styled.div`
  flex-basis: 100%;
`;

const StyledMessageCallInfoInputs = styled.div`
  display: flex;
  padding-bottom: 10px;
  .control-label {
    white-space: nowrap;
  }
  div:first-child {
    padding-right: 10px;
  }
`;

const StyledText = styled.div`
  margin-bottom: 20px;
`;

export function MessageCallInfo() {
  return (
    <StyledMessageCallInfo data-testid={replyLogACallInputHeader}>
      <StyledText>Log a Call</StyledText>
      <StyledMessageCallInfoInputs>
        <MessageCallTypeInput />
        <MessageCallContactedInput />
      </StyledMessageCallInfoInputs>
      <ChannelValueSelector isDisabled={false} />
    </StyledMessageCallInfo>
  );
}

export default MessageCallInfo;
