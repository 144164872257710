import { fetchWrapper } from "@coxauto-ui/core-http";
import { getBaseUrl, getHeader } from "../config";
import { ResponseError } from "./interfaces/api-error";
import { SendCommunicationEmailRequest } from "./interfaces/send-communication-email";
import { Environment } from "@coxauto-ui/communications-interfaces";

export async function sendCommunicationEmail(
  data: SendCommunicationEmailRequest,
  env: Environment
): Promise<boolean | ResponseError> {
  const url = `${getBaseUrl(env)}cxm/sendemail`;

  const header = await getHeader();

  const response = await fetchWrapper.postAsyncText(url, data, header);
  if (response && !response.hasError) {
    return true;
  } else {
    const error: ResponseError = {
      isError: true,
      statusCode: response.statusCode,
      errorResult: response.error
    };
    return error;
  }
}
