/**
 * Description: the bridge bar allowed environments you can follow https://ghe.coxautoinc.com/PPE-CoreDomain/bridge-bar/blob/master/bridge-bar/api.md#renderconfig for more information
 */

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "bridge-bar": any;
    }
  }
}

export type BridgeBarEnvironment = "qa" | "prod" | "dev" | "dz_np" | "dz_prod";
export interface BridgeBarScriptProps {
  sessionId: string;
  environment: BridgeBarEnvironment;
  solutionGroup: string;
  showBridgebar?: boolean;
}

export const getBridgeBarScript = ({
  sessionId,
  environment = "prod",
  solutionGroup,
  showBridgebar
}: BridgeBarScriptProps) => {
  const bridgeBarSettings = {
    solutionGroupCode: solutionGroup,
    sessionId,
    show: showBridgebar,
    env: environment
  };

  return `var bbEl = document.querySelector('bridge-bar');bbEl.addEventListener('initialized',function(){bbEl.bb.render(${JSON.stringify(
    bridgeBarSettings
  )});});`;
};
