export function getCookie(name: string) {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");

  if (parts.length === 2) {
    return parts.pop()?.split(";").shift();
  }
  return "";
}

export function getRootDomain(url: string) {
  // eslint-disable-next-line no-useless-escape
  const regExp = /[.]([^\/]+)/;
  const result = regExp.exec(url);
  return (result && result.length > 1 && result[1]) || window.location.hostname;
}
