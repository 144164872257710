import { environments } from "@coxauto-ui/ccxm/util/derive-environment";
import { NewRelicConfig } from "@coxauto-ui/core/interfaces/third-party/newrelic";

const config: { [key: string]: NewRelicConfig } = {
  nonprod: {
    accountID: "2755601",
    trustKey: "1190893",
    agentID: "1120109754",
    licenseKey: "NRJS-c686c3bcfdcd1bd390a",
    applicationID: "1120109754",
    corsUseNewRelicHeader: true,
    excludeNewRelicHeader: false,
    corsUseTraceContextHeaders: true,
    distributedTracing: true,
    corsEnabled: true,
    allowedOrigins: ["https://cxm.ccxmnp.cloud/"]
  },
  prod: {
    accountID: "1950602",
    trustKey: "1190893",
    agentID: "594364944",
    licenseKey: "e6afbc9257",
    applicationID: "594364944",
    corsUseNewRelicHeader: true,
    excludeNewRelicHeader: false,
    corsUseTraceContextHeaders: true,
    distributedTracing: true,
    corsEnabled: true,
    allowedOrigins: ["https://swblpftdv-mts01.dev.vinstickers.com"]
  }
};

const getNewRelicConfig = (env: environments) => {
  return config[env];
};

export default getNewRelicConfig;
