import {
  ConversationItem,
  UpdateConversationItemResult
} from "@coxauto-ui/communications-interfaces";

const updateItem = (
  currentItem: ConversationItem,
  newItemByKey: ConversationItem | undefined,
  newItemByContent: ConversationItem | undefined
): UpdateConversationItemResult => {
  // Check if the key is not negative. Negative keys are newly created messages that do not yet exist in message detail api
  // isMessageNotYetProcessed is true when the message already exists in message detail api otherwise it will be false
  const isItemNotYetProcessed = currentItem.key > 0;

  // Check if it is a processed message and if it has an updated status coming from message detail api
  if (
    isItemNotYetProcessed &&
    newItemByKey !== undefined &&
    currentItem.eventResult !== newItemByKey.eventResult
  ) {
    return { updatedItem: newItemByKey, wasUpdated: true };
  }

  // Check if it is a message not yet processed and that when looking for the content of the message in the newItems it exists
  // This means that the message already exists in message detail api and it was processed
  if (!isItemNotYetProcessed && newItemByContent !== undefined) {
    return { updatedItem: newItemByContent, wasUpdated: true };
  }

  return { updatedItem: currentItem, wasUpdated: false };
};

// This method updates the currentItems by comparing them with newItems to check if there is new information that needs to be updated
export const mergeConversationItems = (
  newItems: ConversationItem[],
  currentItems: ConversationItem[]
) => {
  const currentItemKeys = new Set(currentItems.map(i => i.key));
  let updatedItems = false;
  // Mapped to easily search by key in newItems
  const newItemsMap = new Map<number, ConversationItem>();
  // Mapped to easily search by content (subject and text content) in newItems
  const newItemsContentMap = new Map<string, ConversationItem>();
  newItems.forEach((newItem: ConversationItem) => {
    newItemsMap.set(newItem.key, newItem);
    newItemsContentMap.set(
      `${newItem.subject?.trim()}-${newItem.content.text?.trim()}`,
      newItem
    );
  });

  // Iterate over currentItems to identify if any properties need to be updated against newItems
  const updatedCurrentItems = currentItems.map(
    (currentItem: ConversationItem) => {
      const { updatedItem, wasUpdated } = updateItem(
        currentItem,
        newItemsMap.get(currentItem.key),
        newItemsContentMap.get(
          `${currentItem.subject?.trim()}-${currentItem.content.text?.trim()}`
        )
      );

      if (wasUpdated) {
        updatedItems = true;
      }

      return updatedItem;
    }
  );

  // Filter the newItems that are not in conversationsItems
  const newItemsFiltered = newItems.filter(i => !currentItemKeys.has(i.key));

  // Combine the newItems and currentItems into a single array
  const mergedItems = [
    ...new Set([...newItemsFiltered, ...updatedCurrentItems])
  ];

  return {
    hasNewItems: newItemsFiltered.length > 0 || updatedItems,
    items: mergedItems
      .sort((a, b) => {
        return Date.parse(b.date) - Date.parse(a.date);
      })
      .reverse()
  };
};
