import styled from "styled-components";
import { ToggleGroup } from "@interstate/components/ToggleGroup/ToggleGroup/ToggleGroup";
import { ConversationTypeEnum } from "@coxauto-ui/communications-interfaces";
import { ConversationContext, ConversationDetailsContext } from "./context";
import { unstable_batchedUpdates as unstableBatchedUpdates } from "react-dom";
import {
  allCommsTabId,
  commsTabGroupId,
  emailTabId,
  phoneTabId,
  textTabId
} from "./testIds";
import { useContext, useEffect } from "react";
// eslint-disable-next-line
import { TagManager } from "@coxauto-ui/core-third-party-ga4";
const StyledToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px;
  #communication-messages-tabs {
    width: 100%;
  }
`;

export function ConversationPaneTabs() {
  const {
    activeListTab,
    setActiveListTab,
    customerId,
    occConfig,
    replyOptions
  } = useContext(ConversationContext);
  const {
    conversationItems,
    latestConversationItemType,
    setDisplayConversationItems,
    setLatestConversationItemType,
    setCurrentConversationType
  } = useContext(ConversationDetailsContext);

  // updates the displayed conversation items when
  // the active tab is changed or the items are updated
  useEffect(() => {
    if (activeListTab === ConversationTypeEnum.ALL) {
      setDisplayConversationItems(conversationItems);
    } else {
      setDisplayConversationItems(
        conversationItems?.filter(
          (message: { type: string | null }) =>
            message.type === activeListTab.valueOf()
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeListTab, conversationItems]);

  // updates the active tab every time conversationItems is updated
  // with a more recent message of a different type
  useEffect(() => {
    if (conversationItems.length !== 0) {
      const updatedLatestItemType = [...conversationItems].sort(function (
        a,
        b
      ): any {
        return Date.parse(b.date) - Date.parse(a.date);
      })[0].type;

      if (
        latestConversationItemType === null ||
        latestConversationItemType !== updatedLatestItemType
      ) {
        unstableBatchedUpdates(() => {
          if (occConfig.defaultTab === undefined) {
            setLatestConversationItemType(updatedLatestItemType);
            setActiveListTab(updatedLatestItemType);
            setCurrentConversationType(updatedLatestItemType);
          }
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationItems]);

  useEffect(() => {
    const defaultTab = occConfig.defaultTab;

    if (defaultTab) {
      const isEnabledReplyOption = replyOptions.some(
        ({ type }) => type.toLowerCase() === defaultTab.toLowerCase()
      );

      const tabToSetActive =
        !isEnabledReplyOption && defaultTab === ConversationTypeEnum.TEXT
          ? ConversationTypeEnum.EMAIL
          : defaultTab;

      setActiveListTab(tabToSetActive);
      setCurrentConversationType(tabToSetActive);
    }
  }, [
    replyOptions,
    occConfig.defaultTab,
    setActiveListTab,
    setCurrentConversationType
  ]);

  const trackTabSwitchedEvent = (tab: ConversationTypeEnum) => {
    TagManager.event({
      event: "productEvent",
      eventName: "Channel Tab Switched",
      eventProperties: {
        packagedBusinessCapability: "OCC Inbox",
        commonCustomerId: customerId,
        communicationChannel: tab,
        replyContext: undefined,
        errorMessage: undefined
      }
    });
  };

  const getConversationTypeEnumByIndex = (index: number) => {
    return (
      {
        0: ConversationTypeEnum.TEXT,
        1: ConversationTypeEnum.EMAIL,
        2: ConversationTypeEnum.PHONE,
        3: ConversationTypeEnum.ALL
      }[index] || ConversationTypeEnum.ALL
    );
  };
  const toggleGroupOptions = [
    {
      value: 0,
      onClick: undefined,
      onChange: undefined,
      children: <div>Texts</div>,
      selected: false,
      id: textTabId
    },
    {
      value: 1,
      onClick: undefined,
      onChange: undefined,
      children: <div>Emails</div>,
      selected: false,
      id: emailTabId
    },
    {
      value: 2,
      onClick: undefined,
      onChange: undefined,
      children: <div>Phone</div>,
      selected: false,
      id: phoneTabId
    },
    {
      value: 3,
      onClick: undefined,
      onChange: undefined,
      children: <div>All</div>,
      selected: false,
      id: allCommsTabId
    }
  ];
  const handleToggleGroupChange = (event: React.MouseEvent, value: any) => {
    const conversationType = getConversationTypeEnumByIndex(
      parseInt(value, 10)
    );
    trackTabSwitchedEvent(conversationType);
    setActiveListTab(conversationType);
    setCurrentConversationType(conversationType);
  };

  const updatedToggleGroupOptions = toggleGroupOptions.map((option, index) => ({
    ...option,
    selected: getConversationTypeEnumByIndex(option.value) === activeListTab
  }));
  return (
    <StyledToggleContainer>
      <ToggleGroup
        id={commsTabGroupId}
        size="small"
        toggleButtonOptions={updatedToggleGroupOptions}
        onChange={handleToggleGroupChange}
      />
    </StyledToggleContainer>
  );
}

export default ConversationPaneTabs;
