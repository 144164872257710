import { OccConfig } from "@coxauto-ui/communications-interfaces";
import { CommonConsumer, Environment, ViewportMode } from "./common-consumer";

export interface CustomerCardProps {
  commonConsumerID: string;
  dealId?: string;
  displayVinButton?: boolean;
  viewportMode: ViewportMode;
  displayCustomerReportingMetrics?: boolean;
  environment: Environment;
  occConfig?: OccConfig;
  occModule?: JSX.Element;
  editCapability?: EditCapability;
  useTestData?: boolean;
  defaultTab?: DefaultTab;
  enableReadOnlyEditFields?: boolean;
  doneCallback?: () => void;
  getAuthOverride?: () => Promise<string>;
  onBusinessClick?: (businessId: string | undefined) => void;
  onCustomerEdited?: (savedConsumer: CommonConsumer) => void;
}

export interface CustomerCardProviderProps extends CustomerCardProps {
  defaultTab: DefaultTab;
}

export type DefaultTab = "information" | "deal" | "inbox";

export enum CustomerCardTabTypes {
  information = "information",
  deal = "deal",
  inbox = "inbox"
}

export type EditCapability = "enabled" | "disabled" | "hidden";
