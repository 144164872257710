import {
  ConversationItem,
  GroupedMessages
} from "@coxauto-ui/communications-interfaces";
import { format } from "date-fns";

export function groupByDate(array: ConversationItem[]): GroupedMessages[] {
  const groups: Record<string, ConversationItem[]> = {};
  array.forEach(item => {
    const dateLocal = new Date(item.date);
    const dateKey = format(dateLocal, "MM-dd-yyyy");

    if (!groups[dateKey]) {
      groups[dateKey] = [];
    }
    groups[dateKey].push(item);
  });

  return Object.entries(groups).map(([key, messages]) => ({
    key,
    messages
  }));
}
