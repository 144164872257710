import { NavigateOptions, useNavigate } from "react-router-dom";
import { useRoutingContext } from "./context";

export function useRouting() {
  const context = useRoutingContext();
  const navigate = useNavigate();
  const customNavigate = (path: string, options?: NavigateOptions) => {
    if (context.isModule === false) {
      navigate(path, options);
    }
  };
  return customNavigate;
}
