export const openCommunicationsButton = "communications-open-button";

export const conversationListTestId = "conversations-list";
export const conversationListError = "conversation-list-error-message";

export const replyDropDownTestId = "reply-dropdown";
export const replyInputTestId = "reply-input";
export const replyButtonTestId = "reply-button";
export const replyAreaTestId = "reply-area";
export const replySubjectInputTestId = "reply-subject-input";
export const replySendUnavailableTestId = "reply-send-unavailable";
export const replySendUnavailableAlertTestId = "reply-send-unavailable-alert";
export const replyAttachmentScratchpad = "reply-attachment-scratchpad";
export const replyAttachmentScratchpadInput =
  "reply-attachment-scratchpad-file-input";
export const replyAttachmentScratchpadLabel =
  "reply-attachment-scratchpad-file-label";
export const replyAttachmentScratchpadIcon =
  "reply-attachment-scratchpad-file-icon";
export const replyAttachmentScratchpadAlert =
  "reply-attachment-scratchpad-alert";
export const replyAttachmentScratchpadThumbnail =
  "reply-attachment-scratchpad-file-thumb";
export const conversationsListTestId = "conversation-list-wrapper";

export const conversationsListItemAudioTestId = "conversation-list-item-audio";

export const conversationIconTestId = "conversation-list-item-icon";
export const conversationTitleTestId = "conversation-list-item-title";
export const conversationIconEmailTestId = "conversation-list-item-icon-email";
export const conversationIconTextTestId = "conversation-list-item-icon-text";
export const conversationIconPhoneTestId = "conversation-list-item-icon-phone";
export const conversationIconChatTestId = "conversation-list-item-icon-chat";

export const customerNameHeaderTestId = "customer-name-header";
export const goBackArrowHeaderIconTestId = "go-back-icon-header";

export const commsTabGroupId = "communication-messages-tabs";
export const textTabId = "text-messages-tab";
export const emailTabId = "emails-tab";
export const phoneTabId = "phone-calls-tab";
export const allCommsTabId = "all-communication-messages-tab";

export const listLoadingIndicatorId =
  "conversation-message-list-loading-indicator";

// all message items have an id appended to the end of them in the item.tsx
export const messageItemInboundId = "message-item-inbound-";
export const messageItemSenderNameId = "message-item-sender-name-";
export const messageItemSenderAgeId = "message-item-sender-age-";
export const messageItemInboundBubbleId = "message-item-inbound-bubble-";
export const messageItemOutboundId = "message-item-outbound-";
export const messageItemOutboundBubbleId = "message-item-outbound-bubble-";
export const messageItemSubjectId = "message-item-subject-";
export const messageItemRecipientContactInfoId =
  "message-item-recipient-contact-info-id";
export const messageItemBodyId = "message-item-body-";
export const messageItemContentBottomId = "message-item-content-bottom-";
export const messageItemContentBottomNumberOfAttachmentsId =
  "message-item-content-bottom-number-of-attachments-";
export const messageItemContentBottomAttachmentIconId =
  "message-item-content-bottom-attachment-icon";

export const messageItemInboundAttachmentBubbleId =
  "message-item-inbound-bubble-attachment-";
export const messageItemOutboundAttachmentBubbleId =
  "message-item-outbound-bubble-attachment-";
export const messageItemAttachmentName = "message-item-attachment-name-";
export const messageItemAttachmentIcon = "message-item-attachment-icon-";
export const messageItemAttachmentIconImage =
  "message-item-attachment-icon-image";
export const messageItemAttachmentIconVideo =
  "message-item-attachment-icon-video";
export const messageItemAttachmentIconAudio =
  "message-item-attachment-icon-audio";
export const messageItemAttachmentIconFile =
  "message-item-attachment-icon-file";
export const messageItemAttachmentViewLoading =
  "message-item-attachment-view-loading-indicator";
export const messageItemAttachmentPlayAudioLoading =
  "message-item-attachment-play--audio-loading-indicator";

export const conversationsDetailTestId = "conversation-detail-wrapper";

export const itemDetailsMessageDetailSentFromId =
  "item-details-message-detail-sent-from";
export const itemDetailsMessageDetailSentToId =
  "item-details-message-detail-sent-to";
export const itemDetailsMessageDetailSentDateId =
  "item-details-message-detail-sent-date";
export const itemDetailsMessageDetailSentSubjectId =
  "item-details-message-detail-sent-subject";
export const itemDetailsMessageDetailSentTextId =
  "item-details-message-detail-sent-text";

export const itemDetailsBackButtonId = "item-details-back-button";

export const conversationChannelSelectorId = "conversation-channel-selector";

export const messageDeliveryFailedIcon = "message-delivery-failed-icon";
export const messageDeliveryFailed = "message-delivery-failed";

export const replyCallTypeDropDownTestId = "reply-call-type-dropdown";
export const replyCallContactedDropDownTestId = "reply-call-contacted-dropdown";
export const replyLogACallInputHeader = "reply-call-input-header";
