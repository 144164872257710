import { fetchWrapper } from "@coxauto-ui/core-http";
import { getBaseUrl, getHeader } from "../config";
import { ResponseError } from "./interfaces/api-error";
import {
  Environment,
  SendCommunicationAttachmentResponse,
  UiException
} from "@coxauto-ui/communications-interfaces";
import authClient from "@coxauto-ui/core-auth-get-auth-client-customer-conversation";

export async function getAttachmentThumbnailForScratchpad(
  attachmentId: string,
  xSignature: string,
  env: Environment
): Promise<ArrayBuffer | ResponseError> {
  const url = `${getBaseUrl(env)}cxm/attachment/upload/${attachmentId}/thumb`;

  const header = await getHeader();

  const response = await fetchWrapper.getBinaryAsync(url, {
    ...header,
    "X-Signature": xSignature
  });

  if (response && response.data) {
    return response.data;
  } else {
    const error: ResponseError = {
      isError: true,
      statusCode: response.statusCode,
      errorResult: response.error
    };
    return error;
  }
}

export async function uploadAttachmentToScratchpad(
  data: File,
  contentType: string,
  env: Environment
): Promise<SendCommunicationAttachmentResponse[] | string[] | ResponseError> {
  const url = `${getBaseUrl(env)}cxm/attachment/upload${
    contentType.includes("video/") ? "/video" : ""
  }`;
  const formData = new FormData();
  formData.append("file", data);

  const bridgeJwt = await authClient.getAuth();

  try {
    const result = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${bridgeJwt}`
      },
      body: formData
    });

    if (result.ok) {
      if (contentType.includes("video/")) {
        return (await result.json()) as string[];
      } else {
        return (await result.json()) as SendCommunicationAttachmentResponse[];
      }
    } else {
      let errorMessage = "";
      if (result.status === 400) {
        const validationEX = (await result.json()) as UiException;
        errorMessage = validationEX.message;
      } else {
        errorMessage = result.statusText;
      }
      const error: ResponseError = {
        isError: true,
        statusCode: result.status,
        errorResult: new Error(errorMessage)
      };
      return error;
    }
  } catch (error: unknown) {
    const errorResponse: ResponseError = {
      isError: true,
      statusCode: 500,
      errorResult:
        error instanceof Error ? error : new Error("Request failed to complete")
    };
    return errorResponse;
  }
}
