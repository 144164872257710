import {
  GoogleAnalyticsDataLayer,
  tagsInterface
} from "@coxauto-ui/core-interfaces-third-party-gtm";

// https://developers.google.com/tag-manager/quickstart

const Snippets = {
  /** Add script tag to the page */
  tags({ id, events, dataLayer, dataLayerName, preview, auth }: tagsInterface) {
    const gtmAuth = auth ? `&gtm_auth=${auth}` : "";
    const gtmPreview = preview ? `&gtm_preview=${preview}` : "";
    const gtmDataLayer = dataLayerName ? `&l=${dataLayerName}` : "";

    const iframe = `
      <iframe data-testid="gtm-iframe" src="https://www.googletagmanager.com/ns.html?id=${id}${gtmAuth}${gtmPreview}"
        height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`;

    // I modified the script to always include the dataLayer name per Dan Mesa
    const script = `
      (function(w,d,s,l,i){w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js', ${JSON.stringify(
          events
        ).slice(1, -1)}});
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.type="text/javascript";
        j.async=true;j.setAttribute('data-testid','gtm-script');j.src='https://www.googletagmanager.com/gtm.js?id='+i+'${gtmAuth}${gtmPreview}${gtmDataLayer}';
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','${dataLayerName}','${id}');`;

    const dataLayerVar = this.dataLayer(dataLayerName, dataLayer);

    return {
      iframe,
      script,
      dataLayerVar
    };
  },
  /** Creates datalayer and pushes */
  dataLayer(dataLayerName: string, dataLayer?: GoogleAnalyticsDataLayer) {
    return `
      window.${dataLayerName} = window.${dataLayerName} || [];
      window.${dataLayerName}.push(${JSON.stringify(dataLayer)})`;
  }
};
export default Snippets;
