import { useContext, useEffect, useRef } from "react";
import { ConversationContext, ConversationDetailsContext } from "../context";
import { TextInput } from "@coxauto-ui/core-cx";
import { replySubjectInputTestId } from "../testIds";
import { ConversationTypeEnum } from "@coxauto-ui/communications-interfaces";

export interface MessageSubjectInputProps {
  isDisabled: boolean;
  initialValue: string;
}

export function MessageSubjectInput({
  isDisabled,
  initialValue
}: MessageSubjectInputProps) {
  const { activeListTab } = useContext(ConversationContext);
  const { replyTextSubject, setReplyTextSubject } = useContext(
    ConversationDetailsContext
  );

  const onInputChange = (cxEvent: {
    target: { value: string; name: string };
  }) => {
    setReplyTextSubject(cxEvent.target.value);
  };
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setReplyTextSubject(initialValue);
  }, [initialValue, setReplyTextSubject]);

  if (activeListTab !== ConversationTypeEnum.EMAIL.valueOf()) return <span />;

  return (
    <TextInput
      required
      autoComplete="off"
      data-testid={replySubjectInputTestId}
      disabled={isDisabled}
      htmlId="conversation-reply-subject-input"
      label="Subject"
      name="ConversationReplySubjectInput"
      ref={inputRef}
      value={replyTextSubject}
      onChange={onInputChange}
    />
  );
}

export default MessageSubjectInput;
