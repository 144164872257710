import styled from "styled-components";
import {
  SlidingDrawer,
  SlidingDrawerProps
} from "@interstate/components/SlidingDrawer";
import { Anchor } from "@interstate/components/SlidingDrawer/Types/slidingDrawerTypes";

/* eslint-disable-next-line */
export interface CcxmReusableBridgeBarFriendlySlidingDrawerProps
  extends Omit<SlidingDrawerProps, "position"> {
  position?: Anchor;
}

const StyledCcxmReusableBridgeBarFriendlySlidingDrawer = styled(SlidingDrawer)<{
  scrollY: number;
}>`
  .MuiDrawer-paperAnchorLeft {
    z-index: 99998;
    top: 35px;
    ${({ scrollY }) => scrollY <= 35 && "border-top: 1px solid #b2b6bf;"};
  }

  .cx-drawer__background {
    z-index: 99997;
  }
`;

export function CcxmReusableBridgeBarFriendlySlidingDrawer({
  children,
  position = "left",
  ...other
}: CcxmReusableBridgeBarFriendlySlidingDrawerProps) {
  return (
    <StyledCcxmReusableBridgeBarFriendlySlidingDrawer
      position={position}
      {...other}
      scrollY={window.scrollY}
    >
      {children}
    </StyledCcxmReusableBridgeBarFriendlySlidingDrawer>
  );
}

export default CcxmReusableBridgeBarFriendlySlidingDrawer;
