import { Dispatch, SetStateAction, createContext, useContext } from "react";

export interface CRWRoutingParams {
  selectedCustomerId: string | null;
  selectedMultiDealCustomerId: string | null;
  selectedDealId: string | null;
  defaultCustomerTab: string | null;
}

export interface CRWRoutingContext extends CRWRoutingParams {
  isModule: boolean;
  setSelectedCustomerId: Dispatch<SetStateAction<string | null>>;
  setSelectedMultiDealCustomerId: Dispatch<SetStateAction<string | null>>;
  setSelectedDealId: Dispatch<SetStateAction<string | null>>;
  setDefaultCustomerTab: Dispatch<SetStateAction<string | null>>;
}

export const RoutingContext = createContext<CRWRoutingContext>({
  isModule: false,
  selectedCustomerId: null,
  selectedMultiDealCustomerId: null,
  selectedDealId: null,
  defaultCustomerTab: null,
  setSelectedCustomerId: () => "",
  setSelectedMultiDealCustomerId: () => "",
  setSelectedDealId: () => "",
  setDefaultCustomerTab: () => ""
});

// Hook to use the context in components
export function useRoutingContext() {
  const context = useContext(RoutingContext);
  if (!context) {
    throw new Error(
      "useRoutingContext must be used within a RoutingContextProvider"
    );
  }
  return context;
}
