import { useContext } from "react";
import styled from "styled-components";
import { ConversationContext, ConversationDetailsContext } from "./context";
import { customerNameHeaderTestId } from "./testIds";
import { BaseColors } from "@coxauto-ui/communications-interfaces";

const StyledConversationPaneHeader = styled.div<{ baseColors: BaseColors }>`
  border-bottom: 1px solid ${props => props.baseColors.gray100};
  padding: 0.5em;
  button {
    font-weight: 700;
    font-size: 1.5em;
    color: ${props => props.baseColors.black};
    padding: 5px;
  }
`;

const StyledBreadCrumbArea = styled.div``;

export function ConversationPaneHeader() {
  const { customerName, showCustomerInfo, title, baseColors } =
    useContext(ConversationContext);
  const { selectedConversationItem } = useContext(ConversationDetailsContext);

  if (!showCustomerInfo || selectedConversationItem) return null;

  return (
    <StyledConversationPaneHeader baseColors={baseColors}>
      <StyledBreadCrumbArea data-testid={customerNameHeaderTestId}>
        {showCustomerInfo ? title || customerName : ""}
      </StyledBreadCrumbArea>
    </StyledConversationPaneHeader>
  );
}
