import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { RoutingContext } from "./context";

interface DataAttributes {
  [key: string]: string;
}

export interface RoutingLinkProps {
  to: string;
  children: React.ReactNode;
  id?: string;
  dataAttributes?: DataAttributes;
  onClick: (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>) => void;
}

export function RoutingLink({
  to,
  children,
  id,
  dataAttributes,
  onClick
}: RoutingLinkProps) {
  const { isModule } = useContext(RoutingContext);

  if (isModule) {
    return (
      <div id={id} onClick={onClick} {...dataAttributes}>
        {children}
      </div>
    );
  } else {
    return (
      <div>
        <Link id={id} onClick={onClick} {...dataAttributes} to={to}>
          {children}
        </Link>
      </div>
    );
  }
}

export default RoutingLink;
