import React, { ComponentType, LazyExoticComponent, Suspense } from "react";
import { loadRemoteModule } from "@coxauto-ui/core/module-federation";
import { LoadingIndicator } from "@interstate/components/LoadingIndicator";
export { TenantAdminPage } from "./tenant-admin";

interface AdminModuleProps {
  getAuthOverride?: () => Promise<string>;
}

const Module: LazyExoticComponent<ComponentType<AdminModuleProps>> = React.lazy(
  () => loadRemoteModule("tenant-admin", "./AdminModule")
);

export const AdminModule = ({ getAuthOverride }: AdminModuleProps) => {
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <Module getAuthOverride={getAuthOverride} />
    </Suspense>
  );
};
