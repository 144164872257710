export const NON_PROD_PIPELINES_DOMAIN = "np-pipelines.app.coxautoinc.com";
export const PROD_PIPELINES_DOMAIN = "pipelines.app.coxautoinc.com";

export const COOKIE_ACTING_USER_NON_PROD = "np-crw-acting-user-name";
export const COOKIE_ACTING_USER_PROD = "crw-acting-user-name";

export type PipelinesDomain =
  | typeof NON_PROD_PIPELINES_DOMAIN
  | typeof PROD_PIPELINES_DOMAIN;

export const isPipelinesDomain = (domain: string): domain is PipelinesDomain =>
  domain === NON_PROD_PIPELINES_DOMAIN || domain === PROD_PIPELINES_DOMAIN;
