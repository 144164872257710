import styled from "styled-components";
import {
  ConversationItem,
  GroupedMessages
} from "@coxauto-ui/communications-interfaces";
import MessageItem from "./item";
import { Badge } from "@interstate/components/Badge";
import { ConversationDetailsContext } from "../context";
import { useContext } from "react";
import {
  getLongDateFormat,
  groupByDate
} from "@coxauto-ui/communications-util";

const StyledConversationList = styled.div`
  padding: 0.5em;
  white-space: pre-wrap;
`;

export function ConversationListApp() {
  const { displayConversationItems } = useContext(ConversationDetailsContext);

  const grouped = Object.values(groupByDate(displayConversationItems));

  return (
    <StyledConversationList>
      {!!grouped &&
        grouped.map((itemDay: GroupedMessages) => (
          <div key={itemDay.key}>
            <center>
              <Badge variant="white">
                {getLongDateFormat(new Date(itemDay.key))}
              </Badge>
            </center>

            {itemDay.messages.map((itemConversation: ConversationItem) => {
              return (
                <div key={itemConversation.key}>
                  {
                    <MessageItem
                      conversationItem={itemConversation}
                      key={itemConversation.key}
                    />
                  }
                </div>
              );
            })}
          </div>
        ))}
    </StyledConversationList>
  );
}

export default ConversationListApp;
