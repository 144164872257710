import { fetchWrapper } from "@coxauto-ui/core-http";
import { getBaseUrl, getHeader } from "../config";
import { ResponseError } from "./interfaces/api-error";
import { Environment } from "@coxauto-ui/communications-interfaces";
import { GetCommunicationAttachmentThumbnailRequest } from "./interfaces/get-communication-attachment-thumbnail";

export async function getCommunicationAttachmentThumbnail(
  data: GetCommunicationAttachmentThumbnailRequest,
  env: Environment
): Promise<ArrayBuffer | ResponseError> {
  const url = `${getBaseUrl(env)}CxM/attachment/id/thumbnail?ConsumerId=${
    data.consumerId
  }&CoxAutoMessageId=${data.coxAutoMessageId}&AttachmentId=${
    data.attachmentId
  }&ConsumerRecordSystem=${data.consumerRecordSystem}`;

  const header = await getHeader();

  const response = await fetchWrapper.getBinaryAsync(url, {
    ...header,
    "X-Signature": data.XSignature
  });

  if (response && response.data) {
    return response.data;
  } else {
    const error: ResponseError = {
      isError: true,
      statusCode: response.statusCode,
      errorResult: response.error
    };
    return error;
  }
}
