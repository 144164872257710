import { createSlice } from "@reduxjs/toolkit";
import { UserPreferencesState } from "@coxauto-ui/ccxm/interfaces";
export const USER_PREFERENCES_FEATURE_KEY = "userPreferences";

const localStorageKey = "CXMUserPreferences";

const defaultPreferences = {
  isSidebarCollapsed: true
};

export const getUserPreferences = () => {
  const storedPreferences = localStorage.getItem(localStorageKey);
  const initialPreferences = storedPreferences
    ? { ...defaultPreferences, ...JSON.parse(storedPreferences) }
    : defaultPreferences;

  return initialPreferences;
};

export const initialUserPreferencesState: UserPreferencesState =
  getUserPreferences();

export const userPreferencesSlice = createSlice({
  name: USER_PREFERENCES_FEATURE_KEY,
  initialState: initialUserPreferencesState,
  reducers: {
    set: (state, action) => {
      const preferences = { ...state, ...action.payload };

      localStorage.setItem(localStorageKey, JSON.stringify(preferences));

      return preferences;
    }
  }
});

export const userPreferencesReducer = userPreferencesSlice.reducer;

export const userPreferencesActions = userPreferencesSlice.actions;

export const getUserPreferencesState = (rootState: any): UserPreferencesState =>
  rootState[USER_PREFERENCES_FEATURE_KEY];
