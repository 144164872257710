import styled, { createGlobalStyle, css } from "styled-components";

export const StyledResponsiveGridWrapper = styled.div`
  .cell {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    padding: 6px;
    background-color: #fff;

    &.nowrap {
      white-space: nowrap;
    }

    &.top-cell {
      border-top: 1px solid #b2b6bf;
      padding-top: 16px;
    }

    &.right-cell {
      border-right: 1px solid #b2b6bf;
      padding-right: 16px;

      &.desktopCustomer {
        padding-right: 20px;
      }
    }

    &.bottom-cell {
      padding-bottom: 16px;
    }

    &.left-cell {
      border-left: 1px solid #b2b6bf;
      padding-left: 16px;

      &.desktopCustomer {
        padding-left: 20px;
      }
    }

    &.border-top-dashed {
      border-top: 1px dashed #b2b6bf;
    }

    &.border-bottom {
      border-bottom: 1px solid #b2b6bf;
    }

    &.top-padding {
      padding-top: 16px;
    }

    &.highlighted {
      background-color: #ebf6ff;

      &.secondary {
        background-color: #fff;
      }

      &.left-cell:after {
        box-shadow: inset 6px 0 0 0 #2b6bdd;
        bottom: 0;
        content: "";
        left: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        transition: box-shadow 0.3s;
        width: 30px;
      }
    }

    &.flex-centered {
      display: flex;
      align-items: center;
    }

    a {
      color: #2b6bdd;
      font-weight: 500;
    }

    .consumer-value,
    .inline-consumer-value {
      color: #6d727a;
      font-weight: 500;
    }

    .MuiButton-outlined {
      a {
        color: #2b6bdd;
      }

      &:hover a {
        color: #fff;
      }
    }

    .MuiButton-containedPrimary {
      a {
        color: #fff;
      }
    }

    .button-group {
      white-space: nowrap;
      .MuiButtonBase-root:not(:first-child) {
        margin-left: 8px;
      }
    }

    hr {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      border: none;
      border-top: 2px solid #b2b6bf;
      width: 64px;
      margin: 0;
      padding: 0;
    }

    .popover-action {
      color: #2b6bdd;
      cursor: pointer;
      display: inline-block;
    }

    .cell-label {
      color: #16171a;
      font-weight: 800;
    }

    .cell-inline-label {
      color: #16171a;
      font-weight: 800;
      font-size: 12px;
    }

    .cell-arrow {
      position: absolute;
      bottom: -6px;
      width: 10px;
      height: 10px;
      z-index: -1;

      &::before {
        content: "";
        border-bottom: 1px solid #cacfd9;
        border-right: 1px solid #cacfd9;
        position: absolute;
        transform: rotate(45deg);
        background: #fff;
        width: 10px;
        height: 10px;
        top: 0;
        left: 0;
      }
    }

    &.sticky {
      position: sticky;
      right: 0;

      &.left-divider {
        overflow: visible;
        padding-left: 16px;
        border-left: 1px solid #b2b6bf;

        &:after {
          box-shadow: inset -24px 0 14px -12px rgb(0 0 0 / 10%);
          bottom: -1px;
          content: "";
          left: 0;
          pointer-events: none;
          position: absolute;
          top: 0;
          -webkit-transform: translate(-100%);
          transform: translate(-100%);
          transition: box-shadow 0.3s;
          width: 30px;
        }
      }
    }

    .amp-opportunity-list-snooze-btn {
      padding: 0;
      position: relative;
      height: 32px;
      width: 32px;
      > .svgicon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .amp-opportunity-list-completed-btn {
      position: relative;
      display: inline-flex;

      .svgicon {
        height: 16px;
        width: 16px;
        border-radius: 20px;
        background-color: #2b6bdd;
        color: #fff;
        margin-top: 1px;
        margin-right: 4px;
      }
    }

    .multi-deal-badge {
      display: inline-block;
      border-radius: 20px;
      background-color: #c2ffbd;
      color: #298c20;
      font-size: 12px;
      padding: 0 8px;
      margin: 0 0 0 16px;
      cursor: pointer;

      .icon-wrapper {
        display: inline-block;
        position: relative;
        width: 20px;
        height: 12px;

        svg {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 3px;
          height: 15px;
          width: 15px;
        }
      }
    }
  }
`;

export const AmpCardStyles = createGlobalStyle<{
  rowCount: number;
  rowStart: number;
  showDealershipCol: boolean;
}>`
 // is passing the variable faster than defining blocks of 200 or similar?
 ${({ rowCount, rowStart, showDealershipCol }) => {
   let styles = "";
   for (let i = rowStart; i < rowStart + rowCount + 1; i++) {
     styles += `
        .cell.customer-${i} {
          &.mobileList,
          &.mobileCustomer,
          &.desktopCustomer {
            grid-row: ${i * 4 - 3} / span 1;
            grid-column: 1;
          }
        }

        .cell.campaigns-${i} {
          &.mobileList,
          &.mobileCustomer,
          &.desktopCustomer {
            grid-row: ${i * 4 - 1} / span 1;
            grid-column: ${showDealershipCol ? "1" : "1 / span 2"};
          }
        }

        .cell.dealership-${i} {
          &.mobileList,
          &.mobileCustomer,
          &.desktopCustomer {
            grid-row: ${i * 4 - 1} / span 1;
            grid-column: 2;
          }
        }

        .cell.current-vehicle-${i} {
          &.mobileList,
          &.mobileCustomer,
          &.desktopCustomer {
            grid-row: ${i * 4 - 2} / span 1;
            grid-column: 1;
          }
        }

        .cell.market-level-${i} {
          &.mobileList,
          &.mobileCustomer,
          &.desktopCustomer {
            grid-row: ${i * 4 - 2} / span 1;
            grid-column: 2;
          }
        }

        .cell.equity-${i} {
          &.mobileList,
          &.mobileCustomer,
          &.desktopCustomer {
            grid-row: ${i * 4 - 3} / span 1;
            grid-column: 2;
          }
        }

        .cell.actions-${i} {
          &.mobileList,
          &.mobileCustomer,
          &.desktopCustomer {
            grid-row: ${i * 4} / span 1;
            grid-column: 1 / span 2;
          }
        }
      `;
   }
   return css`
     ${styles}
   `;
 }}
`;
