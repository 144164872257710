import {
  AmpStandaloneResponse,
  CompleteOpportunitiesRequest,
  CompleteOpportunitiesResponse,
  ConsumerInsightsRowsRequest,
  ConsumerInsightsRowsResponse,
  DealInsightsRecordsEntity,
  DealInsightsRowsRequest,
  DealInsightsRowsResponse,
  OpportunitiesInsightsRowsRequest,
  OpportunitiesRowsRequest,
  OpportunitiesRowsResponse,
  OpportunityInsightsRowsResponse,
  UserProfileResponse
} from "@coxauto-ui/ccxm/interfaces";
import { deriveEnvironmentName } from "@coxauto-ui/ccxm/util/derive-environment";
import { fetchWrapper } from "@coxauto-ui/core/http";
import { baseUrls } from "./baseUrls";
import authClient from "@coxauto-ui/core/auth/get-auth-client-crw";
export * from "./baseUrls";

export async function getUserProfile(viewAsUsername: string) {
  const environment = deriveEnvironmentName();
  const path = `${baseUrls[environment]}api/user-profile${
    viewAsUsername !== "" ? `?actingUserName=${viewAsUsername}` : ""
  }`;

  const bridgeJwt = await authClient.getAuth();

  const response = await fetchWrapper.getAsync<UserProfileResponse>(path, {
    Authorization: `Bearer ${bridgeJwt}`
  });

  if (!response.data) {
    return Promise.reject(response.error);
  }
  if (response.hasError) {
    return Promise.reject({
      ...response.error,
      message: response.data?.message
    });
  }
  return Promise.resolve(response.data);
}

export async function getConsumerInsightsRows(
  request: ConsumerInsightsRowsRequest
) {
  const environment = deriveEnvironmentName();

  const bridgeJwt = await authClient.getAuth();

  const response = await fetchWrapper.postAsync<any>(
    `${baseUrls[environment]}api/deal/rows`,
    request,
    { Authorization: `Bearer ${bridgeJwt}` }
  );

  if (!response.data || response.hasError) {
    return Promise.reject(response?.data?.message || response.error);
  }

  const data: ConsumerInsightsRowsResponse = response.data;

  return data;
}

export async function getOpportunitiesRows(request: OpportunitiesRowsRequest) {
  const environment = deriveEnvironmentName();

  const bridgeJwt = await authClient.getAuth();

  const response = await fetchWrapper.postAsync<any>(
    `${baseUrls[environment]}api/opportunities/rows`,
    request,
    { Authorization: `Bearer ${bridgeJwt}` }
  );

  if (!response.data || response.hasError) {
    return Promise.reject(response?.data?.message || response.error);
  }

  const data: OpportunitiesRowsResponse = response.data;

  return data;
}

export async function completeOpportunities(
  request: CompleteOpportunitiesRequest
) {
  const environment = deriveEnvironmentName();

  const bridgeJwt = await authClient.getAuth();

  return await fetchWrapper.postAsync<CompleteOpportunitiesResponse>(
    `${baseUrls[environment]}api/opportunity/complete`,
    request,
    {
      Authorization: `Bearer ${bridgeJwt}`,
      "Content-Type": "application/vnd.coxauto.v2+json"
    }
  );
}

export async function getIsAmpStandalone(
  consumerId: string,
  environment: string
) {
  const bridgeJwt = await authClient.getAuth();

  return await fetchWrapper.getAsync<AmpStandaloneResponse>(
    `${baseUrls[environment]}api/opportunity/amp-standalone?consumerId=${consumerId}`,
    {
      Authorization: `Bearer ${bridgeJwt}`,
      "Content-Type": "application/vnd.coxauto.v2+json"
    }
  );
}

export async function getDealInsightsRows(request: DealInsightsRowsRequest) {
  const environment = deriveEnvironmentName();

  const bridgeJwt = await authClient.getAuth();

  const response = await fetchWrapper.postAsync<any>(
    `${baseUrls[environment]}api/deal/rows`,
    request,
    {
      Authorization: `Bearer ${bridgeJwt}`,
      "Content-Type": "application/vnd.coxauto.v2+json"
    }
  );

  if (!response.data || response.hasError) {
    return Promise.reject(response?.data?.message || response.error);
  }

  const data: DealInsightsRowsResponse = response.data;

  return data;
}

export async function getMultiDealInsights(consumerId: string) {
  const environment = deriveEnvironmentName();

  const bridgeJwt = await authClient.getAuth();

  const response = await fetchWrapper.getAsync<any>(
    `${baseUrls[environment]}api/deal/multi-view?consumerId=${consumerId}`,
    {
      Authorization: `Bearer ${bridgeJwt}`,
      "Content-Type": "application/vnd.coxauto.v2+json"
    }
  );

  if (!response.data || response.hasError) {
    return Promise.reject(response?.data?.message || response.error);
  }

  const data: DealInsightsRecordsEntity[] = response.data;

  return data;
}

export async function getOpportunityInsightsRows(
  request: OpportunitiesInsightsRowsRequest
) {
  const environment = deriveEnvironmentName();

  const bridgeJwt = await authClient.getAuth();

  const response = await fetchWrapper.postAsync<any>(
    `${baseUrls[environment]}api/opportunity/rows`,
    request,
    {
      Authorization: `Bearer ${bridgeJwt}`,
      "Content-Type": "application/vnd.coxauto.v2+json"
    }
  );

  if (!response.data || response.hasError) {
    return Promise.reject(response?.data?.message || response.error);
  }

  const data: OpportunityInsightsRowsResponse = response.data;

  return data;
}
