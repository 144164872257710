import { StyledLabelledInsightDisplayWrapper } from "@coxauto-ui/ccxm/reusable/labelled-insight-display";
import styled from "styled-components";

export const StyledCardWrapper = styled(StyledLabelledInsightDisplayWrapper)`
  .close-my-deals-card {
    display: grid;
    grid-gap: 16px;
    padding: 16px 16px 16px 8px;
    border: 1px solid #b2b2b2;
    border-radius: 4px;
    margin-bottom: 16px;
    cursor: pointer;

    &.desktopList {
      grid-template-rows: min-content min-content;
      grid-template-columns: 148px repeat(4, 1fr);
      grid-template-areas:
        "image header header header header"
        "image vehicle employees recentupdate actions";
    }

    &.tabletList {
      grid-template-rows: min-content min-content min-content;
      grid-template-columns: 148px repeat(3, 1fr);
      grid-template-areas:
        "image header header header"
        "image vehicle employees recentupdate"
        "actions actions actions actions";
    }

    &.desktopCustomer,
    &.mobileList {
      grid-template-rows: min-content min-content min-content min-content;
      grid-template-columns: 148px 1fr;
      grid-template-areas:
        "image header"
        "vehicle vehicle"
        "recentupdate recentupdate"
        "actions actions";
    }

    &.highlighted {
      background-color: #ebf6ff;
      border-left: 4px solid #003468;
    }

    &.new-deal {
      background-color: #ebf6ff;
    }

    .vehicle-image {
      grid-area: image;
      margin-left: 8px;

      img {
        width: 140px;
      }
    }

    .card-header {
      grid-area: header;
      align-items: baseline;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .consumer-name {
        color: #005ba8;
        font-weight: 500;
        font-size: 18px;
        size: 18px;
        margin: 0;
      }

      .dealership-name {
        font-style: italic;
        // italic text will get cut off without this padding...
        padding-right: 3px;
      }

      .has-trade-in {
        color: #53ac39;
        display: flex;
        gap: 4px;
        position: relative;
        width: 70px;
        justify-content: flex-end;

        > svg {
          position: absolute;
          top: 1px;
          left: 0;
        }
      }
    }

    .accepted-pill {
      background-color: #c9eabf;
      color: #000;
      padding: 5px 10px;
      border-radius: 15px;
      font-size: 0.9em;
      margin-right: 10px;
      margin-left: auto;
    }

    .vehicle-description {
      grid-area: vehicle;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .MuiButton-containedPrimary {
        a {
          color: #fff;
        }
      }

      .singleline {
        .shrink {
          flex: 1 1 0;
          min-width: 0;
        }

        .static {
          flex: 0 0 auto;
        }
      }
    }

    .employees {
      grid-area: employees;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .recent-update {
      grid-area: recentupdate;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .actions {
      white-space: nowrap;
      grid-area: actions;
      min-width: min-content;
      display: flex;
      align-items: center;

      .delimiter {
        color: #959595;
      }

      &.right {
        justify-content: flex-end;
        align-items: center;
        align-self: end;
      }
    }

    &.desktopCustomer,
    &.mobileList {
      .card-header {
        flex-direction: column;

        .appended.multiline {
          align-self: flex-start;
          margin: 0;
        }
      }

      .vehicle-description,
      .recent-update {
        margin-left: 8px;
      }
    }
  }

  .MuiAlert-root {
    max-width: 550px;
    margin: auto;
    margin-bottom: 16px;
  }
`;
