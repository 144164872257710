import {
  ContactDetails,
  Environment,
  UiException
} from "@coxauto-ui/communications-interfaces";
import { fetchWrapper } from "@coxauto-ui/core-http";
import { getBaseUrl, getHeader } from "../config";
import { ResponseError } from "./interfaces/api-error";
import { GetContactDetailsRequest } from "./interfaces/get-contact-details";

export async function getContactDetails(
  data: GetContactDetailsRequest,
  env: Environment
): Promise<ContactDetails | ResponseError> {
  const url = `${getBaseUrl(env)}cxm/contact-details`;
  const header = await getHeader();

  const response = await fetchWrapper.getAsync<ContactDetails | UiException>(
    `${url}?consumerid=${data.consumerId}&ConsumerRecordSystem=${data.consumerRecordSystem}`,
    header
  );

  if (response && response.data && !response.hasError) {
    return response.data as ContactDetails;
  } else {
    const error: ResponseError = {
      isError: true,
      statusCode: response.statusCode,
      errorResult: new Error((response?.data as UiException)?.message)
    };
    return error;
  }
}
