import { MessageCallType } from "@coxauto-ui/communications-interfaces";
import { InterstateOnChangeEvent } from "@interstate/components/InterstateEvents";
import {
  SelectInput,
  SelectInputEventValue
} from "@interstate/components/SelectInput";
import { useContext, useEffect, useState } from "react";
import { ConversationDetailsContext } from "../context";
import { replyCallTypeDropDownTestId } from "../testIds";

/* eslint-disable-next-line */
export interface MessageCallTypeInputProps {}

export function MessageCallTypeInput(props: MessageCallTypeInputProps) {
  const { messageCallType, setMessageCallType } = useContext(
    ConversationDetailsContext
  );
  const [callType, setCallType] = useState(MessageCallType.INBOUND.toString());
  const onSelectedInputChange = (
    iEvent: InterstateOnChangeEvent<SelectInputEventValue>
  ) => {
    const changeVal = iEvent.target.value?.toString();
    if (changeVal === undefined) return;
    setCallType(changeVal);
    setMessageCallType(changeVal);
  };
  useEffect(() => {
    if (callType !== messageCallType) setMessageCallType(callType);
  }, [callType, messageCallType, setMessageCallType]);

  return (
    <SelectInput
      required
      data-testid={replyCallTypeDropDownTestId}
      displayDeselectOption={false}
      htmlId="messageCallTypeInput"
      label="Call Type"
      name="CallType"
      options={[
        {
          label: MessageCallType.INBOUND,
          value: MessageCallType.INBOUND.toString()
        },
        {
          label: MessageCallType.OUTBOUND,
          value: MessageCallType.OUTBOUND.toString()
        }
      ]}
      value={callType}
      onChange={onSelectedInputChange}
    />
  );
}

export default MessageCallTypeInput;
