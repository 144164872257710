import React, { useMemo, useState } from "react";
import { RoutingContext } from "./context";

interface RoutingContextProviderProps {
  isModule?: boolean;
  children: React.ReactNode;
}

export const RoutingContextProvider: React.FC<RoutingContextProviderProps> = ({
  isModule = false,
  children
}: RoutingContextProviderProps) => {
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(
    null
  );
  const [selectedMultiDealCustomerId, setSelectedMultiDealCustomerId] =
    useState<string | null>(null);
  const [selectedDealId, setSelectedDealId] = useState<string | null>(null);
  const [defaultCustomerTab, setDefaultCustomerTab] = useState<string | null>(
    null
  );

  const routingValues = useMemo(() => {
    return {
      isModule,
      selectedCustomerId,
      selectedMultiDealCustomerId,
      selectedDealId,
      defaultCustomerTab,
      setSelectedCustomerId,
      setSelectedMultiDealCustomerId,
      setSelectedDealId,
      setDefaultCustomerTab
    };
  }, [
    isModule,
    selectedCustomerId,
    selectedMultiDealCustomerId,
    selectedDealId,
    defaultCustomerTab
  ]);

  return (
    <RoutingContext.Provider value={routingValues}>
      {children}
    </RoutingContext.Provider>
  );
};
