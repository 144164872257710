// eslint-disable-next-line @nx/enforce-module-boundaries
import { getUserProfileState } from "@coxauto-ui/ccxm/store";
import { shallowEqual, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { USER_HOME_PAGE } from "./dealer-user-access";

export const ADMIN_HOME_PAGE = "/admin";

export const AdminAccess = ({ children }: { children: React.ReactElement }) => {
  const { isEmployee } = useSelector(getUserProfileState, shallowEqual);
  return isEmployee ? children : <Navigate to={USER_HOME_PAGE} />;
};

export default AdminAccess;
