import { loadRemoteModule } from "@coxauto-ui/core/module-federation";
import React, { Suspense } from "react";

const CustomerSearchPageModule = React.lazy(() =>
  loadRemoteModule("customer", "./CustomerSearchTestPageModule")
);

export const CustomerSearchPage = () => {
  return (
    <Suspense fallback={<div>Loading test page...</div>}>
      <CustomerSearchPageModule />
    </Suspense>
  );
};
